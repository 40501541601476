import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Skeleton } from '@mui/material';
import styled from 'styled-components';
import { Autoplay } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { SwiperEvents } from 'swiper/types';

import LeaderCard from 'components/Common/LeaderCard';
import DEVICE_SIZE from 'constant/deviceSize';
import { GetLeadersResponseDto, useGetLeaders } from 'services/react-query/leaders';

import NavButton from './NavButton';

import 'swiper/css';

interface CarouselProps {
  mainDisplay: React.ComponentProps<typeof LeaderCard>['mainDisplay'];
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC' | undefined;
  insertedData?: GetLeadersResponseDto;
  createOnCopy: (leaderCd: number) => (e?: MouseEvent<HTMLButtonElement>) => void;
  customOrder?: (string | undefined)[];
}

const settings = {
  spaceBetween: 28,
  autoplay: {
    delay: 3000,
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
  },
  slidesPerView: 'auto' as const,
};

export default function Carousel({
  mainDisplay,
  orderByField,
  orderByOrder,
  insertedData,
  createOnCopy,
  customOrder,
}: CarouselProps) {
  const swiperRef = useRef<SwiperRef>(null);
  const [navStatus, setNavStatus] = useState({ isBeginning: true, isEnd: false });
  useEffect(() => {
    const swiper = swiperRef.current?.swiper;
    const swiperEvents: Array<keyof SwiperEvents> = ['slideChange', 'reachEnd', 'reachBeginning'];
    const handleSlideChange = (swipe: any) => {
      setNavStatus(() => ({ isBeginning: swipe.isBeginning, isEnd: swipe.isEnd }));
    };
    swiperEvents.forEach((event) => {
      swiper?.on(event, handleSlideChange);
    });
    return () => {
      swiperEvents.forEach((event) => {
        swiper?.off(event, handleSlideChange);
      });
    };
  }, [swiperRef.current]);
  const isMobile = useMediaQuery({ query: `(max-width :${DEVICE_SIZE.BREAK_POINT})` });
  const { data: fetchedData } = useGetLeaders({ page: 1, limit: 15, orderByField, orderByOrder }, !insertedData, {
    useLoading: false,
  });
  const data = useMemo(() => {
    if (!!insertedData) return insertedData;
    else return fetchedData;
  }, [insertedData, fetchedData]);

  return (
    <CarouselWrapper>
      <SwiperWrapper>
        <Swiper {...settings} modules={[Autoplay]} ref={swiperRef}>
          {data
            ? data?.items.map((el, i) => (
                <Slide key={el.leaderCd}>
                  <LeaderCard
                    mainDisplay={mainDisplay}
                    data={el}
                    fixedWidth
                    rank={i + 1}
                    key={el.leaderCd}
                    onCopy={createOnCopy(el.leaderCd)}
                    customOrder={customOrder}
                  />
                </Slide>
              ))
            : new Array(15).fill(undefined).map((el, i) => (
                <Slide key={i}>
                  <Skeleton variant="rectangular" width={190} height={328} />
                </Slide>
              ))}
        </Swiper>
      </SwiperWrapper>
      {!isMobile && (
        <>
          <ShadeWrapper>
            <Shade type="prev" isActive={!navStatus.isBeginning} />
            <Shade type="next" isActive={!navStatus.isEnd} />
          </ShadeWrapper>
          <NavWrapper>
            <NavButton
              type="prev"
              isActive={!navStatus.isBeginning}
              onClick={() => {
                swiperRef.current?.swiper.slidePrev();
              }}
            />
            <NavButton
              type="next"
              isActive={!navStatus.isEnd}
              onClick={() => {
                swiperRef.current?.swiper.slideNext();
              }}
            />
          </NavWrapper>
        </>
      )}
    </CarouselWrapper>
  );
}

const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

const SwiperWrapper = styled.div`
  width: 100%;
  padding: 0 40px;
  z-index: 1;

  .swiper-slide {
    height: unset;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 0;
  }
`;

const Slide = styled(SwiperSlide)`
  width: max-content;
`;

const ShadeWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  z-index: 2;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NavWrapper = styled(ShadeWrapper)`
  button {
    pointer-events: auto;
  }
`;

interface ShadeProps {
  type: 'prev' | 'next';
  isActive: boolean;
}

const Shade = styled.div<ShadeProps>`
  width: 230px;
  height: 100%;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  background: linear-gradient(
    ${({ type }) => (type === 'prev' ? '90deg' : '270deg')},
    ${({ theme }) => theme.bg.primary} 20.06%,
    rgba(241, 242, 246, 0) 56.02%
  );
  transition: opacity 0.5s;
`;
