import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import bannerImg from 'assets/img/landing_banner_m.webp';
import Button from 'components/Common/Button';
import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { useUserInfoStore } from 'store/userInfoStore';

export default function Banner() {
  const isMobile = useMediaQuery({ query: `(max-width :${DEVICE_SIZE.BREAK_POINT})` });
  const { t, Trans } = useTranslationNamespace('common');
  const { role } = useUserInfoStore();

  return (
    <BannerWrapper>
      {isMobile ? (
        <div className="bg" />
      ) : (
        <video loop autoPlay muted src="videos/CopyTrading_Landing.mp4">
          <source src="videos/CopyTrading_Landing.mp4" type="video/mp4" />
        </video>
      )}
      <div className="welcome">
        <div className="eye-catch">
          <h1 className="heading">
            <Trans
              i18nKey="main_page_title1"
              context={window.location.href.includes('toopip') ? 'toopip' : undefined}
            />
          </h1>
          <p className="content">{t('main_page_title2')}</p>
        </div>
        <Link to={role === 'Leader' ? PATH.MY.ACCOUNT : role === 'Follower' ? PATH.LEADER_BOARD : PATH.REGISTER}>
          <Button
            variant="outlined"
            onClick={() => {}}
            style={
              isMobile
                ? {
                    height: '40px',
                    color: 'white',
                    fontSize: '15px',
                    padding: '0 15px',
                  }
                : {
                    height: '50px',
                    color: 'white',
                    fontSize: '18px',
                    padding: '0 15px',
                  }
            }
          >
            {role === 'Leader'
              ? t('main_page_button_leader')
              : role === 'Follower'
              ? t('main_page_button_follower')
              : t('main_page_button')}
          </Button>
        </Link>
      </div>
    </BannerWrapper>
  );
}

const BannerWrapper = styled.section`
  position: relative;
  top: 0;
  width: 100%;
  height: 600px;
  background-color: ${({ theme }) => theme.bg.newHeader};
  background: linear-gradient(#739ec9, #85acd3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  video {
    position: absolute;
    left: 50%;
    height: 600px;
    display: block;
    transform: translateX(-50%);
  }

  .welcome {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0 80px;
    max-width: ${DEVICE_SIZE.BODY_MAX_WIDTH};
    gap: 40px;
    justify-self: flex-start;

    .eye-catch {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      font-style: normal;

      .heading {
        font-weight: 700;
        font-size: 52px;
        line-height: 66px;
        color: ${({ theme }) => theme.color.white};

        &:lang(jp) {
          font-family: 'M PLUS Rounded 1c', sans-serif;
        }
      }

      .content {
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;

        color: ${({ theme }) => theme.color.white};
      }
    }
  }

  @media screen and (max-width: ${DEVICE_SIZE.HEADER_BREAK_POINT}) {
    .welcome {
      .eye-catch {
        .heading {
          font-size: 48px;
          line-height: 60px;
        }
        .content {
          font-size: 17px;
          line-height: 20px;
        }
      }
    }
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    background-color: #85acd3;
    height: 600px;
    justify-content: flex-start;
    .bg {
      position: absolute;
      background-image: url(${bannerImg});
      background-position: center bottom;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
    .welcome {
      padding: 20px 30px;
      gap: 20px;
      .eye-catch {
        margin-top: 70px;
        gap: 10px;
        .heading {
          font-size: 28px;
          line-height: 33px;
        }
        .content {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
`;
