import { Helmet } from 'react-helmet-async';

interface MetaTagProps {
  title?: string;
  description?: string;
  image?: string;
}

const MetaTag = ({
  title = 'Copy Trading',
  description = 'Elevate Your Trading, CopyTrading Competition Now Live!',
  image = 'https://asset.land-fx.com/copy_trading/231129_CT_open.png',
}: MetaTagProps) => {
  const url = window.location.href;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image ?? 'https://asset.land-fx.com/copy_trading/231129_CT_open.png'} />
      <meta property="og:image:width" content="600" />
      <meta property="og:image:height" content="315" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />

      <link rel="canonical" href={url} />
    </Helmet>
  );
};

export default MetaTag;
