import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const LinkText = styled(Link)<{ $isActive?: boolean }>`
  font-weight: ${({ $isActive }) => ($isActive ? 700 : 400)};
  color: ${({ theme, $isActive }) => ($isActive ? theme.color.sub1 : theme.color.white)};
  width: fit-content;

  &:hover {
    color: ${({ theme }) => theme.color.sub1};
  }
`;
