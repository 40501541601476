import styled from 'styled-components';

import step1 from 'assets/img/landing_step_1.webp';
import step2 from 'assets/img/landing_step_2.webp';
import step3 from 'assets/img/landing_step_3.webp';
import step4 from 'assets/img/landing_step_4.webp';
import DEVICE_SIZE from 'constant/deviceSize';
import useTranslationNamespace from 'hooks/useTranslationNamespace';

export default function QuickStart() {
  const { t } = useTranslationNamespace('common');

  return (
    <BodyWrapper>
      <Wrapper>
        <h2 className="title">{t('main_page_Step')}</h2>
        <div className="content">
          <Step>
            <div className="step-text">
              <h3 className="step">{t('main_page_Step1')}</h3>
              <p className="description">{t('main_page_Step1_desc')}</p>
            </div>
            <img src={step1} alt="account registration" className="responsive" />
          </Step>
          <Step>
            <div className="step-text">
              <h3 className="step">{t('main_page_Step2')}</h3>
              <p className="description">{t('main_page_Step2_desc')}</p>
            </div>
            <img src={step2} alt="copy request" className="responsive" />
          </Step>
          <Step>
            <div className="step-text">
              <h3 className="step">{t('main_page_Step3')}</h3>
              <p className="description">{t('main_page_Step3_desc')}</p>
            </div>
            <img src={step3} alt="setting" className="responsive" loading="lazy" />
          </Step>
          <Step>
            <div className="step-text">
              <h3 className="step">{t('main_page_Step4')}</h3>
              <p className="description">{t('main_page_Step4_desc')}</p>
            </div>
            <img src={step4} alt="activate trading" className="responsive" loading="lazy" />
          </Step>
        </div>
      </Wrapper>
    </BodyWrapper>
  );
}

const BodyWrapper = styled.section`
  width: 100vw;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
  min-height: ${DEVICE_SIZE.MIN_HEIGHT};
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background: ${({ theme }) => theme.color.white};
  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 80px;
  gap: 40px;
  max-width: ${DEVICE_SIZE.BODY_MAX_WIDTH};
  width: 100%;

  .title {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: ${({ theme }) => theme.font.primary};

    &:lang(jp) {
      font-weight: 500;
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 28px;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 50px 20px;
    gap: 30px;

    .title {
      font-size: 28px;
      line-height: 33px;
    }
    .content {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }

  @media screen and (max-width: ${DEVICE_SIZE.LANDING_GRID_BREAK_POINT}) {
    .content {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;

  background: ${({ theme }) => theme.color.white};
  align-self: stretch;
  .step-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    gap: 10px;
    .step {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: ${({ theme }) => theme.font.primary};
      &:lang(jp) {
        font-weight: 500;
        font-family: 'M PLUS Rounded 1c', sans-serif;
      }
    }
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;

      color: ${({ theme }) => theme.font.primary};
    }
  }
  .responsive {
    width: 100%;
    max-width: 259px;
    height: auto;
    align-self: center;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 0;
  }

  @media screen and (max-width: ${DEVICE_SIZE.LANDING_GRID_BREAK_POINT}) {
    padding: 0;
  }
`;
