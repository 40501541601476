const STORAGE_KEYS = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  i18nextLng: 'i18nextLng',
  localDate: 'localDate',
  dubaiPromotionCode: 'dubaiPromotionCode',
  loginStorage: 'loginStorage',
  mt4AccountNosList: 'mt4AccountNosList',
  userInfoStorage: 'userInfoStorage',
};

export default STORAGE_KEYS;
