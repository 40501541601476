import Drawer from '@mui/material/Drawer';
import i18n from 'i18next';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icon/icon_close.svg';
import DEVICE_SIZE from 'constant/deviceSize';
import STORAGE_KEYS from 'constant/storageKeys';
import { LanguagesListStore } from 'store/languagesListStore';
import { handleChangeLng } from 'translation/i18n';
import ToggleComponentProps from 'types/common';
import switchEnGbToEnUs from 'utils/switchEnGbToEnUs';

/**
 * language select drawer for mobile
 */

export default function LanguageSelectDrawer({ open, onClose }: ToggleComponentProps) {
  const { supportedLanguages } = LanguagesListStore();

  const currentLang = i18n?.language ?? localStorage.getItem(STORAGE_KEYS.i18nextLng);

  return (
    <Drawer open={open} onClose={onClose} anchor="top">
      <LanguageSelectWrapper>
        <button className="close-btn" onClick={onClose}>
          <CloseIcon />
        </button>
        <GridBox>
          {supportedLanguages.map((lang, index) => (
            <GridItem
              key={index}
              onClick={() => {
                document.documentElement.setAttribute('lang', lang.languageAbbr);
                handleChangeLng(lang.languageCode);
                onClose();
              }}
              isCurrentLang={currentLang === switchEnGbToEnUs(lang.languageCode)}
            >
              <div className="first">{lang.languageFull}</div>
              <div className="second">{lang.country.countryName}</div>
            </GridItem>
          ))}
        </GridBox>
      </LanguageSelectWrapper>
    </Drawer>
  );
}

const LanguageSelectWrapper = styled.div`
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
  height: 100vh;
  background-color: ${({ theme }) => theme.button.secondary};
  position: relative;
  padding: 74px 28px 63px;

  .close-btn {
    position: absolute;
    width: 24px;
    height: 24px;
    padding: 3.5px;
    top: 20px;
    right: 28px;
  }
`;

const GridBox = styled.ul`
  display: grid;
  flex-wrap: wrap;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

const GridItem = styled.li<{ isCurrentLang?: boolean }>`
  min-width: 93px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  .first {
    height: 20px;
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme, isCurrentLang }) => (isCurrentLang ? theme.color.sub1 : theme.color.white)};
  }

  .second {
    height: 15px;
    font-size: 10px;
    line-height: 15px;
    color: #8c96a9;
  }
`;
