import { useNavigate } from 'react-router-dom';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import queryString from 'query-string';
import styled from 'styled-components';

import { showModal } from 'components/Common/GlobalModal';
import ERROR_CODES from 'constant/errorCode';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { api, apiPrivate, ResponsePromise } from 'services/apiService';
import { CreateStMemberRequestDto, CreateStMemberResponseDto, Mt4AccountNosDto, RoleType } from 'services/common';
import { setUserCountryState } from 'store/userInfoStore';
import printObjValues from 'utils/printObjValues';

export interface GetFollowersResponseDto {
  mt4AccountNos: Mt4AccountNosDto[];
}

export interface GetFollowersCdResponseDto {
  followerCd: number;
  isActive: number | boolean;
  dailyFundStatement: number | boolean;
  monthlyFundStatement: number | boolean;
  settlementPeriod: string;
  sharingRatio: number;
  memberCd: number;
  mt4AccountNo: number;
  regDatetime: string;
  updateDatetime: string;
  copyType: string;
  copyTypeRatio: number;
  email: string;
  mobileCountry: string;
  mobileRest: string;
}

type PutFollowersCdRequestDto = Pick<
  GetFollowersCdResponseDto,
  'isActive' | 'copyType' | 'copyTypeRatio' | 'dailyFundStatement' | 'monthlyFundStatement'
>;

export interface GetFollowersAccountInfoResponseDto {
  cd: number;
  mt4AccountNo: number;
  role: RoleType;
  name: string;
  balance: number;
  status: string;
  isSimple: number;
  isSMSVerified: number;
  isPOAVerified: number;
  isPLEVerified: number;
  currencyAbbr: string;
  currencySymbol: string;
  nickname: string;
}

export interface PostFollowersLeadersResponseDto {
  nickname: string;
}

const getFollowersCd = (followerCd: number): ResponsePromise<GetFollowersCdResponseDto> => {
  return apiPrivate.get(`/st-followers/${followerCd}`);
};

const putFollowersCd = (followerCd: number, params: PutFollowersCdRequestDto): ResponsePromise<AxiosResponse<any>> => {
  return apiPrivate.put(`/st-followers/${followerCd}`, params);
};

const postFollowersAccount = (params: CreateStMemberRequestDto): ResponsePromise<CreateStMemberResponseDto> => {
  return api.post('/st-followers', params);
};

const postFollowersLeaders = (
  followerCd: number,
  leaderCd: number
): ResponsePromise<PostFollowersLeadersResponseDto> => {
  return apiPrivate.post(`/st-followers/${followerCd}/leaders/${leaderCd}`);
};

export const useFollowerCd = (followerCd: number, trigger?: boolean) => {
  return useQuery({
    queryKey: ['getFollowersCdState', followerCd],
    queryFn: () => getFollowersCd(followerCd),
    onSuccess: ({ mobileCountry, mobileRest }) => {
      setUserCountryState({
        mobileCountry,
        mobileRest,
      });
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
  });
};

function checkFollowersErrorCode(errorCode: string) {
  for (const key in ERROR_CODES.followers) {
    if ((ERROR_CODES as any).followers[key] === errorCode) {
      return true;
    }
  }
  return false;
}

export const useUpdateFollowerCd = (followerCd: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PutFollowersCdRequestDto) => putFollowersCd(followerCd, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['getFollowersCdState']);
      showModal({
        body: <ModalBody>{t('Update_Success', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
    onError: (err: any) => {
      const errorDesc = err.response.data.error.description;
      const errorCode = err.response.data.error.code;

      if (checkFollowersErrorCode(errorCode)) {
        showModal({
          body: <ModalBody>{errorDesc[0]}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
        return;
      }

      if (errorCode === ERROR_CODES.client[400]) {
        showModal({
          body: <ModalBody>{printObjValues(errorDesc[0].constraints)}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
        return;
      }

      showModal({
        body: <ModalBody>{t('Error_Common_Msg', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

// error handling step2에서 진행
export const useCreateFollowerAccount = () => {
  return useMutation({
    mutationFn: (data: CreateStMemberRequestDto) => postFollowersAccount(data),
  });
};

export interface GetFollowerStatsResponseDto {
  accountAge: string;
  roi: number;
  sharingRatio: number;
  leverage: number;
  totalProfitRate: number;
  maxDrawdown: number;
  totalNumberTrade: number;
  totalPips: number;
  totalTradingLot: number;
  averageTradingLot: number;
  averageDuration: string;
  profitableTrades: number;
  winRate: number;
  averageDailyTrades: number;
  averageWeeklyTrades: number;
  numberStopOutTrade: number;
  tradingPeriod: string;
  lastTrade: string;
}

const getFollowerStats = (followerCd: number): ResponsePromise<GetFollowerStatsResponseDto> => {
  return apiPrivate.get(`/st-followers/${followerCd}/stats`);
};

export const useFollowerStats = (followerCd: number, trigger?: boolean) => {
  return useQuery({
    queryKey: ['getFollowerStats', followerCd],
    queryFn: () => getFollowerStats(followerCd),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
  });
};

export const useFollowersLeaders = (followerCd: number) => {
  const navigate = useNavigate();
  const { t, Trans } = useTranslationNamespace('leader-board');
  return useMutation({
    mutationFn: (leaderCd: number) => postFollowersLeaders(followerCd, leaderCd),
    onSuccess: (data) => {
      showModal({
        body: (
          <ModalBody>
            <Trans
              ns="leader-board"
              i18nKey="Copy_Modal_Success"
              values={{ name: data.nickname }}
              components={{ deco: <span className="highlight" /> }}
            />
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: true,
        cancelText: t('Common_Confirm', { ns: 'common' }) as string,
        confirmText: t('Copy_Modal_Button_To_Active') as string,
        onConfirm: () => {
          navigate(PATH.MY.SETTING);
        },
      });
    },
    onError: (err: any) => {
      console.log(err);
      showModal({
        body: <ModalBody>{err.response.data.error.description}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};
export interface GetFollowersTradeInfoResponseDto {
  totalDepositAmount: number;
  totalProfitLoss: number;
  totalProfitRate: number;
  balance: number;
  equity: number;
  floating: number;
  margin: number;
  currencyAbbr: string;
  currencySymbol: string;
}

export interface GetFollowersOpenPositionsQueryString {
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}
export interface GetFollowersOpenPositionsResponseDto {
  items: {
    currencyAbbr: string;
    currencySymbol: string;
    positions: Array<{
      symbol: string;
      type: string;
      volume: number;
      price: number;
      sl: number;
      tp: number;
      profit: number;
      ticket: number;
    }>;
  };
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}
export interface GetFollowersTradingHistoryQueryString {
  startDate?: string;
  endDate?: string;
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}
export interface GetFollowersTradingHistoryResponseDto {
  items: Array<{
    symbol: string;
    volume: number;
    openPrice: number;
    closePrice: number;
    openTime: string;
    closeTime: string;
    profit: number;
    type: string;
    currencyAbbr: string;
    currencySymbol: string;
    swaps: number;
  }>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

const getFollowersTradeInfo = (followerCd: number): ResponsePromise<GetFollowersTradeInfoResponseDto> => {
  return apiPrivate.get(`/st-followers/${followerCd}/trade-info`);
};

const getFollowersOpenPositions = ({
  followerCd,
  page,
  limit,
  query,
}: {
  followerCd: number;
  page: number;
  limit: number;
  query?: GetFollowersOpenPositionsQueryString;
}): ResponsePromise<GetFollowersOpenPositionsResponseDto> => {
  return apiPrivate.get(
    `/st-followers/${followerCd}/open-positions?${queryString.stringify({ page, limit, ...query })}`,
    { useLoading: false }
  );
};

const getFollowersTradingHistory = ({
  followerCd,
  page,
  limit,
  query,
}: {
  followerCd: number;
  page: number;
  limit: number;
  query?: GetFollowersTradingHistoryQueryString;
}): ResponsePromise<GetFollowersTradingHistoryResponseDto> => {
  return apiPrivate.get(
    `/st-followers/${followerCd}/trading-history?${queryString.stringify({ page, limit, ...query })}`
  );
};

export const useFollowersTradeInfo = (followerCd: number, trigger: boolean) => {
  return useQuery({
    queryKey: ['getFollowersTradeInfo', followerCd],
    queryFn: () => getFollowersTradeInfo(followerCd),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
  });
};

export const useInfiniteFollowersOpenPositions = (
  {
    followerCd,
    limit,
    query,
  }: {
    followerCd: number;
    limit: number;
    query?: GetFollowersOpenPositionsQueryString;
  },
  trigger: boolean
) => {
  return useInfiniteQuery({
    queryKey: ['getFollowersOpenPositions', followerCd, queryString.stringify({ ...query, limit } ?? {})],
    queryFn: ({ pageParam = 1 }) => getFollowersOpenPositions({ followerCd, page: pageParam, limit, query }),
    onSuccess: (data) => {},
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });
};

export const useFollowersOpenPositions = (
  {
    followerCd,
    limit,
    page,
    query,
  }: {
    followerCd: number;
    limit: number;
    page: number;
    query?: GetFollowersOpenPositionsQueryString;
  },
  trigger: boolean
) => {
  return useQuery({
    queryKey: ['getFollowersOpenPositions', followerCd, queryString.stringify({ ...query, page, limit })],
    queryFn: () => getFollowersOpenPositions({ followerCd, page, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export const useInfiniteFollowersTradingHistory = (
  {
    followerCd,
    limit,
    query,
  }: {
    followerCd: number;
    limit: number;
    query?: GetFollowersTradingHistoryQueryString;
  },
  trigger: boolean
) => {
  return useInfiniteQuery({
    queryKey: ['getFollowersTradingHistory', followerCd, queryString.stringify({ ...query, limit } ?? {})],
    queryFn: ({ pageParam = 1 }) => getFollowersTradingHistory({ followerCd, page: pageParam, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });
};

export const useFollowersTradingHistory = (
  {
    followerCd,
    limit,
    page,
    query,
  }: {
    followerCd: number;
    limit: number;
    page: number;
    query?: GetFollowersTradingHistoryQueryString;
  },
  trigger: boolean
) => {
  return useQuery({
    queryKey: ['getFollowersTradingHistory', followerCd, queryString.stringify({ ...query, limit, page })],
    queryFn: () => getFollowersTradingHistory({ followerCd, page, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export type GetFollowersProfitChartResponseDto = Array<{
  date: string;
  value: number;
  formattedDate: string;
}>;
export interface GetFollowersProfitChartQueryString {
  itemCount?: number;
  period?: string;
}
const getFollowersProfitChart = (
  followerCd: number,
  query: GetFollowersProfitChartQueryString
): ResponsePromise<GetFollowersProfitChartResponseDto> => {
  return apiPrivate.get(`/st-followers/${followerCd}/charts/profit?${queryString.stringify(query)}`, {
    useLoading: false,
  });
};
export const useFollowersProfitChart = (
  followerCd: number,
  query: GetFollowersProfitChartQueryString,
  trigger?: boolean
) => {
  return useQuery({
    queryKey: ['followersProfitChart', followerCd, queryString.stringify(query)],
    queryFn: () => getFollowersProfitChart(followerCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export type GetFollowersPipChartResponseDto = Array<{
  date: string;
  value: number;
  formattedDate: string;
}>;

export interface GetFollowersPipChartQueryString {
  itemCount?: number;
  period?: string;
}
const getFollowersPipChart = (
  followerCd: number,
  query: GetFollowersPipChartQueryString
): ResponsePromise<GetFollowersPipChartResponseDto> => {
  return apiPrivate.get(`/st-followers/${followerCd}/charts/pip?${queryString.stringify(query)}`, {
    useLoading: false,
  });
};
export const useFollowersPipChart = (followerCd: number, query: GetFollowersPipChartQueryString, trigger?: boolean) => {
  return useQuery({
    queryKey: ['followersPipChart', followerCd, queryString.stringify(query)],
    queryFn: () => getFollowersPipChart(followerCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

const ModalBody = styled.div`
  background-color: white;
`;

interface CreateAdditionalAccountDto {
  memberTypeCd: number;
  currencyCd: number;
  leverage: number;
  password: string;
  confirmPassword: string;
  signUpIpAddress: string;
  twoFactorAuthentication: {
    type: string;
    secureVerificationCd: number;
  };
}

const postAdditionalAccount = (params: CreateAdditionalAccountDto) => {
  return apiPrivate.post('/st-followers/additional-account', params);
};

export const useCreateFollowerAdditionalAccount = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: CreateAdditionalAccountDto) => postAdditionalAccount(data),
    onSuccess: () => navigate(0),
    onError: (error: any) => {
      const { code: errorCode, description: descriptions } = error.response.data.error;

      showModal({
        body: (
          <ModalBody>
            <p style={{ marginBottom: '8px' }}>{t('Error_Common_Msg', { ns: 'common' })}</p>
            {errorCode === ERROR_CODES.client[400] ? (
              <p>{printObjValues(descriptions[0].constraints)}</p>
            ) : errorCode === ERROR_CODES.user[404] ? (
              <p>{t('add_account_error_0007', { ns: 'common' })}</p>
            ) : (
              descriptions.map((description: string, index: number) => <p key={index}>{description}</p>)
            )}
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: false,
        hasCloseIcon: false,
        closeOnBackdropClick: false,
      });
    },
  });
};
