/**
 * 반응형을 위한 디바이스 사이즈 가이드
 * Max width size : 1920
 * Min width size : 360
 * Figma 기준 표준 모바일 사이즈 : 375
 * 모바일 디자인으로 변경 되어야하는 최대 사이즈 : 414
 * Break point : 992 미만에서 모바일 시안대로 작업
 */

const DEVICE_SIZE = {
  MAX_WIDTH: '1920px',
  BODY_MAX_WIDTH: '1440px',
  CONTENTS_MAX_WIDTH: '1280px',
  MIN_WIDTH: '360px',
  MIN_HEIGHT: '337px',
  MOBILE: '414px',
  MOBILE_BREAK_POINT: '480px',
  BREAK_POINT: '992px',
  HEADER_BREAK_POINT: '1220px',
  HEADER_LEADER_BREAK_POINT: '1400px',
  FOOTER_BREAK_POINT: '1140px',
  FOOTER_BREAK_POINT_2: '800px',
  LANDING_GRID_BREAK_POINT: '540px',
};

export default DEVICE_SIZE;
