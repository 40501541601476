import styled from 'styled-components';

import FlagImg from 'assets/img/flags.png';
import { CountriesListStore } from 'store/countriesListStore';

interface CountryFlagProps {
  countryAbbr?: string;
  countryName?: string;
}

export default function CountryFlag({ countryAbbr, countryName }: CountryFlagProps) {
  const { supportedCountriesMap } = CountriesListStore();
  return (
    <StyledDiv
      className={`country-flag flag-${
        countryAbbr?.toLowerCase() ?? supportedCountriesMap.get(countryName ?? '')?.countryAbbr.toLowerCase()
      }`}
    />
  );
}

const StyledDiv = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background-position: 29px 0;
  background-repeat: no-repeat;
  background-image: url(${FlagImg});
`;
