import { useCookies } from 'react-cookie';

import { jwtDecode } from 'utils/jwtDecode';

export default function useAuth() {
  const [cookies] = useCookies(['access_token', 'refresh_token']);
  if (!cookies || !cookies.access_token) {
    return { isLoggedIn: false };
  }

  const { roles } = jwtDecode(cookies.access_token as string);
  const hasStRole = roles.includes('st_follower_user') || roles.includes('st_leader_user'); // login 할 수 있는 전제조건
  const isLoggedIn = cookies.access_token && hasStRole ? true : false;

  return { isLoggedIn, cookies };
}
