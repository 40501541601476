import styled from 'styled-components';

import MetaTag from 'components/Common/MetaTag';
import DEVICE_SIZE from 'constant/deviceSize';

import Banner from './components/Banner';
import QuickStart from './components/QuickStart';
import Ranking from './components/Ranking';

export default function Landing() {
  return (
    <MainWrapper>
      <MetaTag />
      <Banner />
      <QuickStart />
      <Ranking />
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
`;
