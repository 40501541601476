import { create } from 'zustand';

import { Mt4AccountNosDto } from 'services/common';

interface Mt4AccountNosStoreProps {
  mt4AccountNos: Mt4AccountNosDto[];
  setMt4AccountNos: (data: Mt4AccountNosDto[]) => void;
  selectedMt4AccountNo: Mt4AccountNosDto | undefined;
  setSelectedMt4AccountNo: (data?: Mt4AccountNosDto) => void;
}

export const Mt4AccountNosStore = create<Mt4AccountNosStoreProps>((set, get) => ({
  mt4AccountNos: [],
  setMt4AccountNos: (data) => {
    if (data.find((el) => el.mt4AccountNo === get().selectedMt4AccountNo?.mt4AccountNo) !== undefined) {
      set(() => ({ mt4AccountNos: [...data] }));
    } else {
      set(() => ({ mt4AccountNos: [...data], selectedMt4AccountNo: data[0] }));
    }
  },
  selectedMt4AccountNo: undefined,
  setSelectedMt4AccountNo: (data) => {
    set(() => ({
      selectedMt4AccountNo: data ? { ...data } : undefined,
    }));
  },
}));

export const { setMt4AccountNos, setSelectedMt4AccountNo } = Mt4AccountNosStore.getState();
