import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import STORAGE_KEYS from 'constant/storageKeys';
import { RoleType } from 'services/common';
import { GetCountriesResponseDto } from 'services/react-query/countries';

interface UserState {
  userCd: number | null;
  cd: number | null;
  mt4AccountNo: number;
  role: RoleType | null;
  name: string;
  nickname: string;
  balance: number;
  status: string;
  isSimple: number;
  isSMSVerified: number;
  isPOAVerified: number;
  isPLEVerified: number;
  currencyAbbr: string;
  currencySymbol: string;
  email: string;
  setUserInfo: (newState: Partial<UserState>) => void;
}

interface UserCountryState {
  mobileRest: string;
  mobileCountry: string;
  userCountry: GetCountriesResponseDto | null;
  setUserCountryState: (newState: Partial<UserCountryState>) => void;
}

interface UserLoginState {
  isLoggedIn?: boolean;
  isMaster?: boolean;
  setUserLoginState: (newState: Partial<UserLoginState>) => void;
}

export const initialUserState: Omit<UserState, 'setUserInfo'> = {
  userCd: null,
  cd: null,
  mt4AccountNo: 0,
  role: null,
  name: '',
  nickname: '',
  balance: 0,
  status: '',
  isSimple: 1,
  isSMSVerified: 0,
  isPOAVerified: 0,
  isPLEVerified: 0,
  currencyAbbr: 'USD',
  currencySymbol: '$',
  email: '',
};

export const useUserInfoStore = create<UserState>()(
  persist(
    (set) => ({
      ...initialUserState,
      setUserInfo: (newState) => set((state) => ({ ...state, ...newState })),
    }),
    {
      name: STORAGE_KEYS.userInfoStorage,
    }
  )
);

export const useUserMobileCountryStore = create<UserCountryState>((set) => ({
  mobileCountry: '',
  mobileRest: '',
  userCountry: null,
  setUserCountryState: (newState) => set((state) => ({ ...state, ...newState })),
}));

// 쿠키에 Token이 있지만, 로그인이 아닌 경우를 위해 isLoggedIn 필요
// ex) mypage에서 CT 계좌가 없는 계정으로 로그인 => CT로 돌아옴 => 쿠키 공유되어 토큰인 있지만, 로그인은 시키면 안됨
export const useUserLoginStore = create<UserLoginState>()(
  persist(
    (set) => ({
      isLoggedIn: false,
      isMaster: false,
      setUserLoginState: (newState: Partial<UserLoginState>) => set((state) => ({ ...state, ...newState })),
    }),
    {
      name: STORAGE_KEYS.loginStorage,
    }
  )
);

export const { setUserInfo } = useUserInfoStore.getState();

export const { mobileCountry, mobileRest, setUserCountryState } = useUserMobileCountryStore.getState();

export const { isLoggedIn, isMaster, setUserLoginState } = useUserLoginStore.getState();
