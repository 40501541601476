import styled from 'styled-components';

import { ReactComponent as MoreIcon } from 'assets/icon/go.svg';
import useTranslationNamespace from 'hooks/useTranslationNamespace';

interface ShowAllProps {
  onClick?: () => void;
}

export default function ShowAll(props: ShowAllProps) {
  const { t } = useTranslationNamespace('common');
  return (
    <ShowAllWrapper {...props}>
      <span>{t('main_page_sort')}</span>
      <MoreIcon />
    </ShowAllWrapper>
  );
}

const ShowAllWrapper = styled.button`
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 4px;

  color: ${({ theme }) => theme.font.primary};
`;
