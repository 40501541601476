import { useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import { DateField } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { FormikProps, useFormik } from 'formik';
import queryString from 'query-string';
import styled from 'styled-components';
import * as Yup from 'yup';

import InvalidCheckIcon from 'assets/icon/invalid.svg';
import ValidCheckIcon from 'assets/icon/valid.svg';
import Button from 'components/Common/Button';
import { showModal } from 'components/Common/GlobalModal';
import PasswordInput from 'components/Common/PasswordInput';
import Timer from 'components/Common/Timer';
import DEVICE_SIZE from 'constant/deviceSize';
import ERROR_CODES from 'constant/errorCode';
import STORAGE_KEYS from 'constant/storageKeys';
import { PASSWORD_REGEXP, YUP_SCHEMA } from 'constant/yupSchema';
import { EMAIL_REGEXP } from 'constant/yupSchema';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { RoleType as AccountType, CreateStMemberRequestDto } from 'services/common';
import { useCheckEmailDuplicated, useEmailSendCode, useEmailVerifyCode } from 'services/react-query/email';
import { useCreateFollowerAccount } from 'services/react-query/followers';
import { useCreateLeaderAccount } from 'services/react-query/leaders';
import { GetMemberTypesResponseDto } from 'services/react-query/members';
import { useGetMembersMainAccount } from 'services/react-query/members';
import { CountriesListStore } from 'store/countriesListStore';
import { LanguagesListStore } from 'store/languagesListStore';
import { useUserMobileCountryStore } from 'store/userInfoStore';
import * as S from 'styles/register.and.login.styled';
import defaultTheme from 'styles/theme';
import { getIbCode } from 'utils/ibCode';

import { LoginImageBox } from '..';

import CountrySelectBox from './CountrySelectBox';
import PlatformDownloadButtons from './PlatformDownloadButtons';
import Step3 from './step3';

interface StepProps {
  handlePrevStep?: () => void;
  handleNextStep?: () => void;
  accountType: AccountType;
  memberTypes?: GetMemberTypesResponseDto[];
  ip: string;
}

const registrationCommonErrors = Object.entries(ERROR_CODES.registration.COMMON);
const needEmailResendErrors = registrationCommonErrors.filter(
  ([errorName]) =>
    errorName === 'ANOTHER_WAIT_ACCOUNT' ||
    errorName === 'MAIN_ACCOUNT_LEGAL_DOCUMENT_MISSING' ||
    errorName === 'WRONG_REGISTRATION_ENTRY' ||
    errorName === 'ANOTHER_ACCOUNT_WITHOUT_KYC'
);

const Step2 = (props: StepProps) => {
  const isBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.BREAK_POINT})` });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { pathname, search } = useLocation();

  const leaderCode = useMemo<number | undefined>(() => {
    const query = queryString.parse(search);
    return query?.leaderCode ? +query.leaderCode : undefined;
  }, [search]);

  const isProperPromotionCode = useMemo<boolean>(() => {
    const hasPromotionCode = search.includes('promotionCode');
    if (!hasPromotionCode) {
      return false;
    } else {
      const hasProperPromotionCode = queryString.parse(search).promotionCode === '30_dubai_bonus';
      if (hasProperPromotionCode) {
        return true;
      } else return false;
    }
  }, [search]);

  const isRegisterPage = pathname.includes('register'); // PATH.REGISTER로 했을 때 ?code=1234 를 인식하지 못해서 바꿔줌

  // promotion 페이지에서도 쓰이는데, promotion Json과 함께 호출되므로 register를 참조하라고 명시해줘야 정상동작함
  const { t, Trans } = useTranslationNamespace('register');
  const schema = YUP_SCHEMA(t);

  const { userCountry } = useUserMobileCountryStore();
  const { supportedLanguages } = LanguagesListStore();
  const { supportedCountries } = CountriesListStore();

  const [refreshTimer, setRefreshTimer] = useState(false);

  const initialCodeData = { code: '', secureVerificationCd: 0, canSend: true, canVerify: false };
  const [codeData, setCodeData] = useState(initialCodeData);

  const [email, setEmail] = useState<string>('');
  const [ibCode] = useState<string | null>(getIbCode());

  /* 이메일 인증 관련 호출 순서
   * 1. /send-code (이메일 입력 후 "코드 발송" 버튼 클릭시)
   * 2. /verify-code (이메일로 발송된 코드 입력 후 "인증" 버튼 클릭시)
   * 3. /members/is-registrable-email 호출후 response data로 isStRegistrable 옴
   * 4. if (isStRegistrable) => 가입 가능. /email-verification 호출 (auto filled 기능용)
   *    if (!isStRegistrable) => 이미 가입된 이메일이므로 가입 불가. 해당 이메일로 로그인 유도.
   */

  const { mutateAsync: sendCodeMutateAsync, data: sendCodeData, isSuccess: isSuccessSendCode } = useEmailSendCode();
  const { mutateAsync: verifyEmailCodeMutate, isSuccess: isSuccessVerify } = useEmailVerifyCode();
  const {
    data: isRegistrable,
    error,
    isFetched,
  } = useCheckEmailDuplicated(
    {
      secureVerificationCd: codeData.secureVerificationCd,
      emailAddress: email,
    },
    !codeData.canVerify && !codeData.canSend
  );

  const shouldFetchMainAccount =
    isFetched &&
    isRegistrable?.isStRegistrable &&
    (error as any)?.data.error.code !== ERROR_CODES.registration.COMMON.ALREADY_REGISTERED_EMAIL.code &&
    codeData.secureVerificationCd !== 0 &&
    !codeData.canSend &&
    !codeData.canVerify;

  const { data: mainAccountData } = useGetMembersMainAccount(codeData.secureVerificationCd!, shouldFetchMainAccount);

  const { mutateAsync: createFollowersMutate, data: createdFollowerAccountData } = useCreateFollowerAccount();
  const { mutateAsync: createLeadersMutate, data: createdLeaderAccountData } = useCreateLeaderAccount();

  const handleReCaptchaVerify = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha();
    return token;
  };

  useEffect(() => {
    if (isSuccessSendCode && sendCodeData) {
      setRefreshTimer(true);
    }
  }, [isSuccessSendCode, sendCodeData]);

  useEffect(() => {
    if (isSuccessVerify) {
      setRefreshTimer(false);
    }
  }, [isSuccessVerify]);

  const onClickSendCodeBtn = async () => {
    if (email.length === 0 || !email.match(EMAIL_REGEXP)) {
      alert(t('Error_Email_Type', { ns: 'common' }));
      return;
    }

    const res = await sendCodeMutateAsync(email);

    setCodeData((prev) => ({
      ...prev,
      secureVerificationCd: res.secureVerificationCd,
      canSend: false,
      canVerify: true,
    }));
  };

  const handleCodeVerifyButtonClick = async () => {
    if (codeData.code.length === 0 || !codeData.code.match(/^\d{4}$/)) {
      alert(t('Code_Validation_Msg', { ns: 'common' }));
      return;
    }

    try {
      const res = await verifyEmailCodeMutate({
        emailAddress: email,
        code: +codeData.code,
        secureVerificationCd: +codeData.secureVerificationCd!,
      });

      setEmail(res.emailAddress);
      setCodeData((prev) => ({ ...prev, canSend: false, canVerify: false }));
    } catch (err: any) {
      // Time안에 잘못된 Code를 입력한 경우
      if (err.data.error.code === ERROR_CODES.email[402]) {
        return setCodeData((prev) => ({ ...prev, canSend: false, canVerify: true }));
      }

      // Code는 맞지만 Timeout 에러일 경우
      if (err.data.error.code === ERROR_CODES.email[404]) {
        return setCodeData({ code: '', secureVerificationCd: 0, canSend: true, canVerify: false });
      }

      setCodeData({ code: '', secureVerificationCd: 0, canSend: true, canVerify: false });
    }
  };

  const handleCommonErrorOnRegistration = (err: any) => {
    const errorCode = err.data.error.code;

    // Email 재인증이 필요한 에러들 먼저 핸들링
    if (needEmailResendErrors.find((err) => err[1].code === errorCode)) {
      setCodeData(initialCodeData);
      setRefreshTimer(false);
      alert(needEmailResendErrors.find((err) => err[1].code === errorCode)?.[1].description);
      return;
    }

    // 400 에러 중 Email 재인증이 필요한 에러
    // two factor invalid
    if (
      errorCode === ERROR_CODES.client[400] &&
      err.data.error.description[0].constraints.hasOwnProperty('TwoFactorAuthentication')
    ) {
      setCodeData(initialCodeData);
      setRefreshTimer(false);
      alert(t('registration-common-0013'));
      return;
    }

    if (
      errorCode === ERROR_CODES.client[400] &&
      err.data.error.description[0].constraints.hasOwnProperty('IsBirthDateMajorAge')
    ) {
      alert(err.data.error.description[0].constraints['IsBirthDateMajorAge']);
      return;
    }

    if (registrationCommonErrors.find((err) => err[1].code === errorCode)) {
      alert(t(errorCode));
      return;
    }

    showModal({
      body: <div>{t('Error_Common_Msg', { ns: 'common' })}</div>,
      isDefaultBtn: true,
      cancelBtn: false,
    });
  };

  const RegisterFormik: FormikProps<CreateStMemberRequestDto> = useFormik({
    enableReinitialize: mainAccountData ? true : false,
    validateOnMount: true,
    initialValues: {
      firstName: mainAccountData?.firstName ?? '',
      lastName: mainAccountData?.lastName ?? '',
      birth: mainAccountData?.birth ? dayjs(mainAccountData.birth) : '',
      password: '',
      confirmPassword: '',
      citizenshipCountryCd: mainAccountData?.citizenshipCountry.countryCd ?? userCountry?.countryCd ?? 1,
      memberTypeCd: props.accountType === 'Leader' ? 56 : 57,
      signUpIpAddress: props.ip,
      referralIb: ibCode ?? '',
      isPrivate: props.accountType === 'Leader' ? false : undefined, // just leader only
      hasTCConsent: false,
      hasMarketingConsent: false,
    } as CreateStMemberRequestDto,
    validationSchema: Yup.object({
      firstName: schema.nameSchema,
      lastName: schema.nameSchema,
      birth: schema.stringRequired,
      citizenshipCountryCd: schema.numberRequired,
      memberTypeCd: schema.numberRequired,
      signUpIpAddress: schema.stringRequired,
      password: schema.password,
      hasTCConsent: Yup.boolean().isTrue(),
    }),
    onSubmit: async (values) => {
      const currentTime = new Date().getTime();
      const promotionData =
        sessionStorage.getItem(STORAGE_KEYS.dubaiPromotionCode) !== null &&
        JSON.parse(sessionStorage.getItem(STORAGE_KEYS.dubaiPromotionCode) as string);
      const token = await handleReCaptchaVerify();

      if (!token) {
        console.error('No token by recatpcha');
        showModal({
          body: <div>{t('Error_Common_Msg', { ns: 'common' })}</div>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
        return;
      }

      const { birth, ...rest } = values;
      const dateObj = new Date(birth);
      const formattedBirth = format(dateObj, 'yyyy-MM-dd');

      const payload = {
        ...rest,
        birth: formattedBirth,
        email,
        twoFactorAuthentication: {
          type: 'email',
          secureVerificationCd: codeData.secureVerificationCd!,
        },
        // TEMP promotion code for DUBAI
        // 1) query string에 올바른 코드가 있을시 해당 코드 바로 사용 (Session expiration time과 관계 없이)
        // 2) 없거나, 유효한 코드가 아닐 경우 Session에 저장된 코드 사용 (유효한 시간이 있을 경우)
        // 3) 모두 해당되지 않을 경우 empty string으로 보냄
        promotionCode: isProperPromotionCode
          ? queryString.parse(search).promotionCode
          : promotionData !== null && currentTime <= promotionData.expirationTime
          ? promotionData.promotionCode
          : '',
        languageCd:
          // 1) i18n으로부터 받아서 local storage에 저장된 값 (헤더에서 선택된 language)
          // 2) 없으면 getMyCountry 호출로 얻어온 값
          // 3) 없으면 fallback language 1 (ENG)
          supportedLanguages.find((lng: any) => lng.languageCode === localStorage.getItem(STORAGE_KEYS.i18nextLng))
            ?.languageCd ??
          supportedLanguages.find((lng: any) => lng.country.countryCd === userCountry?.countryCd)?.languageCd ??
          1,
        gRecaptchaResponse: token!,
      };

      if (props.accountType === 'Follower') {
        createFollowersMutate({ ...payload, leaderCode }).catch((err: any) => {
          handleCommonErrorOnRegistration(err);
        });
      }

      if (props.accountType === 'Leader') {
        createLeadersMutate(payload).catch((err: any) => {
          handleCommonErrorOnRegistration(err);
        });
      }
    },
  });

  const handlePrevStepButtonClick = () => {
    if (email.length || RegisterFormik.dirty) {
      return showModal({
        body: <Trans i18nKey="Register_Prev_Step_Warning" ns="register" />,
        isDefaultBtn: true,
        onConfirm: () => props.handlePrevStep?.(),
      });
    }

    props.handlePrevStep?.();
  };

  const isPasswordMatch = RegisterFormik.values.password === RegisterFormik.values.confirmPassword;

  return (
    <StepWrapper>
      {(props.accountType === 'Follower' && createdFollowerAccountData) ||
      (props.accountType === 'Leader' && createdLeaderAccountData) ? (
        <>
          {!isBreakPoint && (
            <LoginImageBox>
              <Adjust>
                <S.TextBox>
                  <h1>{t('Register_Title')}</h1>
                  <p>{t('Register_Title_Desc')}</p>
                </S.TextBox>
                <PlatformDownloadButtons isMobile={true} />
              </Adjust>
            </LoginImageBox>
          )}
          <StepArea>
            <Step3
              accountType={props.accountType}
              resData={props.accountType === 'Follower' ? createdFollowerAccountData : createdLeaderAccountData}
            />
          </StepArea>
        </>
      ) : (
        <FormWrapper>
          <h2>{t('Register_label')}</h2>
          <EmailInputWrapper>
            <label htmlFor="email">{t('Register_Info_Email')}</label>
            <input
              type="text"
              id="email"
              placeholder="email@landprime.com"
              value={email}
              onChange={(e) => setEmail(e.target.value as string)}
            />
            <Button
              variant={'outlined'}
              style={{
                width: '80px',
                height: '40px',
                position: 'absolute',
                border: '1px solid',
                borderColor: codeData.canSend && email.length !== 0 ? '#1352EF' : '#CCCFD8',
                color: codeData.canSend && email.length !== 0 ? '#1352EF' : '#CCCFD8',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '20px',
                top: '10px',
                right: '12px',
                borderRadius: '6px',
              }}
              disabled={!codeData.canSend || email.length === 0}
              onClick={onClickSendCodeBtn}
            >
              {t('Register_Info_Getcode')}
            </Button>
          </EmailInputWrapper>
          <InputWrapperNew>
            <label htmlFor="code">{t('Register_verification_code', { ns: 'common' })}</label>
            <input
              type="text"
              id="code"
              placeholder={t('Code_Validation_Placeholder', { ns: 'common' })!}
              value={codeData.code}
              onChange={(e) => setCodeData((prev) => ({ ...prev, code: e.target.value }))}
            />
            {isSuccessSendCode && sendCodeData && (
              <div className="code-button-timer">
                <Timer
                  startTime={sendCodeData.registeredDatetime}
                  endTime={sendCodeData.expiredDatetime}
                  enableStart={refreshTimer}
                  enableEnd={!refreshTimer}
                  expireMinute={refreshTimer === true ? 5 : undefined}
                  onTimeOutCallback={() => setCodeData(initialCodeData)}
                />
                {isRegisterPage ? (
                  <Button
                    variant="outlined"
                    style={{
                      width: '80px',
                      height: '40px',
                      border: '1px solid',
                      borderColor: codeData.canVerify ? '#1352EF' : '#CCCFD8',
                      color: codeData.canVerify ? '#1352EF' : '#CCCFD8',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                      borderRadius: '6px',
                    }}
                    disabled={!codeData.canVerify}
                    onClick={handleCodeVerifyButtonClick}
                  >
                    {t('Register_Info_Getcode_Validate')}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    style={{
                      width: '90px',
                      minWidth: '90px',
                      height: '36px',
                      backgroundColor: '#fff',
                      color: codeData.canVerify ? '#333333' : '#B5B5B5',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                    disabled={!codeData.canVerify}
                    onClick={handleCodeVerifyButtonClick}
                  >
                    {t('Register_Info_Getcode_Validate')}
                  </Button>
                )}
              </div>
            )}
          </InputWrapperNew>
          {isRegistrable?.isStRegistrable === false ? (
            <div className="validation-result-msg">
              <span className="error">{t('registration-common-0019')}</span>
            </div>
          ) : (
            isSuccessSendCode &&
            sendCodeData && (
              <div className="validation-result-msg">
                {!codeData.canSend && !codeData.canVerify ? (
                  <span className="success">{t('Register_Info_Getcode_Comment2')}</span>
                ) : (
                  <span className="common">{t('Register_Info_Getcode_Comment1')}</span>
                )}
              </div>
            )
          )}
          <div className="name-area">
            <InputWrapperNew>
              <label htmlFor="first-name">{t('Register_Info_Firstname')}</label>
              <input
                type="text"
                id="first-name"
                placeholder={t('Register_Info_Firstname') ?? 'First Name'}
                value={RegisterFormik.values.firstName}
                onChange={(e) => RegisterFormik.setFieldValue('firstName', e.target.value)}
              />
            </InputWrapperNew>
            <InputWrapperNew>
              <label htmlFor="last-name">{t('Register_Info_Lastname')}</label>
              <input
                type="text"
                id="last-name"
                placeholder={t('Register_Info_Lastname') ?? 'Last Name'}
                value={RegisterFormik.values.lastName}
                onChange={(e) => RegisterFormik.setFieldValue('lastName', e.target.value)}
              />
            </InputWrapperNew>
          </div>
          <InputWrapperNew>
            <label htmlFor="day-of-birth">{t('Register_Info_Birth')}</label>
            <StyledDateField
              id="day-of-birth"
              disableFuture
              value={RegisterFormik.values.birth}
              onChange={(date: any) => {
                RegisterFormik.setFieldValue('birth', date);
              }}
              format={'YYYY. MM. DD'}
              $isEmpty={!RegisterFormik.values.birth}
            />
          </InputWrapperNew>

          <PasswordInput
            labelName={t('Register_password', { ns: 'common' })}
            id="password"
            value={RegisterFormik.values.password}
            onChange={(e) => RegisterFormik.setFieldValue('password', e.target.value)}
            onClickClearButton={() => RegisterFormik.setFieldValue('password', '')}
          />
          {RegisterFormik.values.password.length !== 0 && (
            <ValidationWrapper>
              <p
                className={
                  PASSWORD_REGEXP.uppercaseAndLowercaseRequired.test(RegisterFormik.values.password)
                    ? 'valid'
                    : 'invalid'
                }
              >
                {t('Register_password_validation_label1')}
              </p>
              <p
                className={
                  PASSWORD_REGEXP.specialCharactersRequired.test(RegisterFormik.values.password) ? 'valid' : 'invalid'
                }
              >
                {t('Register_password_validation_label2')}
              </p>
              <p className={PASSWORD_REGEXP.lengthRequired.test(RegisterFormik.values.password) ? 'valid' : 'invalid'}>
                {t('Register_password_validation_label3')}
              </p>
              <p className={PASSWORD_REGEXP.numberRequired.test(RegisterFormik.values.password) ? 'valid' : 'invalid'}>
                {t('Register_password_validation_label4')}
              </p>
            </ValidationWrapper>
          )}
          <PasswordInput
            labelName={t('Register_confirm_password', { ns: 'common' })}
            id="confirm-password"
            value={RegisterFormik.values.confirmPassword}
            onChange={(e) => RegisterFormik.setFieldValue('confirmPassword', e.target.value)}
            onClickClearButton={() => RegisterFormik.setFieldValue('confirmPassword', '')}
          />
          {RegisterFormik.values.confirmPassword.length !== 0 && !isPasswordMatch && (
            <ValidationWrapper>
              <p className="invalid">{t('Common_wrong_password_label', { ns: 'common' })}</p>
            </ValidationWrapper>
          )}
          <InputWrapperNew>
            <label htmlFor="country">{t('Register_Info_Country')}</label>
            <CountrySelectBox
              value={
                supportedCountries.find((c) => c.countryCd === RegisterFormik.values.citizenshipCountryCd)?.countryName!
              }
              onSelect={(value) => RegisterFormik.setFieldValue('citizenshipCountryCd', value?.countryCd)}
            />
          </InputWrapperNew>
          <InputWrapperNew hidden={ibCode !== null}>
            <label htmlFor="ib-code">{t('Register_Info_IB`Code')}</label>
            <input
              type="text"
              id="ib-code"
              placeholder="IB Code"
              value={RegisterFormik.values.referralIb}
              onChange={(e) => RegisterFormik.setFieldValue('referralIb', e.target.value)}
            />
          </InputWrapperNew>
          <CheckboxWrapper>
            <Checkbox
              checked={RegisterFormik.values.hasTCConsent}
              onChange={() => RegisterFormik.setFieldValue('hasTCConsent', !RegisterFormik.values.hasTCConsent)}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ padding: 0, '&.Mui-checked': { color: '#1352EF' } }}
            />
            <p>
              <Trans
                i18nKey="register_agreement_term"
                ns="register"
                components={[
                  <a
                    href={process.env.REACT_APP_TC}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: defaultTheme.color.primary }}
                  />,
                ]}
              />
            </p>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              checked={RegisterFormik.values.hasMarketingConsent}
              onChange={() =>
                RegisterFormik.setFieldValue('hasMarketingConsent', !RegisterFormik.values.hasMarketingConsent)
              }
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ padding: 0, '&.Mui-checked': { color: '#1352EF' } }}
            />
            <p>{t('Register_consent_desctription')}</p>
          </CheckboxWrapper>
          {isRegisterPage ? (
            <ButtonsWrapper>
              <Button
                variant="contained"
                style={{
                  width: '100%',
                  height: '54px',
                  backgroundColor: '#A5AFC4',
                  color: 'white',
                  fontWeight: 600,
                  fontSize: '17px',
                  lineHeight: '24px',
                }}
                onClick={handlePrevStepButtonClick}
              >
                {t('Register_Prev_Step')}
              </Button>
              <Button
                variant="contained"
                style={{
                  width: '100%',
                  height: '54px',
                  backgroundColor: '#1352EF',
                  color: 'white',
                  fontWeight: 600,
                  fontSize: '17px',
                  lineHeight: '24px',
                  opacity: RegisterFormik.isValid && isSuccessVerify && isPasswordMatch ? 1 : 0.5,
                }}
                disabled={!RegisterFormik.isValid || !isSuccessVerify || !isPasswordMatch}
                onClick={RegisterFormik.handleSubmit}
              >
                {t('Register_Info_Complete')}
              </Button>
            </ButtonsWrapper>
          ) : (
            <PromotionButtonWrapper>
              <Button
                variant="outlined"
                style={{
                  minWidth: '200px',
                  height: '50px',
                  fontWeight: 700,
                  fontSize: '18px',
                  backgroundColor: '#fff',
                  color: RegisterFormik.isValid && isSuccessVerify ? '#333333' : '#B5B5B5',
                }}
                disabled={Object.entries(RegisterFormik.errors).length !== 0}
                onClick={RegisterFormik.handleSubmit}
              >
                {t('Promotion_Register_Now', { ns: 'promotion' })}
              </Button>
            </PromotionButtonWrapper>
          )}
        </FormWrapper>
      )}
    </StepWrapper>
  );
};

export default Step2;

const Adjust = styled.div`
  width: 100%;
  max-width: 720px;
  padding-top: 50px;
  padding-left: 80px;
`;

const StepArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 30px 16px 50px;
    justify-content: center;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 17px;
  line-height: 24px;
  color: ${({ theme }) => theme.font.primary};

  .description {
    text-transform: uppercase;
  }

  .name-area {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    div {
      min-width: 200px;
      flex: 1;
    }
  }

  .validation-result-msg {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    .success {
      color: ${({ theme }) => theme.status.approved};
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .common,
    .error {
      color: ${({ theme }) => theme.status.warning};
    }
  }
`;

export const ValidationWrapper = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  user-select: none;

  .invalid,
  .valid {
    display: flex;
    gap: 1px;
    justify-content: center;
    align-items: center;

    &:after {
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }

  .invalid {
    color: ${({ theme }) => theme.status.warning};

    &:after {
      content: url(${InvalidCheckIcon});
    }
  }

  .valid {
    color: ${({ theme }) => theme.status.approved};

    &:after {
      content: url(${ValidCheckIcon});
    }
  }
`;

const InputWrapperNew = styled.div`
  display: flex;
  height: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  padding: 10px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.line.my};
  position: relative;

  label {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.font.quaternary};
    top: 10px;
    left: 16px;
  }

  input {
    width: 100%;
    font-size: 17px;
    line-height: 24px;
  }

  input::placeholder {
    color: ${({ theme }) => theme.font.placeholder};
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.color.primary};

    label {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  .code-button-timer {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    align-items: center;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const FormWrapper = styled.div`
  margin: 0 auto;
  padding: 50px 0;
  max-width: 424px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    margin-bottom: 32px;
  }

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 20px 16px;
  }
`;

const StyledDateField = styled(DateField)<{ $isEmpty?: boolean }>`
  &.MuiFormControl-root {
    margin-left: 0;
  }

  .MuiInputBase-root {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;

    input {
      padding: 0;
      color: ${({ theme, $isEmpty }) => (!$isEmpty ? theme.font.primary : theme.font.guide)};
      font-weight: ${({ $isEmpty }) => (!$isEmpty ? 400 : 300)};
    }

    fieldset {
      border: none;
    }

    &.Mui-focused {
      input {
        color: ${({ theme }) => theme.font.primary};
        font-weight: 400;
      }
    }
  }
`;

const EmailInputWrapper = styled(InputWrapperNew)`
  input {
    width: calc(100% - 110px);
    min-width: 257px;
    max-width: 360px;
    background-color: ${({ theme }) => theme.color.white};
  }

  // Email auto fill 되면 background 색상 변경 방지용
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px white inset;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    input {
      max-width: 100%;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  height: 100%;
  display: flex;
  gap: 10px;
  margin: 0 auto;
`;

const PromotionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    justify-content: center;
  }
`;
