import { Suspense, useEffect } from 'react';
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { ThemeProvider } from 'styled-components';

import GlobalModal from 'components/Common/GlobalModal';
import LoadingSpinner from 'components/Common/LoadingSpinner';
import RouteChangeTracker from 'components/Common/RouteChangeTracker';
import FetchUserInfo from 'components/FetchUserInfo';
import GoogleTagManager from 'components/GoogleTagManager';
import ScrollToTop from 'components/ScrollToTop';
import { clientSupportedLanguages } from 'constant/clientSupportedLanguages';
import { isUnderMaintenance } from 'constant/maintenance';
import PATH from 'constant/paths';
import routes from 'constant/routes';
import Maintenance from 'pages/Maintenance';
import GlobalStyle from 'styles/global';
import defaultTheme from 'styles/theme';
import { addQueryStringForLng } from 'translation/i18n';
import { setIbCode } from 'utils/ibCode';

import 'styles/chartTooltip.css';
import 'styles/flags.css';

function App() {
  RouteChangeTracker();

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const routing = useRoutes(routes);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
        onError: (error) => {
          console.error(error);
        },
      },
    },
  });

  useEffect(function removeIframe() {
    let ignore = false;

    if (!ignore) {
      const logout = document.getElementById('mypage-logout');
      if (logout) {
        document.body.removeChild(logout);
      }
    }

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(function defaultSettingForLang() {
    if (!clientSupportedLanguages.includes(i18n?.language)) {
      addQueryStringForLng('en_US');
    }

    // en_US 일때 html tag lang="us"로 초기세팅 되는 것 방지
    if (i18n?.language?.includes('en')) {
      document.documentElement.lang = 'en';
      return;
    }

    const currentLang = i18n?.language?.split('_')[1]?.toLowerCase() || 'en';

    document.documentElement.lang = currentLang;
  }, []);

  useEffect(
    function handleIbCode() {
      if (pathname === PATH.RESET_PASSWORD) return;
      const ibCode = searchParams.get('code');
      if (ibCode) setIbCode(ibCode);
    },
    [searchParams, pathname]
  );

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={{ ...defaultTheme }}>
          {isUnderMaintenance ? (
            <Maintenance />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
              <FetchUserInfo />
              <GlobalStyle />
              <ScrollToTop />
              <Suspense fallback={<></>}>
                {routing}
                <GlobalModal />
                <LoadingSpinner />
              </Suspense>
            </LocalizationProvider>
          )}
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={true} />
        <GoogleTagManager />
      </QueryClientProvider>
    </div>
  );
}

export default App;
