import { useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { ReactComponent as AddressIcon } from 'assets/icon/address2.svg';
import { ReactComponent as MyIcon } from 'assets/icon/my2.svg';
import { ReactComponent as PhoneIcon } from 'assets/icon/phone2.svg';
import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { useGetLegalDocuments } from 'services/react-query/legalDocument';

interface UnverifiedBannerProps {
  isSMSVerified: number;
  isPLEVerified: number;
  isPOAVerified: number;
}

const verifyStatus = {
  Unverified: 0, // Unverified: SMS verified or not / PLE unverified / POA unverified
  Verified: 1, // Verified: SMS verified / PLE verified / POA verified
  Verifying: 2, // Verifying: SMS verified / PLE verifying / POA verifying
} as const;
type VerifyStatusType = (typeof verifyStatus)[keyof typeof verifyStatus];

const UnverifiedBanner = ({ isSMSVerified, isPLEVerified, isPOAVerified }: UnverifiedBannerProps) => {
  const navigate = useNavigate();
  const { t, Trans } = useTranslationNamespace('common');
  const { data } = useGetLegalDocuments();

  const verificationStatus = {
    sms: isSMSVerified ? verifyStatus.Verified : verifyStatus.Unverified,
    ple: (() => {
      if (isPLEVerified) return verifyStatus.Verified;
      if (data?.ple && data?.ple.stat === 'wait') return verifyStatus.Verifying;
      return verifyStatus.Unverified;
    })(),
    poa: (() => {
      if (isPOAVerified) return verifyStatus.Verified;
      if (data?.poa && data?.poa.stat === 'wait') return verifyStatus.Verifying;
      return verifyStatus.Unverified;
    })(),
    all: (() => {
      const isFullVerified = [isSMSVerified, isPLEVerified, isPOAVerified].every(Boolean);

      if (isFullVerified) return verifyStatus.Verified;
      if (data?.ple && data?.ple.stat === 'wait') return verifyStatus.Verifying;
      if (data?.poa && data?.poa.stat === 'wait') return verifyStatus.Verifying;
      return verifyStatus.Unverified;
    })(),
  };

  const transtext = (verifyVal: VerifyStatusType) => {
    if (verifyVal === verifyStatus.Unverified) return t('unverified');
    else if (verifyVal === verifyStatus.Verifying) return t('verifying');
    return t('verified');
  };

  return (
    <BannerWrapper>
      <BannerInner>
        <TextArea>
          <Badge $status={verificationStatus.all}>{transtext(verificationStatus.all)}</Badge>
          <Trans i18nKey="verification_label" components={[<br />]} />
        </TextArea>
        <ButtonArea>
          <Button $status={verificationStatus.sms} onClick={() => navigate(`${PATH.MY.SETTING}#mobile-number`)}>
            <PhoneIcon />
            <div>
              {t('mobile_number')}
              <div>{transtext(verificationStatus.sms)}</div>
            </div>
          </Button>
          <Button $status={verificationStatus.ple} onClick={() => navigate(`${PATH.MY.SETTING}#legal-documents`)}>
            <MyIcon />
            <div>
              {t('ple')}
              <div>{transtext(verificationStatus.ple)}</div>
            </div>
          </Button>
          <Button $status={verificationStatus.poa} onClick={() => navigate(`${PATH.MY.SETTING}#legal-documents`)}>
            <AddressIcon className="address-icon" />
            <div>
              {t('poa')}
              <div>{transtext(verificationStatus.poa)}</div>
            </div>
          </Button>
        </ButtonArea>
      </BannerInner>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.aside`
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
  background-color: ${({ theme }) => theme.color.white};

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    background-color: transparent;
  }
`;

const BannerInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  width: ${DEVICE_SIZE.CONTENTS_MAX_WIDTH};

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    flex-direction: column;
    gap: 10px;
    height: max-content;
  }
`;

const UnverifiedColor = css`
  color: #e35138;
  background-color: #ffeeee;
  border: 1px solid #ecc4c4;
`;

const Badge = styled.div<{ $status: VerifyStatusType }>`
  width: fit-content;
  height: fit-content;
  padding: 6px 10px;
  border-radius: 6px;
  color: ${({ theme }) => theme.status.warning};
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;

  ${({ $status }) =>
    $status === verifyStatus.Unverified
      ? css`
          ${UnverifiedColor}
        `
      : `
          color: #6282cc;
          background-color: #f2f5ff;
          border: 1px solid #adbde2;
        `}
`;

const TextArea = styled.div`
  display: flex;
  gap: 10px;
  color: ${({ theme }) => theme.font.secondary};
  font-size: 14px;
  line-height: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  color: ${({ theme }) => theme.color.sub1};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    gap: 8px;
    width: 100%;
    font-size: 12px;
  }
`;

const Button = styled.a<{ $status: VerifyStatusType }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 10px 20px;
  width: 200px;
  border-radius: 6px;
  white-space: nowrap;

  svg {
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${({ theme }) => theme.color.sub1};
    }
  }

  ${({ $status }) =>
    $status === verifyStatus.Unverified
      ? css`
          ${UnverifiedColor}
          .address-icon {
            path {
              fill: none;
              stroke: ${({ theme }) => theme.status.canceled};
            }
          }

          svg {
            path {
              fill: ${({ theme }) => theme.status.canceled};
            }
          }
        `
      : css`
          background-color: ${({ theme }) => theme.color.white};
          border: 1px solid ${({ theme }) => theme.color.sub1};

          .address-icon {
            path {
              fill: none;
              stroke: ${({ theme }) => theme.color.sub1};
            }
          }
        `}

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    flex-direction: column;
    gap: 4px;
    text-align: center;
    padding: 4px;
    width: unset;
    border-color: transparent;
    box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.line.my};
  }

  &:hover {
    color: none;
  }
`;

export default UnverifiedBanner;
