import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';

import { clientSupportedLanguages } from 'constant/clientSupportedLanguages';
import { isUnderMaintenance } from 'constant/maintenance';
import switchEnGbToEnUs from 'utils/switchEnGbToEnUs';

const loadPath = process.env.REACT_APP_LOAD_BASE_URL + '/social_trading/locales/{{lng}}/{{ns}}.json';
const defaultEnLoadPath = process.env.REACT_APP_LOAD_BASE_URL + '/social_trading/locales/en_US/{{ns}}.json';

const handleLoadPathByLang = (lngs: string[]) => {
  const [lng] = lngs;
  if (clientSupportedLanguages.includes(lng)) {
    return loadPath;
  } else return defaultEnLoadPath;
};

const detectorOptions = {
  order: ['querystring', 'localStorage', 'cookie'], // detect 우선순위 변경
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
  htmlTag: document.documentElement,
};

!isUnderMaintenance &&
  i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init<HttpBackendOptions>({
      fallbackLng: 'en_US',
      lng: process.env.REACT_APP_CI_MODE === 'true' ? 'cimode' : undefined,
      debug: process.env.NODE_ENV === 'development',
      keySeparator: false,
      nsSeparator: false,
      load: 'currentOnly',
      ns: ['common'],
      defaultNS: ['common'],
      backend: {
        loadPath: handleLoadPathByLang,
        crossDomain: true,
        queryStringParams: { t: String(Date.now()) }, // timestamp 찍어서 배포 할때마다 json 파일 reload
      },
      react: {
        useSuspense: true,
      },
      detection: detectorOptions,
      partialBundledLanguages: true, // to lazy load some translations via a backend plugin
    });

export const addQueryStringForLng = (languageCode: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set('lng', languageCode);
  window.history.pushState({}, '', url.toString());
};

export const handleChangeLng = (languageCode: string) => {
  const _languageCode = switchEnGbToEnUs(languageCode);
  i18n.changeLanguage(_languageCode).then(() => addQueryStringForLng(_languageCode));
};
