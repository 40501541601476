import { useNavigate } from 'react-router';

import { useMutation, useQuery } from '@tanstack/react-query';
import styled from 'styled-components';

import { showModal } from 'components/Common/GlobalModal';
import ERROR_CODES from 'constant/errorCode';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { api, apiPrivate, ResponsePromise } from 'services/apiService';
import { setMt4AccountNos, setSelectedMt4AccountNo } from 'store/selectAccountsStore';
import { setUserInfo, setUserLoginState } from 'store/userInfoStore';

import { PasswordWithCode, RoleType } from '../common';

export interface UsersResponseDto {
  userCd: number;
  cd: number;
  mt4AccountNo: number;
  role: RoleType;
  name: string;
  nickname: string;
  balance?: number;
  status?: string;
  isSimple: number;
  isSMSVerified: number;
  isPOAVerified: number;
  isPLEVerified: number;
  currencyAbbr: string;
  currencySymbol: string;
}
interface LeaderResponseDto extends Pick<UsersResponseDto, 'cd' | 'mt4AccountNo'> {
  nickname: string;
}

interface FollowerResponseDto extends Pick<UsersResponseDto, 'cd' | 'mt4AccountNo'> {
  leaderNickname: string;
}

export interface FindAuthenticatedUserInfoResponseDto {
  defaultAccountType: RoleType;
  defaultAccount: number;
  leaders: Array<LeaderResponseDto>;
  followers: Array<FollowerResponseDto>;
}

const getUserAccountsListInfo = (): ResponsePromise<FindAuthenticatedUserInfoResponseDto> => {
  return apiPrivate.get('/st/users');
};

const getUserAccountInfo = (mt4AccountNo: number): ResponsePromise<UsersResponseDto> => {
  return apiPrivate.get(`/st/user/${mt4AccountNo}`);
};

const createEmailIdUser = (data: PasswordWithCode): ResponsePromise<any> => {
  return api.post('/users/from-account-no', data);
};

export const useUserAccountsList = (trigger?: boolean) => {
  const navigate = useNavigate();
  const { t } = useTranslationNamespace('common');

  return useQuery({
    queryKey: ['userinfo'],
    queryFn: () => getUserAccountsListInfo(),
    onSuccess: (data) => {
      let selectedAccount;
      if (data.defaultAccountType === 'Follower') {
        selectedAccount = data.followers.find(
          (followerAccount) => followerAccount.mt4AccountNo === data.defaultAccount
        );
      } else {
        selectedAccount = data.leaders.find((leaderAccount) => leaderAccount.mt4AccountNo === data.defaultAccount);
      }
      setUserInfo({ role: data.defaultAccountType });
      setMt4AccountNos([...data.leaders, ...data.followers]);
      setSelectedMt4AccountNo(selectedAccount);
    },
    onError: (err: any) => {
      const errStatus = err.response.status;
      const errCode = err.response.data.error.code;

      if (errStatus === 403 && errCode === ERROR_CODES.client[403]) {
        setUserLoginState({
          isLoggedIn: false,
          isMaster: true,
        });
        showModal({
          body: <ModalBody>{t('not_ct_user_message')}</ModalBody>,
          isDefaultBtn: true,
          disableEscapeKeyDown: true,
          closeOnBackdropClick: false,
          cancelBtn: false,
          hasCloseIcon: false,
          onConfirm: () => navigate(PATH.MAIN),
        });
      }
    },
    enabled: !!trigger,
  });
};

export const useUserInfo = (mt4AccountNo: number, trigger?: boolean) => {
  return useQuery({
    queryKey: ['user', mt4AccountNo],
    queryFn: () => getUserAccountInfo(mt4AccountNo),
    onSuccess: (data) => {
      setUserInfo({ ...data });
    },
    onError: (err: any) => {
      console.log(err);
    },
    enabled: !!trigger,
  });
};

export const useCreateEmailIdUser = () => {
  return useMutation((data: PasswordWithCode) => createEmailIdUser(data), {
    onError: ({ data }) => {
      if (data.error.code === 'user-0004') {
        alert('Account already created');
      }
    },
  });
};

const ModalBody = styled.div`
  min-width: 260px !important;
  margin-top: 10px;
  background-color: white;
`;
