import { ReactNode } from 'react';

import styled from 'styled-components';

export const LinedLinkText = ({ children, isTop, id }: { children?: ReactNode; isTop?: boolean; id?: string }) => {
  return (
    <LinkTextWrapper className={isTop ? 'top' : ''} id={id}>
      {children}
    </LinkTextWrapper>
  );
};

const LinkTextWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.white};

  &.top {
    &:hover {
      color: ${({ theme }) => theme.color.white};
    }
    &.top::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 3px;
      background-color: ${({ theme }) => theme.button.secondary};
      transition: all 0.3s ease 0s;
    }
    &.top:hover::after {
      width: 100%;
    }

    a {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.sub1};
  }
`;
