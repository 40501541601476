import { ChangeEvent, MouseEvent, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as DropdownIcon } from 'assets/icon/dropdown.svg';

interface SearchableSelectBoxProps<T> {
  onSelect?: (value?: T) => void;
  options: T[];
  searchKey: keyof T;
  value?: string;
  leftSlot?: (value?: T) => ReactNode;
  rightSlot?: (value?: T) => ReactNode;
  listContent?: (value: T, i: number) => ReactNode;
  dropdownIcon?: boolean;
  className?: string;
  placeholder?: string;
  showAlways?: boolean;
  resetOnEmpty?: boolean;
}

export default function SearchableSelectBox<T>({
  value,
  onSelect,
  options,
  searchKey,
  leftSlot,
  rightSlot,
  listContent,
  dropdownIcon = true,
  className,
  placeholder,
  showAlways,
  resetOnEmpty = true,
}: SearchableSelectBoxProps<T>) {
  const [internalValue, setInternalValue] = useState<string>(value ?? '');
  const [searchValue, setSearchValue] = useState<string>(value ?? '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [show, setShow] = useState<boolean>(showAlways ?? false);
  const [showAll, setShowAll] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsMap = new Map(options.map((el) => [el[searchKey] as string, el]));
  const handleSelect = useCallback(
    (selectedValue: T) => {
      onSelect?.(selectedValue);
      if (selectedValue?.[searchKey] !== undefined) {
        setInternalValue(() => selectedValue[searchKey] as string);
        setSearchValue(() => selectedValue[searchKey] as string);
      }
      setShow(showAlways ?? false);
      setShowAll(true);
    },
    [onSelect]
  );
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'ArrowDown')
      setSelected((prev) => Math.min(prev + 1, filteredOptions ? filteredOptions.length - 1 : 0));
    else if (e.key === 'ArrowUp') setSelected((prev) => Math.max(prev - 1, 0));
    else if (e.key === 'Enter') {
      handleSelect(filteredOptions[selected]);
    }
  };

  useLayoutEffect(() => {
    setFilteredOptions(() =>
      showAll
        ? options
        : options.filter((el) => (el[searchKey] as string).toLowerCase().includes(searchValue.toLowerCase()))
    );
    setSelected(showAll ? options.findIndex((el) => el[searchKey] === searchValue) : 0);
  }, [options, searchValue, showAll, show]);

  useEffect(() => {
    setSearchValue(value ?? '');
    setInternalValue(value ?? '');
  }, [value]);

  return (
    <Wrapper
      className={className}
      onMouseDown={(e: MouseEvent<HTMLElement>) => {
        if (e.target !== inputRef.current) e.preventDefault();
      }}
      onClick={(e: MouseEvent<HTMLElement>) => {
        if (e.target !== inputRef.current) {
          setShow((prev) => showAlways ?? !prev);
        } else {
          setShow(true);
        }
        inputRef.current?.focus();
      }}
    >
      {leftSlot?.(optionsMap.get(internalValue))}
      <input
        ref={inputRef}
        onFocus={() => {
          setShowAll(true);
        }}
        onBlur={() => {
          setShow(showAlways ?? false);
          if (resetOnEmpty && searchValue === '') {
            setInternalValue(() => '');
            onSelect?.(undefined);
            setShowAll(true);
          } else setSearchValue(internalValue);
        }}
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setSearchValue(e.target.value);
          setShowAll(false);
          setShow(true);
        }}
        onKeyDown={handleKeyDown}
        value={searchValue}
      />
      {rightSlot?.(optionsMap.get(internalValue))}
      {dropdownIcon && <DropdownIcon width={24} height={24} style={{ minWidth: '24px', minHeight: '24px' }} />}
      {show && (
        <SearchResult className="search-result-wrapper">
          <ul
            id="search-result"
            onMouseDown={(e: any) => {
              if (e.target.id !== 'search-result' && e.button === 0) {
                const id = parseInt(e.target.closest('li')?.id);
                if (!isNaN(id)) {
                  setSelected(id);
                  handleSelect(filteredOptions[id]);
                }
              }
            }}
            onMouseMove={(e: any) => {
              const id = parseInt(e.target.closest('li')?.id);
              if (!isNaN(id)) setSelected(id);
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((el, i) => (
                <ListElement id={i.toString()} key={el[searchKey] as string} selected={i === selected}>
                  {listContent ? listContent(el, i) : (el[searchKey] as string)}
                </ListElement>
              ))
            ) : (
              <li>No results found</li>
            )}
          </ul>
        </SearchResult>
      )}
    </Wrapper>
  );
}

type ListElementProps = { selected?: boolean; id?: string } & React.HTMLAttributes<HTMLLIElement>;

const ListElement = ({ selected, id, onMouseMove, children }: ListElementProps) => {
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (selected) {
      setTimeout(() => ref.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' }), 0);
    }
  }, [selected]);
  return (
    <li id={id} ref={ref} className={selected ? 'selected' : ''} onMouseMove={onMouseMove}>
      {children}
    </li>
  );
};

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  /* padding: 5px 10px; */
  height: 100%;
  cursor: pointer;
  z-index: 2;

  input {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    font-family: 'Roboto';
    color: ${({ theme }) => theme.font.primary};
    width: 100%;
  }
`;

const SearchResult = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: calc(100% + 2px);
  top: 100%;
  left: -1px;

  background: ${({ theme }) => theme.color.white};
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.line.my};

  max-height: 200px;
  overflow: auto;

  color: ${({ theme }) => theme.font.primary};
  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    width: max-content;
    min-width: 100%;
    li {
      box-sizing: border-box;
      width: 100%;
      padding: 5px 10px;
      background-color: ${({ theme }) => theme.color.white};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: ${({ theme }) => theme.font.primary};
      align-self: stretch;
      &.selected {
        background-color: ${({ theme }) => theme.bg.blue};
      }
    }
  }
`;
