import { useMutation, useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import queryString from 'query-string';
import styled from 'styled-components';

import { showModal } from 'components/Common/GlobalModal';
import ERROR_CODES from 'constant/errorCode';
import { api, ResponsePromise } from 'services/apiService';
import printObjValues from 'utils/printObjValues';

interface SendEmailCodeResponseDto {
  emailAddress: string;
  secureVerificationCd: number;
  status: string;
  registeredDatetime: string;
  expiredDatetime: string;
}

interface VerifyEmailCodeRequestDto {
  emailAddress: string;
  secureVerificationCd: number;
  code: number;
}

interface IsRegistrableDto {
  isLandPrimeRegistrable: boolean;
  isStRegistrable: boolean;
}

type DuplicatedEmailQueryType = Omit<VerifyEmailCodeRequestDto, 'code'>;

const emailSendCode = (emailAddress: string): ResponsePromise<SendEmailCodeResponseDto> => {
  return api.post('/emails/send-code', { emailAddress });
};

const emailVerifyCode = ({
  emailAddress,
  secureVerificationCd,
  code,
}: VerifyEmailCodeRequestDto): ResponsePromise<SendEmailCodeResponseDto> => {
  return api.post('/emails/verify-code', { emailAddress, secureVerificationCd, code });
};

const getEmailDuplicatedCheck = (query: DuplicatedEmailQueryType): ResponsePromise<IsRegistrableDto> => {
  return api.get(`/members/is-registrable-email?${queryString.stringify(query)}`);
};

export const useEmailSendCode = () => {
  return useMutation({
    mutationFn: (emailAddress: string) => emailSendCode(emailAddress),
    onError: (err: any) => {
      const Error = err.data.error;
      if (err.status === 400 && Error.code === ERROR_CODES.client[400]) {
        alert(printObjValues(Error.description[0].constraints));
        return;
      }
      showModal({
        body: <ModalBody>{t('Error_Common_Msg', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useEmailVerifyCode = () => {
  return useMutation({
    mutationFn: ({ emailAddress, secureVerificationCd, code }: VerifyEmailCodeRequestDto) =>
      emailVerifyCode({ emailAddress, secureVerificationCd, code }),
    onError: (err: any) => {
      const Error = err.data.error;
      //TODO : 에러케이스에 따른 번역 적용
      if (Error.code === ERROR_CODES.email[402]) {
        // code and email not matching
        alert(t(Error.code, { ns: 'common' }));
        return;
      }
      if (Error.code === ERROR_CODES.email[403]) {
        alert(Error.description[0]);
        return;
      }
      if (Error.code === ERROR_CODES.email[404]) {
        // request timeout
        alert(Error.description[0]);
        return;
      }
      if (Error.code === ERROR_CODES.client[400]) {
        showModal({
          body: <ModalBody>{printObjValues(Error.description[0].constraints)}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
        return;
      }
      showModal({
        body: <ModalBody>{t('Error_Common_Msg', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useCheckEmailDuplicated = (query: DuplicatedEmailQueryType, trigger?: boolean) => {
  return useQuery({
    queryKey: ['check-duplication'],
    queryFn: () => getEmailDuplicatedCheck(query),
    onError: () => {},
    enabled: !!trigger,
  });
};

const ModalBody = styled.div`
  min-width: 260px !important;
  background-color: white;
`;
