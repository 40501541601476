import styled from 'styled-components';

import { ReactComponent as DownloadIcon } from 'assets/icon/icon_download.svg';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import checkMobileOs from 'utils/checkMobileOs';

export default function MobileDownloadButton() {
  const { t } = useTranslationNamespace('common');
  const deviceOs = checkMobileOs();
  const webMt4Link =
    deviceOs === 'browser'
      ? 'https://download.mql5.com/cdn/web/land.prime.ltd/mt4/landprime4setup.exe'
      : deviceOs === 'android'
      ? ' https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en&referrer=ref_id%3d5014276646422293650%26hl%3den'
      : 'https://apps.apple.com/us/app/metatrader-4/id496212596';

  const webMt5Link =
    deviceOs === 'browser'
      ? 'https://download.mql5.com/cdn/web/land.prime.ltd/mt5/landprime5setup.exe'
      : deviceOs === 'android'
      ? 'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5'
      : 'https://apps.apple.com/us/app/metatrader-5-forex-stocks/id413251709';
  return (
    <DownloadMenu>
      <div>{t('CT_head11')}</div>
      <div className="download-sub-menus">
        <a href={webMt4Link}>
          <DownloadIcon color="#B7BEC9" />
          {t('CT_head11_mt4')}
        </a>
        <a href={webMt5Link}>
          <DownloadIcon color="#B7BEC9" />
          {t('CT_head11_mt5')}
        </a>
      </div>
    </DownloadMenu>
  );
}

const DownloadMenu = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.color.white};
  width: 100%;

  .download-sub-menus {
    margin-top: 20px;
    width: 100%;
    background-color: #3d4350;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 20px;
      border: 1px solid rgba(165, 175, 196, 0.3);
      color: #b7bec9;

      &:first-child {
        border-bottom: none;
      }
    }
  }
`;
