import { useState } from 'react';
import { useNavigate } from 'react-router';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import Button from 'components/Common/Button';
import { closeModal } from 'components/Common/GlobalModal';
import PasswordInput from 'components/Common/PasswordInput';
import PATH from 'constant/paths';
import { PASSWORD_REGEXP, YUP_SCHEMA } from 'constant/yupSchema';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { ValidationWrapper } from 'pages/Register/components/step2';
import { PasswordWithCode } from 'services/common';
import { useCreateEmailIdUser } from 'services/react-query/user';

interface ModalProps {
  email: string;
  limitDate: string;
  createUserCode: string;
  accessToken?: string;
}

// fe side에서 한번 검증
const _compareWithCurrentDate = (dateString: string) => {
  const limitDate = new Date(dateString);
  const currentDate = new Date();

  if (limitDate.getTime() < currentDate.getTime()) {
    return false;
  }
  if (limitDate.getTime() >= currentDate.getTime()) {
    return true;
  }
};

const passwordValidationRules = [
  {
    regExp: PASSWORD_REGEXP.uppercaseAndLowercaseRequired,
    labelKey: 'Register_password_validation_label1',
  },
  {
    regExp: PASSWORD_REGEXP.specialCharactersRequired,
    labelKey: 'Register_password_validation_label2',
  },
  {
    regExp: PASSWORD_REGEXP.lengthRequired,
    labelKey: 'Register_password_validation_label3',
  },
  {
    regExp: PASSWORD_REGEXP.numberRequired,
    labelKey: 'Register_password_validation_label4',
  },
];

interface PasswordValidationProps {
  password: string;
  translationFn: TFunction<string | string[]>;
}

const PasswordValidation = ({ password, translationFn }: PasswordValidationProps) => (
  <ValidationWrapper>
    {password.length > 0 &&
      passwordValidationRules.map(({ regExp, labelKey }, index) => (
        <p key={index} className={regExp.test(password) ? 'valid' : 'invalid'}>
          {translationFn(labelKey, { ns: 'register' })}
        </p>
      ))}
  </ValidationWrapper>
);

export default function SetNewPasswordWithEmailModal(props: ModalProps) {
  const navigate = useNavigate();
  const [isPasswordMatches, setPasswordMatches] = useState(false);
  const { mutateAsync: createUserWithEmailPassword } = useCreateEmailIdUser();
  const { t, Trans } = useTranslationNamespace(['register', 'common']);
  const schema = YUP_SCHEMA(t);

  const PasswordFormik: FormikProps<PasswordWithCode> = useFormik({
    isInitialValid: false,
    initialValues: {
      password: '',
      confirmPassword: '',
      code: props.createUserCode, // 마스터 비번으로 로그인 시도하면 code가 없어서 비밀번호 활성화가 안됨
    } as PasswordWithCode,
    validationSchema: Yup.object({
      password: schema.password,
      confirmPassword: schema.password,
      code: schema.stringRequired,
    }),
    onSubmit: async (values) => {
      if (!isPasswordMatches) {
        alert(t('Common_wrong_password_label', { ns: 'common' }) as string);
        return;
      }
      try {
        createUserWithEmailPassword(values).then(() => {
          closeModal();
          if (props.accessToken) {
            navigate(PATH.MY.ACCOUNT);
          } else {
            alert(t('common_re_login', { ns: 'common' }));
          }
        });
      } catch (err: any) {
        if (err.data.error.code === 'user-0004') {
          closeModal();
        }
      }
    },
  });

  const onChangeConfirmPassword = (value: string) => {
    if (PasswordFormik.values.password === value) {
      setPasswordMatches(true);
    } else {
      setPasswordMatches(false);
    }
  };

  const formatLimitDate = (dateString: string) => {
    const milliseconds = dayjs(dateString).diff(dayjs());
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    return {
      date: days,
      hour: hours % 24,
      minute: minutes % 60,
    };
  };

  return (
    <Wrapper>
      <h4>{t('Common_login_method', { ns: 'common' })}</h4>
      <p className="description">
        <Trans i18nKey="Common_board_header" ns="common" />
      </p>
      <div className="input-wrapper">
        <div className="email-wrapper">
          <label htmlFor="email">{t('Common_id', { ns: 'common' })}</label>
          <p>{props.email}</p>
        </div>
        <div className="password-wrapper">
          <PasswordInput
            labelName={t('Common_password', { ns: 'common' })}
            id="password"
            value={PasswordFormik.values.password}
            onChange={(e) => {
              PasswordFormik.setFieldValue('password', e.target.value);
              onChangeConfirmPassword(e.target.value);
            }}
          />
          <ValidationWrapper>
            <PasswordValidation password={PasswordFormik.values.password as string} translationFn={t} />
          </ValidationWrapper>
        </div>
        <div className="password-wrapper">
          <PasswordInput
            labelName={t('Common_confirm_password', { ns: 'common' })}
            id="confirm-password"
            value={PasswordFormik.values.confirmPassword}
            onChange={(e) => {
              PasswordFormik.setFieldValue('confirmPassword', e.target.value);
              onChangeConfirmPassword(e.target.value);
            }}
          />
          {PasswordFormik.values.confirmPassword.length !== 0 && !isPasswordMatches && (
            <ValidationWrapper>
              <p className="invalid">{t('Common_wrong_password_label', { ns: 'common' })}</p>
            </ValidationWrapper>
          )}
        </div>
      </div>
      <div className="buttons-wrapper">
        {/* Access token이 없으면 limitDate가 초과되었다는 의미이므로 무조건 email로 변경해야함 */}
        {props.accessToken && (
          <Button
            variant="contained"
            style={{
              width: '50%',
              height: '54px',
              backgroundColor: '#A5AFC4',
              color: 'white',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '24px',
            }}
            onClick={() => navigate(PATH.MY.ACCOUNT)}
          >
            {t('Common_previous', { ns: 'common' })}
          </Button>
        )}
        <Button
          variant="contained"
          style={{
            width: props.accessToken ? '50%' : '100%',
            height: '54px',
            backgroundColor: PasswordFormik.isValid ? '#1352EF' : '#F0F2F8',
            color: PasswordFormik.isValid ? 'white' : '#CCCFD8',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
          }}
          onClick={() => PasswordFormik.handleSubmit()}
        >
          {t('Common_Confirm', { ns: 'common' })}
        </Button>
      </div>
      {props.accessToken && (
        <LimitDate>
          <Trans
            i18nKey="Common_date_info"
            values={{ ...formatLimitDate(props.limitDate) }}
            components={[<span />, <br />]}
          />
        </LimitDate>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 500px;

  h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .description {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .email-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 64px;
    padding: 10px 16px;
    gap: 6px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.line.my};
    background: ${({ theme }) => theme.bg.common};

    label {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: ${({ theme }) => theme.font.quaternary};
    }

    p {
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .password-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .buttons-wrapper {
    display: flex;
    width: 100%;
    gap: 16px;
    margin: 20px auto;
  }
`;

const LimitDate = styled.div`
  font-size: 13px;

  span {
    color: ${({ theme }) => theme.status.warning};
  }
`;
