import { Dialog } from '@mui/material';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose: (p: any, reason: any) => void;
  children: any;
  isPromotionPage?: boolean;
  disableEscapeKeyDown?: boolean;
}

export const Modal = ({ isOpen, onClose, children, isPromotionPage, disableEscapeKeyDown }: ModalProps) => {
  return (
    <ModalWrapper
      open={isOpen}
      onClose={onClose}
      sx={{
        borderRadius: 0,
      }}
      isPromotionPage={isPromotionPage}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {children}
    </ModalWrapper>
  );
};

//TODO: promotion 내린 뒤에 isPromotionPage 관련 로직 삭제
const ModalWrapper = styled(Dialog)<{ isPromotionPage?: boolean }>`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .MuiPaper-root {
    width: fit-content;
    margin: auto;
    color: ${({ theme }) => theme.font.primary};
    max-width: none;
    box-shadow: none;
    border-radius: 20px;
    background-color: ${({ isPromotionPage }) => isPromotionPage && 'transparent'};
  }
`;
