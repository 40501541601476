import Lottie from 'lottie-react';
import styled from 'styled-components';

import loadingAnimation from 'assets/icon/loading_spinner.json';
import { LoadingStore } from 'store/loadingStore';

const LoadingSpinner = () => {
  const { isLoading } = LoadingStore();

  return (
    <>
      {isLoading && (
        <DropBack>
          <Lottie animationData={loadingAnimation} style={{ width: '70px', height: '70px' }} />
        </DropBack>
      )}
    </>
  );
};

const DropBack = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
`;

export default LoadingSpinner;
