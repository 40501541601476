import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { useFormik } from 'formik';
import i18n from 'i18next';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import InvalidCheckIcon from 'assets/icon/invalid.svg';
import ValidCheckIcon from 'assets/icon/valid.svg';
import LabeledSelect from 'components/Common/LabeledSelect';
import PasswordInput from 'components/Common/PasswordInput';
import Timer from 'components/Common/Timer';
import DEVICE_SIZE from 'constant/deviceSize';
import STORAGE_KEYS from 'constant/storageKeys';
import { PASSWORD_REGEXP, YUP_SCHEMA } from 'constant/yupSchema';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { useGetUserIp } from 'services/react-query/countries';
import { useGetCurrenciesCT } from 'services/react-query/currencies';
import { useEmailSendCode, useEmailVerifyCode } from 'services/react-query/email';
import { useCreateFollowerAdditionalAccount } from 'services/react-query/followers';
import { useCreateLeaderAdditionalAccount } from 'services/react-query/leaders';
import { useUserInfoStore } from 'store/userInfoStore';

function AddAccountModal() {
  const { t } = useTranslationNamespace('common');
  const schema = YUP_SCHEMA(t);

  const HeaderBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.HEADER_BREAK_POINT})` });
  const currLang = i18n.language || localStorage.getItem(STORAGE_KEYS.i18nextLng);

  const { email } = useUserInfoStore();
  const { mutate: sendEmailCode, data: sendCodeData, isSuccess: isSuccessSendCode } = useEmailSendCode();
  const { mutate: verifyEmailCode, data: verifyCodeData, isSuccess: isSuccessVerifyCode } = useEmailVerifyCode();
  const { mutate: createFollowerAdditionalAccount } = useCreateFollowerAdditionalAccount();
  const { mutate: creatLeaderAdditionalAccount } = useCreateLeaderAdditionalAccount();
  const { data: userIp } = useGetUserIp();
  const { data: currencies } = useGetCurrenciesCT();

  const [emailVerification, setEmailVerification] = useState({
    emailAddress: '',
    code: 0,
    secureVerificationCd: 0,
  });
  const [isNext, setNext] = useState(false);
  const [timer, setTimer] = useState({
    isStart: false,
    isFinish: false,
  });
  const [valids, setValids] = useState({
    /**
     * a~e: Password Validation
     * f: Email Verification
     * g: Required Validation
     */
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
    f: false,
    g: false,
  });
  const formik = useFormik({
    initialValues: {
      memberTypeCd: 57,
      currencyCd: currLang === 'ja_JP' ? 3 : 1,
      leverage: 500,
      mtVersion: 4,
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      memberTypeCd: schema.numberRequired,
      currencyCd: schema.numberRequired,
      leverage: schema.numberRequired,
      password: schema.password,
      confirmPassword: schema.password,
    }),
    onSubmit: (values) => {
      const params = {
        ...values,
        signUpIpAddress: userIp as string,
        twoFactorAuthentication: {
          type: 'email',
          secureVerificationCd: emailVerification.secureVerificationCd,
        },
      };
      const { mtVersion, ...rest } = params;

      if (formik.values.memberTypeCd === 57) return createFollowerAdditionalAccount(rest);

      return creatLeaderAdditionalAccount({ mtVersion, ...rest });
    },
  });

  useEffect(() => {
    setValids((prev) => ({
      ...prev,
      a: PASSWORD_REGEXP.uppercaseAndLowercaseRequired.test(formik.values.password),
      b: PASSWORD_REGEXP.specialCharactersRequired.test(formik.values.password),
      c: PASSWORD_REGEXP.lengthRequired.test(formik.values.password),
      d: PASSWORD_REGEXP.numberRequired.test(formik.values.password),
      e: formik.values.password === formik.values.confirmPassword,
      g: Object.entries(formik.values).every(([key, value]) => {
        if (formik.values.memberTypeCd === 57 && key === 'mtVersion') return true;
        return value;
      }),
    }));
  }, [formik.values]);

  useEffect(() => {
    if (!sendCodeData || !isSuccessSendCode) return;

    const { emailAddress, secureVerificationCd } = sendCodeData;

    setEmailVerification((prev) => ({ ...prev, emailAddress, secureVerificationCd }));
    setTimer({ isStart: true, isFinish: false });
  }, [sendCodeData, isSuccessSendCode]);

  useEffect(() => {
    if (!verifyCodeData || !isSuccessVerifyCode) return;

    setValids((prev) => ({ ...prev, f: true }));
    setTimer({ isStart: false, isFinish: true });
  }, [verifyCodeData, isSuccessVerifyCode]);

  const canSubmit = useMemo(() => {
    return Object.values(valids).every(Boolean);
  }, [valids]);

  return (
    <Wrapper isHeaderBreak={HeaderBreakPoint}>
      <Title>{t('new_account')}</Title>

      {!isNext && (
        <>
          <Description>
            <div>{t('select_account_type')}</div>
            <div>{t('select_account_info')}</div>
          </Description>
          <RadioButtonWrapper>
            <RadioButton>
              <input
                type="radio"
                id="follower"
                name="type"
                value="57"
                onChange={(e) => formik.setFieldValue('memberTypeCd', parseInt(e.target.value))}
                defaultChecked
              />
              <label htmlFor="follower">{t('follower_account')}</label>
            </RadioButton>
            <RadioButton>
              <input
                type="radio"
                id="leader"
                name="type"
                value="56"
                onChange={(e) => formik.setFieldValue('memberTypeCd', parseInt(e.target.value))}
              />
              <label htmlFor="leader">{t('leader_account')}</label>
            </RadioButton>
          </RadioButtonWrapper>
          <ModalButton onClick={() => setNext(!isNext)}>{t('next-step')}</ModalButton>
        </>
      )}

      {isNext && (
        <Form onSubmit={formik.handleSubmit}>
          <InputWrapper>
            <LabeledSelect
              label={t('currency')}
              placeholder={t('select_placeholder') || ''}
              items={currencies?.map(({ currencyAbbr, currencyCd }) => [currencyAbbr, currencyCd]) || []}
              defaultValue={currLang === 'ja_JP' ? 'JPY' : 'USD'}
              onChange={(value) => formik.setFieldValue('currencyCd', value)}
            />
            <LabeledSelect
              label={t('leverage')}
              placeholder={t('select_placeholder') || ''}
              items={[
                ['1 : 1', 1],
                ['10 : 1', 10],
                ['25 : 1', 25],
                ['50 : 1', 50],
                ['100 : 1', 100],
                ['200 : 1', 200],
                ['300 : 1', 300],
                ['400 : 1', 400],
                ['500 : 1', 500],
              ]}
              defaultValue={`${formik.values.leverage} : 1`}
              onChange={(value) => formik.setFieldValue('leverage', value)}
            />
            {formik.values.memberTypeCd === 56 && (
              <LabeledSelect
                label={t('platform')}
                placeholder={t('select_placeholder') || ''}
                items={[
                  ['MT4', 4],
                  ['MT5', 5],
                ]}
                defaultValue={`MT${formik.values.mtVersion}`}
                onChange={(value) => formik.setFieldValue('mtVersion', value)}
              />
            )}
            <PasswordInput
              id="account-password"
              labelName={t('Register_password')}
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue('password', e.target.value)}
              onClickClearButton={() => formik.setFieldValue('password', '')}
            />
            {formik.values.password.length !== 0 && (
              <ValidationWrapper>
                <span className={valids.a ? 'valid' : 'invalid'}>{t('password_validation_label1')}</span>
                <span className={valids.b ? 'valid' : 'invalid'}>{t('password_validation_label2')}</span>
                <span className={valids.c ? 'valid' : 'invalid'}>{t('password_validation_label3')}</span>
                <span className={valids.d ? 'valid' : 'invalid'}>{t('password_validation_label4')}</span>
              </ValidationWrapper>
            )}
            <PasswordInput
              id="account-confirm-password"
              labelName={t('Register_confirm_password')}
              value={formik.values.confirmPassword}
              onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value)}
              onClickClearButton={() => formik.setFieldValue('confirmPassword', '')}
            />
            {formik.values.confirmPassword.length !== 0 && (
              <ValidationWrapper>
                {valids.e ? (
                  <span className="valid">{t('Common_matches_password')}</span>
                ) : (
                  <span className="invalid">{t('Common_wrong_password_label')}</span>
                )}
              </ValidationWrapper>
            )}
            <Input>
              <label htmlFor="email-address">{t('email_address')}</label>
              <input type="text" id="email-address" defaultValue={email} disabled />
              <button type="button" disabled={timer.isStart || timer.isFinish} onClick={() => sendEmailCode(email)}>
                {t('Leader_Setting_Edit_Mobile_Code')}
              </button>
            </Input>
            <Input>
              <label htmlFor="verification-code">{t('Register_verification_code')}</label>
              <input
                type="text"
                id="verification-code"
                placeholder={t('verification_code_placeholder') || ''}
                disabled={timer.isFinish}
                onChange={(e) => setEmailVerification((prev) => ({ ...prev, code: parseInt(e.target.value) }))}
              />
              {timer.isStart && (
                <Timer
                  startTime={sendCodeData?.registeredDatetime}
                  endTime={sendCodeData?.expiredDatetime}
                  enableStart={timer.isStart}
                  enableEnd={timer.isFinish}
                  expireMinute={timer.isStart === true ? 5 : undefined}
                  onTimeOutCallback={() => setTimer({ isStart: false, isFinish: false })}
                />
              )}
              <button
                type="button"
                disabled={!timer.isStart || timer.isFinish}
                onClick={() => verifyEmailCode(emailVerification)}
              >
                {t('Leader_Setting_Edit_Email_Validate_Button')}
              </button>
            </Input>
            {isSuccessVerifyCode && (
              <ValidationWrapper>
                <span className="valid">{t('verification_success')}</span>
              </ValidationWrapper>
            )}
          </InputWrapper>
          <ModalButton type="submit" disabled={!canSubmit}>
            {t('complete')}
          </ModalButton>
        </Form>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isHeaderBreak: boolean }>`
  padding: 10px 20px 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: ${({ theme }) => theme.font.primary};
  line-height: 24px;

  ${({ isHeaderBreak }) =>
    isHeaderBreak &&
    css`
      padding: 0;
      width: 300px;

      ${Form} {
        gap: 20px;
      }
    `}
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;

const Description = styled.div`
  text-align: left;

  & > div:not(:first-child) {
    color: ${({ theme }) => theme.font.quaternary};
  }
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  & input:checked {
    accent-color: ${({ theme }) => theme.color.primary};

    & + label {
      color: ${({ theme }) => theme.color.primary};
      font-weight: 600;
    }
  }
`;

const RadioButton = styled.div`
  font-size: 17px;
  line-height: 24px;
  display: flex;
  gap: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Input = styled.div`
  position: relative;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.line.my};
  border-radius: 10px;
  overflow: hidden;

  &:focus-within {
    border-color: ${({ theme }) => theme.color.primary};

    label {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px 16px;
    display: block;
    color: ${({ theme }) => theme.font.quaternary};
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  input {
    padding: 30px 16px 10px;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;

    &::placeholder {
      color: ${({ theme }) => theme.font.placeholder};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.bg.common};
    }
  }

  div {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
  }

  button {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    padding: 0 8px;
    width: 80px;
    height: 42px;
    color: ${({ theme }) => theme.button.primary};
    border: 1px solid ${({ theme }) => theme.button.primary};
    border-radius: 6px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    overflow-wrap: break-word;

    &:disabled {
      color: ${({ theme }) => theme.font.disable};
      border-color: ${({ theme }) => theme.line.my};
    }
  }
`;

const ValidationWrapper = styled.div`
  margin-top: -12px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 12px;
  line-height: 14px;
  user-select: none;

  .invalid,
  .valid {
    display: flex;
    gap: 1px;
    justify-content: center;
    align-items: center;

    &:after {
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }

  .invalid {
    color: ${({ theme }) => theme.status.warning};

    &:after {
      content: url(${InvalidCheckIcon});
    }
  }

  .valid {
    color: ${({ theme }) => theme.status.approved};

    &:after {
      content: url(${ValidCheckIcon});
    }
  }
`;

const ModalButton = styled.button`
  padding: 15px 0;
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;

  &:disabled {
    color: ${({ theme }) => theme.font.disable};
    background-color: #f0f2f8;
  }
`;

export default AddAccountModal;
