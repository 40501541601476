import { useQuery } from '@tanstack/react-query';

import { api, ResponsePromise } from 'services/apiService';
import { setSupportedLanguages } from 'store/languagesListStore';

interface CountryDto {
  countryCd: number;
  countryName: string;
  phoneCountry: number;
  countryAbbr: string;
  isSupported: boolean;
}

export interface GetLanguagesResponseDto {
  languageCd: number;
  languageAbbr: string;
  languageCode: string;
  languageFull: string;
  country: CountryDto;
}

const getLanguages = (): ResponsePromise<Array<GetLanguagesResponseDto>> => {
  return api.get('/languages');
};

export const useGetLanguages = () => {
  return useQuery({
    queryKey: ['getLanguages'],
    queryFn: () => getLanguages(),
    onSuccess: (data) => {
      const supportedLanguages = data.filter((lang) => lang.country.isSupported === true);
      setSupportedLanguages(supportedLanguages);
    },
    onError: (err) => {
      console.log(err);
    },
  });
};
