import { useNavigate } from 'react-router';

import styled from 'styled-components';

import Button from 'components/Common/Button';
import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { RoleType as AccountType, CreateStMemberResponseDto } from 'services/common';

interface StepProps {
  resData?: CreateStMemberResponseDto;
  accountType: AccountType;
}

interface TranslationItem {
  key: keyof CreateStMemberResponseDto;
  value: string;
  ns?: string;
}

const translationForTableLabel: TranslationItem[] = [
  { key: 'email', value: 'Common_id', ns: 'common' },
  { key: 'password', value: 'Register_Complete_Credential_Password' },
  {
    key: 'mtAccountNo',
    value: 'Register_Complete_Credential_MTNumber',
  },
  { key: 'leverage', value: 'Register_Complete_Credential_Leverage' },
  { key: 'currency', value: 'Register_Complete_Credential_Currency' },
  { key: 'server', value: 'Register_Complete_Credential_Server' },
  { key: 'platform', value: 'Register_Info_Platform' },
];

const commonButtonStyle = {
  width: '274px',
  height: '54px',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#FFFFFF',
};

const Step3 = ({ resData, accountType }: StepProps) => {
  // promotion 페이지에서도 쓰이는데, promotion Json과 함께 호출되므로 register를 참조하라고 명시해줘야 정상동작함
  const { t, Trans } = useTranslationNamespace('register');
  const navigate = useNavigate();

  const handleValue = (key: string, value: string | number) => {
    if (key !== 'password' && key !== 'platform') {
      return value;
    }
    if (key === 'password') {
      return t('Register_success_password');
    }
    if (key === 'platform') {
      return 'MT' + value;
    }
  };

  const getTableLabel = (key: string) => {
    const tableItem = translationForTableLabel.find((label) => label.key === key);

    if (tableItem?.ns === 'common') return t(tableItem?.value!, { ns: 'common' });
    return t(tableItem?.value!);
  };

  return (
    <StepWrapper>
      <h2>
        <Trans
          i18nKey={accountType === 'Follower' ? 'Register_Complete_Desc' : 'Register_Complete_Desc_Leader'}
          ns="register"
        />
      </h2>
      <p>{t('Register_Complete_Credential')}</p>
      <div className="info-wrapper">
        {Object.entries(resData!).map(([key, value], index) => (
          <InfoWrapper key={index}>
            <div className="key">{getTableLabel(key)}</div>
            <div className="value">{handleValue(key, value)}</div>
          </InfoWrapper>
        ))}
        <InfoWrapper>
          <div className="key">{t('Register_Complete_Credential_Password')}</div>
          <div className="value">{t('Register_success_password')}</div>
        </InfoWrapper>
      </div>
      <p className="sub-desc">{t('Register_Complete_Credential_Remark')}</p>
      <ButtonWrapper>
        <Button
          variant="contained"
          style={{ backgroundColor: '#A5AFC4', ...commonButtonStyle }}
          onClick={() => navigate(PATH.MAIN)}
        >
          {t('Register_Complete_Credential_Button1')}
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: '#1352EF', ...commonButtonStyle }}
          onClick={() => navigate(PATH.LOGIN)}
        >
          {t('Register_Complete_Credential_Button2')}
        </Button>
      </ButtonWrapper>
    </StepWrapper>
  );
};

export default Step3;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px;
  font-size: 17px;
  line-height: 24px;
  color: ${({ theme }) => theme.font.primary};
  gap: 0px;
  padding: 80px;

  > * {
    width: 100%;
    max-width: 480px;
  }

  .info-wrapper {
    width: 100%;
    > div:first-child {
      border-top: 1px solid #dddddd;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 50px;
  }

  p {
    width: 100%;
    margin-bottom: 11px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }

  .sub-desc {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-top: 11px;
    margin-bottom: 0;
    color: #6a6a6a;
  }

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    align-items: center;
    padding: 0px;

    h2 {
      margin-bottom: 40px;
    }
  }

  div.info-wrapper:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.line.my};
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 480px;
  min-height: 54px;
  border-bottom: 1px solid #dddddd;

  .key {
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    background-color: #f7f8fa;
    font-size: 15px;
    line-height: 24px;
    color: #6a6a6a;
  }

  .value {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
  }

  @media (max-width: ${DEVICE_SIZE.CONTENTS_MAX_WIDTH}) {
    .key {
      width: 120px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 30px;
  width: 100%;

  button {
    flex: 1;
    width: fit-content !important;
  }
`;
