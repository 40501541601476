/*
 * en_GB 선택시 en_US로 호출되도록 switch
 * 번역 파일에는 en_GB가 존재하지 않기 때문 (en_US로 대체)
 */

const switchEnGbToEnUs = (languageCode: string) => {
  if (languageCode === 'en_GB') return 'en_US';
  return languageCode;
};

export default switchEnGbToEnUs;
