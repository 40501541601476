import { ComponentProps } from 'react';

import { Radio as MuiRadio } from '@mui/material';

import { ReactComponent as CheckedIcon } from 'assets/icon/radio_checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/icon/radio_unchecked.svg';

export default function StyledRadio(props: ComponentProps<typeof MuiRadio>) {
  return (
    <MuiRadio
      {...props}
      checkedIcon={<CheckedIcon width={24} height={24} />}
      icon={<UncheckedIcon width={24} height={24} />}
    />
  );
}
