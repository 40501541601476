import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { subscribeWithSelector } from 'zustand/middleware';

import STORAGE_KEYS from 'constant/storageKeys';
import { MT4AccountsDto } from 'services/common';

interface Mt4AccountNosSelectListStoreProps {
  mt4AccountNos: MT4AccountsDto[]; // Select Box에 노출될 모든 계좌들 정보 (Leader, Follower 모두 포함)
  setMt4AccountNos: (data: MT4AccountsDto[]) => void;
  selectedMt4AccountNo?: MT4AccountsDto;
  setSelectedMt4AccountNo: (data?: MT4AccountsDto) => void; // Select Box의 default값 혹은 Select box에서 선택된 계좌
}

export const Mt4AccountNosSelectListStore = create<Mt4AccountNosSelectListStoreProps>()(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        mt4AccountNos: [],
        setMt4AccountNos: (data) => {
          if (data.find((el) => el.mt4AccountNo === get().selectedMt4AccountNo?.mt4AccountNo) !== undefined) {
            set(() => ({ mt4AccountNos: [...data] }));
          } else {
            set(() => ({ mt4AccountNos: [...data] }));
          }
        },
        selectedMt4AccountNo: undefined,
        setSelectedMt4AccountNo: (data) => {
          if (data === get().selectedMt4AccountNo) {
            return;
          }
          set(() => ({
            selectedMt4AccountNo: data ?? undefined,
          }));
        },
      }),
      { name: STORAGE_KEYS.mt4AccountNosList }
    )
  )
);

export const { mt4AccountNos, selectedMt4AccountNo, setMt4AccountNos, setSelectedMt4AccountNo } =
  Mt4AccountNosSelectListStore.getState();
