import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const calcPeriod = (startDate: Dayjs, endDate: Dayjs): number[] => {
  const years = endDate.diff(startDate, 'year');
  const months = endDate.subtract(years, 'year').diff(startDate, 'month');
  const days = endDate.subtract(years, 'year').subtract(months, 'month').diff(startDate, 'day');
  return [years, months, days];
};

export const formatPeriod = (startDate?: string | null, endDate?: string | null, useSpace?: boolean): string => {
  if (startDate === undefined || startDate === null) return '-';
  const period = calcPeriod(dayjs(startDate).utc(), endDate ? dayjs(endDate).utc() : dayjs().utc());
  const space = useSpace ? ' ' : '';
  const yearString = `${period[0]}${space}Year`;
  const monthString = `${period[1]}${space}Month`;
  const dayString = `${period[2]}${space}Day`;
  if (period[0] !== 0) return [yearString, monthString, dayString].join(' ');
  if (period[1] !== 0) return [monthString, dayString].join(' ');
  return dayString;
};

export const formatDate = (date?: string | null, options?: { onlyDate?: boolean }) => {
  if (date === undefined || date === null) return '-';
  if (!options?.onlyDate) return dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss');
  return dayjs(date).utc().format('YYYY-MM-DD');
};

export const formatString = (input?: string | null) => {
  if (input === undefined || input === null || input === '') return '-';
  return input;
};

interface NumberFormatOptions {
  signDisplay?: boolean;
  compact?: boolean;
  symbol?: string;
  maxFractionalDigits?: number;
  maxSignificantDigits?: number;
  useRawNumber?: boolean;
  options?: Intl.NumberFormatOptions;
}

export const formatNumber = (input: number | undefined | null, options?: NumberFormatOptions): string => {
  let value = 0;
  if (input) {
    if (options?.useRawNumber) {
      value = input;
    } else {
      if (options?.compact && input >= 1000) {
        const log = Math.floor(Math.log10(input) / 3) * 3;
        const pow = options?.maxFractionalDigits ? log - options?.maxFractionalDigits : log - 2;
        const div = Math.pow(10, pow);
        value = Math.floor(input / div) * div;
      } else {
        const mult = options?.maxFractionalDigits ? Math.pow(10, options.maxFractionalDigits) : 100;
        value = Math.floor(input * mult) / mult;
      }
    }
  }
  const num = Math.abs(value).toLocaleString('en-GB', {
    ...options?.options,
    maximumFractionDigits:
      options?.maxFractionalDigits !== undefined
        ? options.maxFractionalDigits
        : options?.maxSignificantDigits !== undefined
        ? undefined
        : options?.symbol === '¥'
        ? 0
        : 2,
    maximumSignificantDigits: options?.maxSignificantDigits,
    notation: options?.compact ? 'compact' : 'standard',
  });
  const sign = value < 0 ? '-' : options?.signDisplay ? '+' : '';
  return `${sign}${options?.symbol ?? ''}${num}`;
};

export const toPascalCase = (value: string) => {
  return value.toLowerCase().replace(/^[a-zA-Z]/, (el) => el.toUpperCase());
};
