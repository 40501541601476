import { CSSProperties, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

interface TimerProps {
  startTime?: string; // response로 startTime및 endTime이 주어질때 사용
  endTime?: string;
  expireMinute?: number; // response로 startTime및 endTime이 주어지지 않을때 사용
  enableEnd: boolean; // 종료 조건
  enableStart?: boolean; // 재개 조건
  style?: CSSProperties;
  onTimeOutCallback?: () => void;
}

const Timer = (props: TimerProps) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);

  const time = useRef<number>(0); // 초단위
  const timerId = useRef<any>(null);

  useEffect(() => {
    // time 초기화
    if (props.endTime === undefined && props.startTime === undefined) return;
    else {
      let _time = Date.parse(props.endTime!) - Date.parse(props.startTime!);
      time.current = _time / 1000 - 1;
      setMinutes(Math.floor(time.current / 60));
    }
  }, [props.endTime, props.startTime]);

  useEffect(() => {
    if (props.expireMinute === undefined) return;
    time.current = props.expireMinute * 60 - 1;
    setMinutes(Math.floor(time.current / 60));
  }, [props.expireMinute]);

  useEffect(() => {
    timerId.current = setInterval(() => {
      time.current -= 1;
      setMinutes(Math.floor(time.current / 60));
      setSeconds(time.current % 60);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, []);

  // Timer 종료
  useEffect(() => {
    if (time.current > 0) {
      return;
    }
    props.onTimeOutCallback?.();
    clearInterval(timerId.current);
  }, [seconds]);

  useEffect(() => {
    if (!props.enableEnd) {
      return;
    }
    clearInterval(timerId.current);
  }, [props.enableEnd]);

  useEffect(() => {
    if (!props.enableStart) {
      return;
    }
    clearInterval(timerId.current); // 기존 타이머 중지
    time.current = props.expireMinute! * 60 - 1; // time.current 초기화
    setMinutes(Math.floor(time.current / 60)); // minutes, seconds state 초기화
    setSeconds(time.current % 60);
    timerId.current = setInterval(() => {
      // 타이머 재시작
      time.current -= 1;
      setMinutes(Math.floor(time.current / 60));
      setSeconds(time.current % 60);
    }, 1000);
  }, [props.enableStart]);

  return (
    <TimerWrapper enableEnd={props.enableEnd} enableStart={props.enableStart} style={props.style}>
      {minutes}:{seconds}
    </TimerWrapper>
  );
};

export default Timer;

const TimerWrapper = styled.div<{ enableEnd: boolean; enableStart?: boolean; style?: CSSProperties }>`
  display: ${({ enableStart }) => enableStart && 'block'};
  display: ${({ enableEnd }) => enableEnd && 'none'};
  width: fit-content;
  min-width: 33px;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: ${({ theme }) => theme.status.warning};
  ${({ style }) => style && { ...style }};
`;
