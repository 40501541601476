import { create } from 'zustand';

import { GetLanguagesResponseDto } from 'services/react-query/languages';

interface SupportedLanguagesListDto {
  supportedLanguages: Array<GetLanguagesResponseDto>;
  setSupportedLanguages: (data: Array<GetLanguagesResponseDto>) => void;
}

export const LanguagesListStore = create<SupportedLanguagesListDto>((set) => ({
  supportedLanguages: [],
  setSupportedLanguages: (supportedLanguages) => {
    set({
      supportedLanguages,
    });
  },
}));

export const { supportedLanguages, setSupportedLanguages } = LanguagesListStore.getState();
