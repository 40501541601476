/*
Color naming은 피그마상 변수와 유사하게 맞춤
_을 기준으로 앞은 객체로 분리, 뒤는 camel case로 네이밍
넘버링이 있는 경우 primary, secondary, tertiary... 로 변환
example - account_prime => account.primary
example2 - bg_bb => bg.doubleBlue
example3 - text3 => text.tertiary
*/

const defaultTheme = {
  color: {
    primary: '#1352EF',
    secondary: '#A5AFC4',
    white: '#FFFFFF',
    black: '#000000',
    guide: '#8298FF',
    sub1: '#63A8FE',
    sub2: '#2586DB',
    sub3: '#BB8742',
    icon: '#D3D4D7',
    iconMy: '#B7BEC9',
    topInfo: '#F1F2F6',
    headerBlack: '#171619',
    hover: '#EAF1FB',
  },

  font: {
    primary: '#333333',
    secondary: '#434343',
    tertiary: '#6A6A6A',
    quaternary: '#858585',
    guide: '#B5B5B5',
    copy: '#00BA29',
    placeholder: '#C6C6C6',
    disable: '#CCCFD8',
  },

  bg: {
    common: '#F7F8FA',
    primary: '#F1F2F6',
    secondary: '#212938',
    header: '#0D1B31',
    newHeader: '#91B5D9',
    footer: '#F5F5F5',
    yellow: '#F8F2EB',
    blue: '#EFF6FF',
    doubleBlue: '#F2F6F9',
    copy: '#E7F5EA',
    warning: '#FFE9E9',
  },

  line: {
    common: '#979797',
    my: '#DDDDDD',
    box: '#ECECEC',
  },

  account: {
    primary: '#464D5B',
    ecn: '#172F9A',
    swapFree: '#4376D3',
    standard: '#000000',
  },

  status: {
    pending: '#6282CC',
    approved: '#01A387',
    canceled: '#E35138',
    warning: '#EE3D3D',
  },

  button: {
    primary: '#1352EF',
    secondary: '#464D5B',
    tap: '#89AEDC',
  },
};

export default defaultTheme;
