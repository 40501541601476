import i18n from 'i18next';
import styled from 'styled-components';

import STORAGE_KEYS from 'constant/storageKeys';
import { LanguagesListStore } from 'store/languagesListStore';
import { handleChangeLng } from 'translation/i18n';
import switchEnGbToEnUs from 'utils/switchEnGbToEnUs';

interface LanguageSelectBoxProps {
  onClose: () => void;
}

export default function LanguageSelectBox({ onClose }: LanguageSelectBoxProps) {
  const { supportedLanguages } = LanguagesListStore();
  const currentLang = i18n?.language ?? localStorage.getItem(STORAGE_KEYS.i18nextLng);

  return (
    <LanguageSelectBoxWrapper>
      {supportedLanguages.map((lang, index) => (
        <GridItem
          onClick={() => {
            document.documentElement.setAttribute('lang', lang.languageAbbr);
            handleChangeLng(lang.languageCode);
            onClose();
          }}
          isCurrentLang={currentLang === switchEnGbToEnUs(lang.languageCode)}
          key={index}
        >
          <div className="first">{lang.languageFull}</div>
          <div className="second">{lang.country.countryName}</div>
        </GridItem>
      ))}
    </LanguageSelectBoxWrapper>
  );
}

const LanguageSelectBoxWrapper = styled.ul`
  width: 500px;
  height: 610px;
  padding: 40px 50px;
  position: absolute;
  top: 50px;
  right: -30px;
  display: grid;
  flex-wrap: wrap;
  gap: 14px 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  background-color: ${({ theme }) => theme.button.secondary};
  z-index: 1000;
  animation: growDown 100ms ease-in-out forwards;
  transform-origin: top center;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const GridItem = styled.li<{ isCurrentLang?: boolean }>`
  width: 120px;
  max-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;

  .first {
    font-size: 15px;
    line-height: 20px;
    color: ${({ theme, isCurrentLang }) => (isCurrentLang ? theme.color.sub1 : theme.color.white)};
  }

  .second {
    font-size: 10px;
    line-height: 15px;
    color: #8c96a9;
  }
`;
