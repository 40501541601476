import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetCountries, useGetMyCountry } from 'services/react-query/countries';
import { useGetLanguages } from 'services/react-query/languages';
import { useUserAccountsList, useUserInfo } from 'services/react-query/user.tsx';
import { CountriesListStore } from 'store/countriesListStore';
import { LanguagesListStore } from 'store/languagesListStore';
import { Mt4AccountNosSelectListStore } from 'store/selectAccountsStore';
import { useUserMobileCountryStore } from 'store/userInfoStore';
import { useUserLoginStore } from 'store/userInfoStore';

const FetchUserInfo = () => {
  const { pathname } = useLocation();

  const { selectedMt4AccountNo } = Mt4AccountNosSelectListStore();
  const { supportedCountries } = CountriesListStore();
  const { mobileCountry } = useUserMobileCountryStore();
  const { isLoggedIn } = useUserLoginStore();
  const { supportedLanguages } = LanguagesListStore();

  useUserAccountsList(isLoggedIn && pathname.includes('my'));
  const { refetch: refetchCountriesList } = useGetCountries(true);
  const { refetch: refetchMyCountry } = useGetMyCountry();
  const { refetch: refetchLanguagesList } = useGetLanguages();
  const { refetch: refetchUsersInfo } = useUserInfo(
    selectedMt4AccountNo?.mt4AccountNo!,
    selectedMt4AccountNo?.mt4AccountNo !== undefined && isLoggedIn
  );

  useEffect(() => {
    if (supportedCountries.length === 0) {
      refetchCountriesList();
    }

    if (mobileCountry.length === 0) {
      refetchMyCountry();
    }

    if (supportedLanguages.length === 0) {
      refetchLanguagesList();
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!selectedMt4AccountNo?.mt4AccountNo) return;
    refetchUsersInfo();
  }, [isLoggedIn, selectedMt4AccountNo?.mt4AccountNo]);

  return null;
};

export default FetchUserInfo;
