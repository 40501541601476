export const setIbCode = (ibCode: string) => {
  window.localStorage.setItem('IBCode', ibCode);
  window.localStorage.setItem('IBCodeExpireTime', (new Date().getTime() + 1000 * 60 * 60 * 24).toString());
};

export const getIbCode = () => {
  const expireTime = window.localStorage.getItem('IBCodeExpireTime');
  if (expireTime === null || parseInt(expireTime) < new Date().getTime()) {
    window.localStorage.removeItem('IBCode');
    window.localStorage.removeItem('IBCodeExpireTime');
    return null;
  } else {
    return window.localStorage.getItem('IBCode');
  }
};
