import { useQuery } from '@tanstack/react-query';

import { api, ResponsePromise } from 'services/apiService';
import { setSupportedCountries } from 'store/countriesListStore';
import { setUserCountryState } from 'store/userInfoStore';

export interface GetCountriesResponseDto {
  countryCd: number;
  countryName: string;
  phoneCountry: number;
  countryAbbr: string;
  isSupported: boolean;
  smsEnabled: boolean;
}

const getCountries = (): ResponsePromise<Array<GetCountriesResponseDto>> => {
  return api.get('/countries');
};

const getUserIp = (): ResponsePromise<string> => {
  return api.get('/countries/my-ip', { useLoading: false });
};

const getMyCountry = (): ResponsePromise<GetCountriesResponseDto> => {
  return api.get('/countries/my-country');
};

export const useGetCountries = (trigger?: boolean) => {
  return useQuery({
    queryKey: ['getCountries'],
    queryFn: () => getCountries(),
    onSuccess: (data) => {
      const supportedCountries = data.filter((country) => country.isSupported === true);
      setSupportedCountries(supportedCountries);
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
  });
};

export const useGetUserIp = () => {
  return useQuery({
    queryKey: ['getUserIp'],
    queryFn: () => getUserIp(),
    onError: (err) => {
      console.log(err);
    },
  });
};

export const useGetMyCountry = () => {
  return useQuery({
    queryKey: ['getMyCountry'],
    queryFn: () => getMyCountry(),
    onSuccess: (userCountry) => {
      setUserCountryState({ userCountry });
    },
    onError: (err) => {
      console.log(err);
    },
  });
};
