import { useInfiniteQuery, useQueries, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import { defaultFieldOrders } from 'constant/order';
import { api, ResponsePromise } from 'services/apiService';

import { GetLeadersResponseDto } from './leaders';

export type FindStEventsResponseDto = Array<{
  eventCd: number;
  eventId: string;
  name: string;
  displayOnLanding: number;
  displayOnLeaderboard: number;
  defaultOrderBy: 'roi' | 'aum' | 'followers' | 'mdd' | undefined;
}>;

const getEventList = (): ResponsePromise<FindStEventsResponseDto> => {
  return api.get(`/st-events`);
};

export const useEventList = (lang?: string, trigger?: boolean) => {
  return useQuery({
    queryKey: ['eventList', lang],
    queryFn: getEventList,
    onSuccess: () => {},
    onError: (err) => console.log(err),
    enabled: trigger,
  });
};

export type FindStEventLeadersResponseDto = GetLeadersResponseDto;
export interface GetEventLeadersQueryString {
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}

const getEventLeaders = (
  eventCd: number,
  query?: GetEventLeadersQueryString
): ResponsePromise<FindStEventLeadersResponseDto> => {
  return api.get(`/st-events/${eventCd}/leaders?${queryString.stringify(query ?? {})}`);
};

export const useEventLeaders = (eventCd: number, query: GetEventLeadersQueryString, trigger?: boolean) => {
  return useQuery({
    queryKey: ['eventLeaders', eventCd, query],
    queryFn: () => getEventLeaders(eventCd, query),
    onSuccess: () => {},
    onError: (err: any) => console.log(err),
    enabled: trigger,
  });
};

export const useInfiniteEventLeaders = (eventCd: number, query: GetEventLeadersQueryString, trigger?: boolean) => {
  return useInfiniteQuery({
    queryKey: ['eventLeaders', eventCd, query],
    queryFn: ({ pageParam = 1 }) => getEventLeaders(eventCd, { ...query, page: pageParam }),
    onSuccess: () => {},
    onError: (err: any) => console.log(err),
    enabled: trigger,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });
};

export const useEventsLeaders = (
  events: FindStEventsResponseDto,
  query: GetEventLeadersQueryString,
  trigger?: boolean
) => {
  return useQueries({
    queries: events.map((event) => ({
      queryKey: ['eventLeaders', event.eventCd],
      queryFn: () =>
        getEventLeaders(event.eventCd, {
          ...query,
          orderByField: event.defaultOrderBy ?? 'roi',
          orderByOrder: defaultFieldOrders[event.defaultOrderBy ?? 'roi'],
        }),
      onSuccess: () => {},
      onError: (err: any) => console.log(err),
      enabled: trigger,
    })),
  });
};

const getCompetitionRanking = (
  eventCd: number,
  query: GetEventLeadersQueryString,
  useLoading?: boolean
): ResponsePromise<FindStEventLeadersResponseDto> => {
  return api.get(`/st-events/${eventCd}/leaders?${queryString.stringify(query)}`, {
    useLoading: useLoading ?? true,
  });
};

export const useGetCompetitionRanking = (
  eventCd: number,
  query: GetEventLeadersQueryString,
  trigger?: boolean,
  options?: { useLoading?: boolean; keepPreviousData?: boolean; cacheTime?: number }
) => {
  return useQuery({
    queryKey: ['leaders', queryString.stringify(query)],
    queryFn: () => getCompetitionRanking(eventCd, { ...query }, options?.useLoading),
    onError: (err) => {},
    enabled: trigger,
    keepPreviousData: options?.keepPreviousData ?? true,
    cacheTime: options?.cacheTime,
  });
};
