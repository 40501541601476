import { Cookies } from 'react-cookie';

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import mem from 'mem';
import { CookieGetOptions, CookieSetOptions } from 'universal-cookie/cjs/types';

import PATH from 'constant/paths';
import STORAGE_KEYS from 'constant/storageKeys';
import { setLoading } from 'store/loadingStore';
import { handleBaseUrl } from 'utils/handleBaseUrl';
import { handleDomain } from 'utils/handleDomain';

import { LoginResponseDto } from './react-query/auth';

export type Response<T> = T & { _response?: Omit<AxiosResponse, 'data'> };
export type ResponsePromise<T> = Promise<Response<T>>;

const config: AxiosRequestConfig = {
  baseURL: handleBaseUrl(),
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
    accept: 'application/json,',
  },
  useLoading: true,
  useToken: false,
};

const privateConfig: AxiosRequestConfig = {
  ...config,
  useToken: true,
};

const api = axios.create(config);
const apiPrivate = axios.create(privateConfig);

api.interceptors.request.use(
  (config) => {
    config.headers['x-custom-lang'] = localStorage.getItem(STORAGE_KEYS.i18nextLng) ?? 'en_US';
    config.useLoading && setLoading(true);
    return config;
  },
  (error) => {
    config.useLoading && setLoading(true);
    return Promise.reject(error.response.data);
  }
);

api.interceptors.response.use(
  (response) => {
    setLoading(false);
    let { data, ...rest } = response;
    if (typeof data === 'object') {
      data._response = rest;
      Object.defineProperty(data, '_response', {
        enumerable: false,
      });
    }
    return data;
  },
  (error) => {
    setLoading(false);
    return Promise.reject(error.response);
  }
);

const getRefreshToken = mem(
  async (): Promise<any> => {
    const cookie = new Cookies();
    const refreshToken = cookie.get('refresh_token', {
      domain: handleDomain(),
      path: '/',
    } as CookieGetOptions);

    if (refreshToken?.length === 0) {
      // Master Password로 로그인 할 경우, refresh token이 존재하지 않기 때문에 access token 만료시 재로그인 필요
      alert(t('Error_Expired', { ns: 'common' }));

      const cookie = new Cookies();
      const cookieOption = {
        path: '/',
        domain: handleDomain(),
      } as CookieSetOptions;

      cookie.remove('access_token', cookieOption);
      cookie.remove('refresh_token', cookieOption);

      window.location.replace(PATH.LOGIN);
      return;
    }

    try {
      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = await api.post<
        any,
        Response<LoginResponseDto>
      >('/refresh', undefined, {
        headers: { Authorization: 'Bearer ' + refreshToken },
        useLoading: true,
      });

      const cookieGetOption = {
        domain: handleDomain(),
        path: '/',
      } as CookieGetOptions;
      const rememberMe = cookie.get('remember_me', cookieGetOption);

      let expires: any = 0;
      if (rememberMe == 'true') {
        const tokenPayload = JSON.parse(atob(newRefreshToken.split('.')[1]));
        expires = new Date(tokenPayload.exp * 1000);
      }

      const cookieSetOption = {
        domain: handleDomain(),
        path: '/',
        expires,
      } as CookieSetOptions;

      cookie.remove('access_token', cookieSetOption);
      cookie.set('access_token', newAccessToken, cookieSetOption);

      cookie.remove('refresh_token', cookieSetOption);
      cookie.set('refresh_token', newRefreshToken, cookieSetOption);

      return newAccessToken;
    } catch (err) {
      // Refresh Token expired, 재로그인 필요
      alert(t('Error_Expired', { ns: 'common' }));

      const cookie = new Cookies();
      const cookieOption = {
        path: '/',
        domain: handleDomain(),
      } as CookieSetOptions;

      cookie.remove('access_token', cookieOption);
      cookie.remove('refresh_token', cookieOption);

      sessionStorage.removeItem(STORAGE_KEYS.refreshToken);
      Object.keys(STORAGE_KEYS).forEach((key) => {
        localStorage.removeItem(key);
      });

      window.location.replace(PATH.LOGIN);
    }
  },
  { maxAge: 1000 }
);

// TODO : return값 통일

apiPrivate.interceptors.request.use(
  (config) => {
    config.headers['x-custom-lang'] = localStorage.getItem(STORAGE_KEYS.i18nextLng) ?? 'en_US';
    config.useLoading && setLoading(true);

    const cookie = new Cookies();
    const accessToken = cookie.get('access_token');

    if (accessToken) {
      config.headers.Authorization = 'Bearer ' + accessToken;
    }

    return config;
  },
  (error) => {
    config.useLoading && setLoading(true);
    return Promise.reject(error.response);
  }
);

apiPrivate.interceptors.response.use(
  (response) => {
    setLoading(false);
    let { data, ...rest } = response;
    if (typeof data === 'object') data._response = rest;
    return data;
  },
  async (error) => {
    setLoading(false);

    const errorCode = error.response?.data?.error.code;
    const isTokenExpired = errorCode === 'client-0001';
    const previousRequest = error.config;

    if (!isTokenExpired || previousRequest.sent) {
      return Promise.reject(error);
    }

    // Token expired logic

    previousRequest.sent = true;

    const newAccessToken = await getRefreshToken();
    if (newAccessToken) {
      previousRequest.headers.Authorization = 'Bearer ' + newAccessToken;
      return apiPrivate(previousRequest);
    }

    return Promise.reject(error);
  }
);

export { api, apiPrivate };
