import { type FC, type ReactNode, useEffect } from 'react';

import useAuth from 'hooks/useAuth';
import { setUserLoginState } from 'store/userInfoStore';

import PATH from '../constant/paths';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const children = props?.children;
  const { isLoggedIn, cookies } = useAuth();

  useEffect(() => {
    if (!cookies?.access_token) {
      localStorage.clear();
      setUserLoginState({ isLoggedIn: false, isMaster: false });
      return;
    }
    setUserLoginState({ isLoggedIn: true, isMaster: cookies?.refresh_token ? false : true });
  }, [cookies?.access_token, cookies?.refresh_token]);

  if (!isLoggedIn) {
    window.location.replace(PATH.MAIN);
    return <></>;
  }

  return <>{children}</>;
};

export default AuthGuard;
