import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export interface GoogleTagMangerArgs {
  tagManagerArgs?: {
    events?: object | undefined;
    auth?: string | undefined;
    preview?: string | undefined;
  };
}

const GTM_ID: { com: string; net: string } = {
  com: 'GTM-WGSX9QJC',
  net: 'GTM-W5L532T8',
};

const GoogleTagManager = ({ tagManagerArgs }: GoogleTagMangerArgs) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;
    const topLevelDomain = window.location.hostname.split('.')[2] as 'com' | 'net';

    if (!GTM_ID[topLevelDomain]) {
      console.error(`Unsupported top level domain: ${topLevelDomain}`);
      return;
    }

    TagManager.initialize({
      gtmId: GTM_ID[topLevelDomain],
      ...tagManagerArgs,
    });
  }, []);

  return null;
};

export default GoogleTagManager;
