import { useQuery } from '@tanstack/react-query';

import ERROR_CODES from 'constant/errorCode';
import { api, ResponsePromise } from 'services/apiService';
import { handleDomainByTld } from 'utils/handleDomainByTld';

export interface GetLeadersStatsResponseDto {
  accountAge: string;
  roi: number;
  sharingRatio: number;
  leverage: number;
  totalProfitRate: number;
  maxDrawdown: number;
  totalNumberTrade: number;
  totalPips: number;
  totalTradingLot: number;
  averageTradingLot: number;
  averageDuration: string;
  profitableTrades: number;
  winRate: number;
  averageDailyTrades: number;
  averageWeeklyTrades: number;
  numberStopOutTrade: number;
  tradingPeriod: string;
  lastTrade: string;
  avatar: string;
  strategy: string;
  numFollowers: number;
  fund: number;
  nickname: string;
  minFundingAmount: number;
  currencyAbbr: string;
  currencySymbol: string;
}

export const getLeadersStats = (leaderCd: number): ResponsePromise<GetLeadersStatsResponseDto> => {
  return api.get(`/st-leaders/${leaderCd}/stats`);
};

export const useLeadersStats = (leaderCd: number, trigger?: boolean) => {
  return useQuery({
    queryKey: ['getLeadersStats', leaderCd],
    queryFn: () => getLeadersStats(leaderCd),
    select: (data) => {
      // TEMP : 서버에서 오는 데이터 중, asset.land-fx.com 블락돼서 asset.landprime.net 으로 변경
      return {
        ...data,
        avatar: data.avatar
          ? data.avatar.replace(
              'https://asset.land-fx.com',
              handleDomainByTld(process.env.REACT_APP_ASSET_DOMAIN as string)
            )
          : null,
      };
    },
    onError: (err: any) => {
      if (err.status === 404 && err.data.error.code === ERROR_CODES.client[404]) {
        window.location.replace('/notfound');
        return;
      }
      console.log(err);
    },
    enabled: trigger,
  });
};
