import { useMutation, useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import styled from 'styled-components';

import { showModal } from 'components/Common/GlobalModal';
import ERROR_CODES from 'constant/errorCode';
import { api, apiPrivate, ResponsePromise } from 'services/apiService';
import { GetCountriesResponseDto } from 'services/react-query/countries';
import { GetLanguagesResponseDto } from 'services/react-query/languages';

interface UpdateMemberMobileRequestDto {
  secureVerificationCd?: number;
  mobileCountry: string;
  mobileRest: string;
  phoneNumber?: string;
}

interface GetMemberTypeLeveragesResponseDto {
  cd: number;
  leverages: string;
  hasUnlimited: boolean;
  example: true;
}

interface GetDemoMemberTypesResponseDto {
  cd: number;
  tradingGroup: string;
  leverages: string;
  currency: string;
  hasUnlimited: boolean;
  isCsOnly: boolean;
  mtSupportVersion: string;
}

export interface GetMemberTypesResponseDto {
  cd: number;
  name: string;
  registration: boolean;
  promotionCd: number;
  minFirstDepositUsd: number;
  mcPreventionCredit: number;
  memberTypeLeverages: GetMemberTypeLeveragesResponseDto;
  demoMemberTypes: GetDemoMemberTypesResponseDto;
  stMemberTypeRegistration: boolean;
}

interface CountryResponseDto extends GetCountriesResponseDto {
  language: GetLanguagesResponseDto;
}

interface FindMainAccountBySecureVerificationCdResponseDto {
  firstName: string;
  lastName: string;
  birth: string;
  mobileCountryCd: string;
  mobileRest: string;
  addressCountry: CountryResponseDto;
  citizenshipCountry: CountryResponseDto;
}

interface MembersAccountNosDto {
  memberCd: number;
  mt4AccountNo: number;
}

interface FindMembersAccountNosResponseDto {
  mt4AccountNos: MembersAccountNosDto[];
}

const putMemberMobile = (data: UpdateMemberMobileRequestDto) => {
  return apiPrivate.put(`/members/mobile`, data);
};

const getMemberTypes = (): ResponsePromise<GetMemberTypesResponseDto[]> => {
  return api.get('/members/types');
};

const getMembersMainAccount = (
  secureVerificationCd: number
): ResponsePromise<FindMainAccountBySecureVerificationCdResponseDto> => {
  return api.get(`/members/main-account/${secureVerificationCd}/email-verification`);
};

//Returns a list of approved member_cd / mt4 account nos.
const getMembersAccountNos = (mt4accountNo: number): ResponsePromise<FindMembersAccountNosResponseDto> => {
  return apiPrivate.get(`members/${mt4accountNo}/account-nos`);
};

export const useUpdateMemberMobile = () => {
  return useMutation({
    mutationFn: (data: UpdateMemberMobileRequestDto) => putMemberMobile(data),
    onSuccess: () => {
      showModal({
        body: <ModalBody>{t('Update_Success', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
    onError: (err) => {
      console.log(err);
      showModal({
        body: <ModalBody>{t('Error_Common_Msg', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useGetMemberTypes = () => {
  return useQuery({
    queryKey: ['getMemberTypes'],
    queryFn: () => getMemberTypes(),
    onError: () => {
      showModal({
        body: <ModalBody>{t('Error_Common_Msg', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useGetMembersMainAccount = (secureVerificationCd: number, trigger?: boolean) => {
  return useQuery({
    queryKey: ['getMembersMainAccount', secureVerificationCd],
    queryFn: () => getMembersMainAccount(secureVerificationCd),
    onError: (err: any) => {
      const errorCode = err.data.error.code;
      const description = err.data.error.description;

      if (errorCode === ERROR_CODES.client[400]) {
        showModal({
          body: <ModalBody>{description}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
      }
    },
    enabled: !!trigger,
  });
};

export const useGetMembersAccountNos = (mt4AccountNo: number, trigger?: boolean) => {
  return useQuery({
    queryKey: ['getMembersAccountNos', mt4AccountNo],
    queryFn: () => getMembersAccountNos(mt4AccountNo),
    onError: (err: any) => {
      const errorCode = err.data.error.code;
      const description = err.data.error.description;

      if (errorCode === ERROR_CODES.client[400]) {
        showModal({
          body: <ModalBody>{description}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
      }
    },
    enabled: !!trigger,
  });
};

const ModalBody = styled.div`
  min-width: 260px !important;
  background-color: white;
`;
