import { Cookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import styled from 'styled-components';
import { CookieSetOptions } from 'universal-cookie/cjs/types';

import { showModal } from 'components/Common/GlobalModal';
import ERROR_CODES from 'constant/errorCode';
import PATH from 'constant/paths';
import STORAGE_KEYS from 'constant/storageKeys';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import SetNewPasswordWithEmailModal from 'pages/LogIn/components/SetNewPasswordWithEmailModal';
import { api, apiPrivate, ResponsePromise } from 'services/apiService';
import { setMt4AccountNos, setSelectedMt4AccountNo } from 'store/selectAccountsStore';
import { initialUserState, setUserInfo, setUserLoginState, useUserLoginStore } from 'store/userInfoStore';
import { handleDomain } from 'utils/handleDomain';

export interface LoginResponseDto {
  mustCreateUser: boolean;
  limitDate: string;
  createUserCode: string; // master password로 로그인할 경우 해당 필드 없음
  email: string;
  accessToken: string; // currentDate > limitDate 인 경우 token 필드 없음
  refreshToken: string; // currentDate > limitDate 인 경우 token 필드 없음, master password로 로그인할 경우 해당 값 empty string
}

export interface LoginRequestDto {
  mt4AccountNo?: number;
  email?: string;
  password: string;
  rememberMe: boolean;
  origin: 'ct';
}

export interface LoginHeaderDto {
  remote_addr: string;
}

const userLogin = (data: LoginRequestDto, headers: LoginHeaderDto): ResponsePromise<LoginResponseDto> => {
  return api.post(
    '/login',
    { ...data },
    {
      headers: {
        remote_addr: headers['remote_addr'],
      },
    }
  );
};

const userLogout = (isMaster: boolean): ResponsePromise<null> | Promise<null> => {
  // The API `st/logout` checks the accessToken, and remove all the authentication data from the DB including refresh token.
  // This behavior results forcing ALL LIVE USERS logging out when a single user trying to log out.
  // For ordinary cases, there's no critical issues induced by this behavior, but when DEVELOPERs are testing with MASTER PASSWORD, it becomes a issue.
  // Thus in case of a user logged in via MASTER PASSWORD, we do not call `st/logout` but do all the other jobs as same as ordinary cases.
  return !isMaster ? apiPrivate.get('/logout') : Promise.resolve(null);
};

export const logoutCommon = () => {
  setMt4AccountNos([]);
  setSelectedMt4AccountNo(undefined);
  setUserInfo({ ...initialUserState });
  setUserLoginState({ isLoggedIn: false });

  localStorage.removeItem(STORAGE_KEYS.loginStorage);
  localStorage.removeItem(STORAGE_KEYS.mt4AccountNosList);
  localStorage.removeItem(STORAGE_KEYS.userInfoStorage);

  const cookie = new Cookies();
  const cookieOption = {
    domain: handleDomain(),
    path: '/',
  } as CookieSetOptions;

  cookie.remove('access_token', cookieOption);
  cookie.remove('refresh_token', cookieOption);
  cookie.remove('remember_me', cookieOption);

  const logout = document.createElement('iframe');
  logout.setAttribute('id', 'mypage-logout');
  logout.setAttribute('src', `${process.env.REACT_APP_MYPAGE_DOMAIN}/logout`);
  logout.setAttribute('hidden', 'hidden');
  logout.onload = function () {
    document.body.removeChild(logout);
  };
  document.body.appendChild(logout);
};

interface UseLoginOptions {
  redirectUrl?: string;
  autoCopy?: {
    leaderCd: number;
  };
}

export const useLogin = (options?: UseLoginOptions) => {
  const navigate = useNavigate();
  const { t, Trans } = useTranslationNamespace('common');

  return useMutation(
    (loginData: { data: LoginRequestDto; headers: LoginHeaderDto }) => userLogin(loginData.data, loginData.headers),
    {
      onMutate: async (loginRequestData) => {
        return loginRequestData;
      },
      onSuccess: (data: LoginResponseDto, loginRequestData) => {
        if (data.refreshToken?.length === 0) {
          setUserLoginState({ isMaster: true });
        } else setUserLoginState({ isMaster: false });
        setUserLoginState({ isLoggedIn: true });
        setUserInfo({ email: data.email });

        const cookie = new Cookies();

        let expires: any = 0;
        if (loginRequestData.data.rememberMe) {
          const tokenPayload = JSON.parse(atob(data.refreshToken.split('.')[1]));
          expires = new Date(tokenPayload.exp * 1000);
        }

        const cookieOption = {
          domain: handleDomain(),
          path: '/',
          expires,
        } as CookieSetOptions;

        cookie.set('access_token', data.accessToken, cookieOption);
        cookie.set('refresh_token', data.refreshToken, cookieOption);
        cookie.set('remember_me', loginRequestData.data.rememberMe, cookieOption);

        if (data.mustCreateUser) {
          showModal({
            body: (
              <SetNewPasswordWithEmailModal
                email={data.email}
                limitDate={data.limitDate}
                createUserCode={data.createUserCode}
                accessToken={data.accessToken}
              />
            ),
            isDefaultBtn: false,
            closeOnBackdropClick: false,
            hasCloseIcon: false,
            disableEscapeKeyDown: true,
          });
        } else {
          cookie.get('access_token') && navigate(options?.redirectUrl ?? PATH.MY.ACCOUNT, { state: options });
        }
      },
      onError: (err: any) => {
        const errorStatus = err.status;
        const errorDataCode = err.data.error.code;
        const errorDescription = err.data.error.description[0];

        if (errorStatus === 404 && errorDataCode === ERROR_CODES.login.USER_NO_CT_ACCOUNT.code) {
          showModal({
            body: (
              <ModalBody>
                <Trans i18nKey={ERROR_CODES.login.USER_NO_CT_ACCOUNT.code} ns="common" />
              </ModalBody>
            ),
            isDefaultBtn: true,
            cancelBtn: false,
            confirmText: t('create_account', { ns: 'common' }) as string,
            onConfirm: () => navigate(PATH.REGISTER),
          });
          return;
        }

        if (errorStatus === 401) {
          if (errorDataCode === ERROR_CODES.client[401]) {
            showModal({
              body: <ModalBody>{t('Error_Auth_Not_Matching', { ns: 'common' })}</ModalBody>,
              isDefaultBtn: true,
              cancelBtn: false,
            });
            return;
          }
          if (errorDataCode === ERROR_CODES.login.USER_MUST_USE_EMAIL.code) {
            showModal({
              body: <ModalBody>{t('user-login-0002', { ns: 'common' })}</ModalBody>,
              isDefaultBtn: true,
              cancelBtn: false,
            });
            return;
          }
        }

        if (errorStatus === 403 && errorDataCode === ERROR_CODES.login.ACCOUNT_DEACTIVATED.code) {
          showModal({
            body: <ModalBody>{<Trans i18nKey={'user-login-0009'} ns="common" />}</ModalBody>,
            isDefaultBtn: true,
            cancelBtn: false,
          });
          return;
        }

        if (errorStatus === 404 && errorDataCode === ERROR_CODES.client[404]) {
          showModal({
            body: <ModalBody>{t('Error_Auth_Not_Exist_Account', { ns: 'common' })}</ModalBody>,
            isDefaultBtn: true,
            cancelBtn: false,
            modalWrapStyle: {
              width: '474px',
            },
          });
          return;
        }

        showModal({
          body: <ModalBody>{errorDescription}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
      },
    }
  );
};

export const useLogout = () => {
  const navigate = useNavigate();
  const { isMaster } = useUserLoginStore();
  const { mutate } = useMutation({
    mutationFn: () => userLogout(isMaster!),
    onSuccess: () => {
      navigate(PATH.LOGOUT);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });
  return { logout: mutate };
};

const ModalBody = styled.div`
  min-width: 260px !important;
  margin-top: 10px;
  background-color: white;
`;
