import { createGlobalStyle } from 'styled-components';

const Global = createGlobalStyle`

html {
  box-sizing: border-box;
  min-width: 360px;
  font-family: Roboto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
button,
input,
pre {
  margin: 0;
  padding: 0;
}

body{
    #root {
      min-width: 360px;
      min-height: 337px;
      overflow-x: hidden;
    }
}

ol,
ul {
  list-style: none;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {  
  -webkit-appearance: button;
}

button {
  color: inherit;
  font-family: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

input {
  border: none;
}

input:focus {
  outline:none; // for prevent chrome yellow border when focus
}

input[type='radio'] {
  -webkit-appearance: radio;
}

textarea{
  font-family: 'Roboto';
}

`;

export default Global;
