import { useState } from 'react';

import styled, { css } from 'styled-components';

import { ReactComponent as DropdownIcon } from 'assets/icon/dropdown.svg';

interface LabeledSelectProps {
  label: string;
  items: [string, number | string][];
  defaultValue?: string;
  placeholder?: string;
  onChange: (value: number | string) => void;
}

function LabeledSelect({ label, items, defaultValue, placeholder = 'Select', onChange }: LabeledSelectProps) {
  const [isSpread, setSpread] = useState(false);
  const [selectedItem, setSelectedItem] = useState(defaultValue ?? '');

  const handleItemClick = (text: string, value: number | string) => {
    onChange(value);
    setSelectedItem(text);
  };

  return (
    <Wrapper>
      <Trigger onClick={() => setSpread(true)} isSpread={isSpread}>
        <Label>{label}</Label>
        <SelectedItem $isSelect={!!selectedItem}>{selectedItem || placeholder}</SelectedItem>
        <Icon>
          <DropdownIcon />
        </Icon>
      </Trigger>
      {isSpread && (
        <>
          <Dimmed onClick={() => setSpread(false)} />
          <Dropdown onClick={() => setSpread(false)}>
            {items.map((item) => (
              <DropdownItem key={item[0]} onClick={() => handleItemClick(item[0], item[1])}>
                {item[0]}
              </DropdownItem>
            ))}
          </Dropdown>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  text-align: left;
  user-select: none;
`;

const Trigger = styled.div<{ isSpread: boolean }>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.line.my};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  ${({ isSpread }) =>
    isSpread &&
    css`
      border: 1px solid ${({ theme }) => theme.button.primary};

      ${Label} {
        color: ${({ theme }) => theme.button.primary};
      }

      ${Icon} {
        transform: translateY(-50%) rotate(180deg);
      }
    `}
`;

const Label = styled.label`
  position: absolute;
  top: 10px;
  left: 16px;
  color: ${({ theme }) => theme.font.quaternary};
  font-size: 12px;
  line-height: 14px;
`;

const SelectedItem = styled.div<{ $isSelect: boolean }>`
  padding: 30px 16px 10px;

  ${({ $isSelect }) =>
    $isSelect
      ? css`
          font-size: 17px;
        `
      : css`
          color: ${({ theme }) => theme.font.placeholder};
        `}
`;

const Icon = styled.div`
  height: 24px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);

  path {
    fill: ${({ theme }) => theme.color.iconMy};
  }
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.line.my};
  border-radius: 10px;
  overflow: auto;
  z-index: 10;
  cursor: pointer;
`;

const DropdownItem = styled.li`
  padding: 10px 16px;

  &:hover {
    background-color: #eaf1fb;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.line.my};
  }
`;

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export default LabeledSelect;
