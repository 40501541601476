// save promotion code on session storage
// if url has 'promotionCode' query string
import STORAGE_KEYS from 'constant/storageKeys';

export default function savePromotionCode(queryString: string, holdingTime: number = 30) {
  const expirationTime = new Date().getTime() + holdingTime * 60 * 1000; // current time + 30 mins
  const promotionCodeData = {
    promotionCode: queryString,
    expirationTime,
  };
  sessionStorage.setItem(STORAGE_KEYS.dubaiPromotionCode, JSON.stringify(promotionCodeData));
}
