const ERROR_CODES = {
  client: {
    400: 'client-0000',
    401: 'client-0001',
    403: 'client-0003',
    404: 'client-0004',
  },
  backend: {},
  followers: {
    401: 'follower-0001',
    403: 'follower-0003',
    404: 'follower-0004',
  },
  email: {
    402: 'email-0002',
    403: 'email-0003',
    404: 'email-0004',
  },
  user: {
    404: 'user-0007',
  },
  login: {
    USER_MUST_WAIT: {
      code: 'user-login-0001',
      description: 'user must wait for a while, and then try again',
    },
    HAS_TOO_MANY_ATTEMPT: {
      code: 'user-login-0002',
      description: 'user is attempting too many logins',
    },
    MISSING_REMOTE_ADDR: {
      code: 'user-login-0003',
      description: 'internal server error, please escalate this to Land Prime development team',
    },
    WRONG_CREDENTIALS: {
      code: 'user-login-0004',
      description: 'wrong credentials. please check id or password',
    },
    CREDENTIALS_NOT_PROVIDED: {
      code: 'user-login-0005',
      description: 'provide mt4AccountNo or email address',
    },
    NO_ACCOUNTS_FOUND: {
      code: 'user-login-0006',
      description: 'Account(s) not found for the required service.',
    },
    USER_MUST_USE_EMAIL: {
      code: 'user-login-0007',
      description: 'user must login using their email.',
    },
    USER_NO_CT_ACCOUNT: {
      code: 'user-login-0008',
      description: 'You do not have a valid CT account yet, please register using your current Email address.',
    },
    ACCOUNT_DEACTIVATED: {
      code: 'user-login-0009',
      description: 'Account has been disabled.',
    },
  },
  registration: {
    COMMON: {
      NOT_FOUND_REFERRAL_IB: {
        code: 'registration-common-0001',
        description: 'Cannot find any ib information with the given referral code',
      },
      INVALID_REFERRAL_IB: {
        code: 'registration-common-0002',
        description: "We cannot accept not existing code or master ib's code",
      },
      SIMPLE_IB_REFERRAL: {
        code: 'registration-common-0003',
        description: "We cannot accept simple account's referral",
      },
      SELF_REFERRAL: {
        code: 'registration-common-0004',
        description: 'We cannot accept self-referral',
      },
      ANOTHER_WAIT_ACCOUNT: {
        // email resend 필요
        code: 'registration-common-0005',
        description: 'Another registration request under this email is on wait status',
      },
      MAIN_ACCOUNT_LEGAL_DOCUMENT_MISSING: {
        // email resend 필요
        code: 'registration-common-0006',
        description: 'The Main account under this email has not enough legal documents',
      },
      FIRST_NAME_NOT_MATCH_TO_MAIN_ACCOUNT: {
        code: 'registration-common-0007',
        description: 'The given FirstName is not matched to the main account information Under the Email',
      },
      LAST_NAME_NOT_MATCH_TO_MAIN_ACCOUNT: {
        code: 'registration-common-0008',
        description: 'The given LastName is not matched to the main account information Under the Email',
      },
      BIRTH_NOT_MATCH_TO_MAIN_ACCOUNT: {
        code: 'registration-common-0009',
        description: 'The given birth is not matched to the main account information Under the Email',
      },
      CITIZENSHIP_NOT_MATCH_TO_MAIN_ACCOUNT: {
        code: 'registration-common-0010',
        description: 'The given Citizenship is not matched to the main account information Under the Email',
      },
      WRONG_REGISTRATION_ENTRY: {
        // email resend 필요 or customer service 연결 필요
        code: 'registration-common-0011',
        description: 'This request goes to wrong entry, please contact this issue to Land-fx service team',
      },
      ANOTHER_ACCOUNT_WITHOUT_KYC: {
        code: 'registration-common-0012',
        // email resend 필요
        description: 'Cannot create another account if the previous account is not updated by POA/PLE',
      },
      ALREADY_IN_USED_EMAIL: {
        code: 'registration-common-0019',
        description: 'email is already in use, please log in your previous account',
      },
      ALREADY_REGISTERED_EMAIL: {
        code: 'registration-common-0020',
        description: 'email is already registered, please try to log in with the given email',
      },
      SIMPLE_MAIN_ACCOUNT: {
        code: 'registration-common-0022',
        description: 'The main account under this email is a simple account',
      },
    },
    ST_FOLLOWER: {
      FAIL_MEMBER_CREATION: {
        code: 'registration-st-follower-0001',
        description: 'Failed to create a member for st follower',
      },
      FAIL_IB_MEMBER_CREATION: {
        code: 'registration-st-follower-0002',
        description: 'Failed to create an Ib member for st follower',
      },
      MEMBER_TYPE_INVALID: {
        code: 'registration-st-follower-0003',
        description: "The given member type is invalid to St Follower's registration",
      },
    },
    ST_LEADER: {
      FAIL_MEMBER_CREATION: {
        code: 'registration-st-leader-0001',
        description: 'Failed to create a member for st leader',
      },
      FAIL_IB_MEMBER_CREATION: {
        code: 'registration-st-leader-0002',
        description: 'Failed to create an Ib member for st leader',
      },
      MEMBER_TYPE_INVALID: {
        code: 'registration-st-leader-0003',
        description: "The given member type is invalid to St Leader's registration",
      },
    },
  },
};

export default ERROR_CODES;
