import { useMutation } from '@tanstack/react-query';

import { getExchangeCurrency } from './exchange';
import { getLeadersStats } from './leadersStats';

const getExchangedLeaderStats = async (leaderCd: number, currencyAbbr: string) => {
  const stats = await getLeadersStats(leaderCd);
  const amount = await getExchangeCurrency({
    amount: stats.minFundingAmount,
    src: stats.currencyAbbr,
    dest: currencyAbbr,
  });
  return { ...stats, exchangedMinFundingAmount: amount.amount };
};

export const useExchangedLeaderStats = (currencyAbbr: string) => {
  return useMutation({
    mutationFn: (leaderCd: number) => getExchangedLeaderStats(leaderCd, currencyAbbr),
  });
};
