import * as Yup from 'yup';

export const PASSWORD_REGEXP = {
  numberRequired: /(?=.*\d)/,
  lengthRequired: /^.{8,15}$/,
  uppercaseAndLowercaseRequired: /(?=.*[a-z])(?=.*[A-Z])/,
  specialCharactersRequired: /(?=.*[!@#$%^*+=-])/,
};

export const EMAIL_REGEXP = /^[a-zA-Z0-9+-\_.]{1,64}@[a-zA-Z0-9+-\_.]+\.[a-zA-Z]+$/;

export const YUP_SCHEMA = (t: any) => ({
  stringRequired: Yup.string().required(t('Validation_Common_Required', { ns: 'common' })),
  numberRequired: Yup.number().required(t('Validation_Common_Required', { ns: 'common' })),
  nicknameLength: Yup.string().max(60, t('Validation_Nickname_Length', { ns: 'common' })),
  maxFollowers: Yup.number().max(1000, t('Validation_Max_Followers', { ns: 'common' })),
  strategyRequired: Yup.string().required(t('Validation_Strategy_Required', { ns: 'common' })),
  email: Yup.string()
    .required(t('Validation_Email_Required', { ns: 'common' }))
    .matches(EMAIL_REGEXP, {
      message: t('Validation_Email_Invalid', { ns: 'common' }),
    })
    .max(320, { message: t('Validation_Email_Max_Length', { ns: 'common' }) }),
  mt4AccountNo: Yup.string()
    .required(t('Validation_Account_Required', { ns: 'common' }))
    .matches(/^[0-9]+$/, { message: t('Validation_Account_Only_Number', { ns: 'common' }) }),
  password: Yup.string()
    .required()
    .min(8)
    .max(15)
    .matches(PASSWORD_REGEXP.uppercaseAndLowercaseRequired)
    .matches(PASSWORD_REGEXP.specialCharactersRequired)
    .matches(PASSWORD_REGEXP.numberRequired),
  twoFactorAuthentication: Yup.object().shape({
    type: Yup.string().required(),
    secureVerificationCd: Yup.string().required(t('Validation_Two_Factor_Auth_Not_Verified', { ns: 'common' })),
    isValidated: Yup.boolean()
      .oneOf([true])
      .required(t('Validation_Two_Factor_Auth_Not_Validated', { ns: 'common' })),
  }),
  nameSchema: Yup.string()
    .matches(/^[A-Z _,.\-\s]+$/iu, {
      message: t('Validation_Name_Invalid', { ns: 'common' }),
    })
    .required(),
  addressSchema: Yup.string()
    .matches(/^[a-zA-Z0-9-\s]*$/, { message: t('Validation_Address_Validation', { ns: 'common' }) })
    .required(t('Validation_Address_Required', { ns: 'common' })),
});
