import { ComponentProps, ReactNode, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import styled from 'styled-components';

import { ReactComponent as GlobalIcon } from 'assets/icon/global.svg';
import { ReactComponent as DownloadIcon } from 'assets/icon/icon_download.svg';
import { ReactComponent as HamburgerMenu } from 'assets/icon/menu.svg';
import { ReactComponent as Logo } from 'assets/logo/logo_prime_color.svg';
import Button from 'components/Common/Button';
import LanguageSelectBox from 'components/Common/LanguageSelectBox';
import LanguageSelectDrawer from 'components/Common/LanguageSelectDrawer';
import { LinedLinkText } from 'components/Common/LinedLinkText';
import { LinkText } from 'components/Common/LinkText';
import SideDrawer from 'components/Common/SideDrawer';
import UnverifiedBanner from 'components/Common/UnVerifiedBanner';
import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useToggleState from 'hooks/useToggleState';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { useLogout } from 'services/react-query/auth';
import { useUserInfoStore, useUserLoginStore } from 'store/userInfoStore';
import defaultTheme from 'styles/theme';
import { isHeaderTransparent } from 'utils/pathCheckers';

import DownloadSelectBox from './DownloadSelectBox';

export default function Header() {
  const { pathname } = useLocation();
  const { t } = useTranslationNamespace('common');

  const navigate = useNavigate();
  const { role, name, isSimple, isSMSVerified, isPOAVerified, isPLEVerified } = useUserInfoStore();
  const { isLoggedIn } = useUserLoginStore();

  const { toggleState: openDrawer, handleToggleState: toggleDrawer } = useToggleState();
  const { toggleState: openLanguageDrawer, handleToggleState: toggleLanguageDrawer } = useToggleState();
  const { toggleState: openLanguageBox, handleToggleState: toggleLanguageBox } = useToggleState();
  const { toggleState: openDownloadBox, handleToggleState: toggleDownloadBox } = useToggleState();

  const HeaderBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.HEADER_BREAK_POINT})` });
  const HeaderLeaderBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.HEADER_LEADER_BREAK_POINT})` });

  const { logout } = useLogout();

  const [isTop, setIsTop] = useState<boolean>(window.scrollY === 0 && isHeaderTransparent(pathname));

  useEffect(() => {
    setIsTop(window.scrollY === 0 && isHeaderTransparent(pathname));
    const handleScrollEvent = () => {
      setIsTop(window.scrollY === 0 && isHeaderTransparent(pathname));
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [pathname]);

  return (
    <>
      <HeaderWrapper isHeaderBreak={HeaderBreakPoint} pathname={pathname} isTop={isTop}>
        {HeaderBreakPoint ? (
          <MobileHeaderWrapper>
            <div className="left-side">
              <button className="menu-btn" onClick={() => toggleDrawer()}>
                <HamburgerMenu fill={isTop ? 'black' : 'white'} />
              </button>
              <SideDrawer open={openDrawer} onClose={() => toggleDrawer()} />
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <Logo width="126.4" height="24" />
              </Link>
            </div>
            <button onClick={() => toggleLanguageDrawer()}>
              <GlobalIcon
                width={22}
                height={22}
                color={isTop ? defaultTheme.color.headerBlack : defaultTheme.color.topInfo}
              />
            </button>
            <LanguageSelectDrawer open={openLanguageDrawer} onClose={() => toggleLanguageDrawer()} />
          </MobileHeaderWrapper>
        ) : (
          <>
            <div className="left-side">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <Logo height="30" />
              </Link>
            </div>
            {!pathname.includes(PATH.PROMOTION) && (
              <div className="center">
                {!isLoggedIn && (
                  <LinedLink to="/register" $isActive={pathname === PATH.REGISTER} isTop={isTop}>
                    {t('CT_head2')}
                  </LinedLink>
                )}
                {!(isLoggedIn && role === 'Leader' && HeaderLeaderBreakPoint) && (
                  <>
                    <LinedLink to="/leader-board" $isActive={pathname === PATH.LEADER_BOARD} isTop={isTop}>
                      {t('CT_head3')}
                    </LinedLink>
                    <LinedLink to="/faq" $isActive={pathname === PATH.FAQ} isTop={isTop}>
                      {t('CT_head4')}
                    </LinedLink>
                  </>
                )}
                {isLoggedIn && (
                  <>
                    {!(role === 'Leader' && HeaderLeaderBreakPoint) && <div className="divider" />}
                    <LinedLink to="/my/account" $isActive={pathname === PATH.MY.ACCOUNT} isTop={isTop}>
                      {t('CT_head5')}
                    </LinedLink>
                    <LinedLink to="/my/trading" $isActive={pathname === PATH.MY.TRADING} isTop={isTop}>
                      {t('CT_head6')}
                    </LinedLink>
                    <LinedLink to="/my/setting" $isActive={pathname === PATH.MY.SETTING} isTop={isTop}>
                      {t('CT_head7')}
                    </LinedLink>
                    {role === 'Leader' && (
                      <>
                        <LinedLink to="/my/followers" $isActive={pathname === PATH.MY.FOLLOWERS} isTop={isTop}>
                          {t('CT_head8')}
                        </LinedLink>
                        <LinedLink to="/my/invite" $isActive={pathname === PATH.MY.INVITE} isTop={isTop}>
                          {t('CT_head9')}
                        </LinedLink>
                      </>
                    )}
                    <LinedLink to="/my/fund" $isActive={pathname === PATH.MY.FUND} isTop={isTop}>
                      {t('CT_head10')}
                    </LinedLink>
                  </>
                )}
              </div>
            )}
            <div className="right-side">
              {role && (
                <UserInfoBox>
                  <div className="role">{role.toUpperCase()}</div>
                  <div className="name">{name}</div>
                </UserInfoBox>
              )}
              <Button
                variant="outlined"
                onClick={() => {
                  if (pathname === PATH.REGISTER) {
                    window.location.replace(PATH.REGISTER);
                  } else {
                    navigate(PATH.REGISTER);
                  }
                }}
                style={{
                  width: '82px',
                  height: '31px',
                  color: isTop ? defaultTheme.color.headerBlack : defaultTheme.color.white,
                  fontSize: '13px',
                }}
              >
                {t('CT_head2')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  isLoggedIn ? logout() : navigate(PATH.LOGIN);
                }}
                style={{
                  width: '82px',
                  height: '31px',
                  color: isTop ? defaultTheme.color.headerBlack : defaultTheme.color.white,
                  fontSize: '13px',
                }}
              >
                {isLoggedIn ? t('Common_Logout') : t('Common_Login')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => toggleLanguageBox()}
                style={{
                  width: '20px',
                  height: '32px',
                  border: 'none !important',
                }}
              >
                <GlobalIcon
                  width={20}
                  height={20}
                  color={isTop ? defaultTheme.color.headerBlack : defaultTheme.color.topInfo}
                />
              </Button>
              {openLanguageBox && (
                <>
                  <Backdrop
                    sx={{ zIndex: 10, bgcolor: 'transparent' }}
                    open={openLanguageBox}
                    onClick={toggleLanguageBox}
                  />
                  <LanguageSelectBox onClose={toggleLanguageBox} />
                </>
              )}
              <Button
                variant="outlined"
                onClick={() => toggleDownloadBox()}
                style={{
                  width: '20px',
                  height: '32px',
                }}
              >
                <DownloadIcon
                  width={20}
                  height={20}
                  color={isTop ? defaultTheme.color.headerBlack : defaultTheme.color.topInfo}
                />
              </Button>
              {openDownloadBox && (
                <>
                  <Backdrop
                    sx={{ zIndex: 10, bgcolor: 'transparent' }}
                    open={openDownloadBox}
                    onClick={toggleDownloadBox}
                  />
                  <DownloadSelectBox onClose={toggleDownloadBox} />
                </>
              )}
            </div>
          </>
        )}
      </HeaderWrapper>
      {isLoggedIn && isSimple === 1 && /^\/my/.test(pathname) && (
        <UnverifiedBanner isSMSVerified={isSMSVerified} isPLEVerified={isPLEVerified} isPOAVerified={isPOAVerified} />
      )}
    </>
  );
}

const HeaderWrapper = styled.header<{
  isHeaderBreak: boolean;
  pathname: string;
  isTop: boolean;
}>`
  width: 100%;
  height: 70px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, isTop }) => (isTop ? 'transparent' : theme.button.secondary)};
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.white};
  padding: ${({ isHeaderBreak }) => (isHeaderBreak ? '0 17px' : '0 30px')};
  transition: background-color 0.5s ease 0s;
  border-bottom: 1px solid
    ${({ isTop, pathname }) => (isTop && !pathname.includes('promotion') ? '#FFFFFF4D' : 'transparent')};

  .menu-btn {
    background-color: transparent;
    margin-right: 10px;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      display: flex;
      align-items: center;
    }
  }

  .center {
    display: ${({ isHeaderBreak }) => (isHeaderBreak ? 'none' : 'flex')};
    gap: 0 20px;
    position: absolute;
    left: 317px;
    height: 100%;
    align-items: center;
  }

  .right-side {
    width: 315px;
    display: flex;
    justify-content: right;
    position: relative;
    gap: 10px;

    button:first-child,
    button:nth-child(2) {
      border: 1px solid ${({ theme, isTop }) => (isTop ? theme.color.headerBlack : theme.color.white)} !important;
      color: ${({ theme, isTop }) => (isTop ? theme.color.headerBlack : theme.color.white)} !important;
    }
  }

  .selected-lang {
    margin-left: 4px;
    text-transform: uppercase;
  }

  .divider {
    width: 1px;
    height: 14px;
    background-color: rgba(255, 255, 255, 0.3);
    &.logo {
      height: 24px;
    }
  }

  .role {
    color: ${({ theme, isTop }) => (isTop ? theme.color.headerBlack : theme.color.sub1)};
  }

  .name {
    color: ${({ theme, isTop }) => (isTop ? theme.color.headerBlack : theme.color.white)} !important;
  }
`;

const MobileHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 14px;
  margin-right: 10px;
  max-width: 75px;

  > div:first-child {
    font-weight: 600;
  }

  > div:last-child {
    color: ${({ theme }) => theme.color.white};
    font-size: 13px;
    line-height: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const LinedLink = ({
  children,
  isTop,
  ...props
}: ComponentProps<typeof LinkText> & { children: ReactNode; isTop?: boolean }) => {
  return (
    <LinedLinkText isTop={isTop}>
      <LinkText {...props}>{children}</LinkText>
    </LinedLinkText>
  );
};
