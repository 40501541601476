import styled from 'styled-components';

import { ReactComponent as Mt4Download } from 'assets/icon/mt4_download.svg';
import { ReactComponent as Mt4DownloadM } from 'assets/icon/mt4_download_m.svg';
import { ReactComponent as Mt5Download } from 'assets/icon/mt5_download.svg';
import { ReactComponent as Mt5DownloadM } from 'assets/icon/mt5_download_m.svg';
import checkMobileOs from 'utils/checkMobileOs';

interface Props {
  isMobile?: boolean;
}

const PlatformDownloadButtons = ({ isMobile = false }: Props) => {
  const deviceOs = checkMobileOs();

  const webMt4Link =
    deviceOs === 'browser'
      ? 'https://download.mql5.com/cdn/web/land.prime.ltd/mt4/landprime4setup.exe'
      : deviceOs === 'android'
      ? ' https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en&referrer=ref_id%3d5014276646422293650%26hl%3den'
      : 'https://apps.apple.com/us/app/metatrader-4/id496212596';

  const webMt5Link =
    deviceOs === 'browser'
      ? 'https://download.mql5.com/cdn/web/land.prime.ltd/mt5/landprime5setup.exe'
      : deviceOs === 'android'
      ? 'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5'
      : 'https://apps.apple.com/us/app/metatrader-5-forex-stocks/id413251709';

  return (
    <DownloadButtonsWrapper isMobile={isMobile}>
      <a href={webMt4Link}>{isMobile ? <Mt4DownloadM /> : <Mt4Download />}</a>
      <a href={webMt5Link}>{isMobile ? <Mt5DownloadM /> : <Mt5Download />}</a>
    </DownloadButtonsWrapper>
  );
};

const DownloadButtonsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: 340px;
  height: 60px;

  ${({ isMobile }) => isMobile && `marginTop : 20px`};

  a {
    flex: 1;
  }
`;

export default PlatformDownloadButtons;
