import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';

import loginImg from 'assets/img/login_register_bg.png';
import { showModal } from 'components/Common/GlobalModal';
import MetaTag from 'components/Common/MetaTag';
import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { RoleType as AccountType } from 'services/common';
import { useGetUserIp } from 'services/react-query/countries';
import { useGetMemberTypes } from 'services/react-query/members';
import { useUserLoginStore } from 'store/userInfoStore';
import * as MyPage from 'styles/mypage.wrapper.styled';
import * as S from 'styles/register.and.login.styled';

import PlatformDownloadButtons from './components/PlatformDownloadButtons';
import Step1 from './components/step1';
import Step2 from './components/step2';

export default function Register() {
  const isBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.BREAK_POINT})` });
  const { state } = useLocation();
  const { isLoggedIn } = useUserLoginStore();
  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [accountType, setAccountType] = useState<AccountType>(
    (state as { accountType: AccountType })?.accountType ?? 'Follower'
  );

  const { data: membersType } = useGetMemberTypes();
  const { data: userIp, isError: isErrorFetchIp } = useGetUserIp();

  const { t, Trans } = useTranslationNamespace();

  const handleAccountType = (e: any) => setAccountType(e.target.value);

  const handleNextStep = () => setStep((p) => ++p);
  const handlePrevStep = () => setStep((p) => --p);

  useEffect(() => {
    if (isLoggedIn) {
      return navigate(PATH.MY.ACCOUNT);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isErrorFetchIp) return;
    showModal({
      body: <Trans i18nKey="Error_Common_Msg" ns="common" />,
      isDefaultBtn: true,
      cancelBtn: false,
    });
  }, [isErrorFetchIp]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY as string}>
      <MetaTag
        title="Register"
        description="A Copy Trading account that anyone can easily use can be opened in 1 minute."
      />
      <MyPage.Wrapper>
        {isBreakPoint && step !== 2 && (
          <>
            <S.TextBox isCenter={true}>
              <h1>{t('Register_Title')}</h1>
              <p>{t('Register_Title_Desc')}</p>
              <PlatformDownloadButtons isMobile={true} />
            </S.TextBox>
          </>
        )}
        <S.Wrapper>
          <RegisterFormWrapper step={step}>
            {step === 1 && (
              <Step1 handleNextStep={handleNextStep} accountType={accountType} handleAccountType={handleAccountType} />
            )}
            {step === 2 && membersType && userIp && (
              <Step2
                handlePrevStep={handlePrevStep}
                handleNextStep={handleNextStep}
                accountType={accountType}
                memberTypes={membersType}
                ip={userIp}
              />
            )}
          </RegisterFormWrapper>
        </S.Wrapper>
      </MyPage.Wrapper>
    </GoogleReCaptchaProvider>
  );
}

const RegisterFormWrapper = styled.div<{ step?: number }>`
  display: flex;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.step === 2
      ? `
          padding: 0px;
          width: 424px;
        `
      : `
          width: 50%;
        `}

  ${({ step }) =>
    step !== 2 &&
    `
      @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
        justify-content: center;
        width: 100%;
        height: fit-content;
        padding: 0;
        min-height: auto;
      }
  `}
`;

export const LoginImageBox = styled(S.ImageBoxWrapper)<{ step?: number }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: no-repeat url(${loginImg});
  background-size: 640px;
  background-position: right bottom;
  background-color: #d1dfed;
  justify-content: flex-start;
  align-items: flex-end;

  ${({ step }) =>
    step === 2 &&
    css`
      display: none;
    `};
`;
