import styled from 'styled-components';

import useTranslationNamespace from 'hooks/useTranslationNamespace';
import checkMobileOs from 'utils/checkMobileOs';

interface DownloadSelectBoxProps {
  onClose: () => void;
}

export default function DownloadSelectBox({ onClose }: DownloadSelectBoxProps) {
  const { t } = useTranslationNamespace('common');
  const deviceOs = checkMobileOs();
  const webMt4Link =
    deviceOs === 'browser'
      ? 'https://download.mql5.com/cdn/web/land.prime.ltd/mt4/landprime4setup.exe'
      : deviceOs === 'android'
      ? ' https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en&referrer=ref_id%3d5014276646422293650%26hl%3den'
      : 'https://apps.apple.com/us/app/metatrader-4/id496212596';

  const webMt5Link =
    deviceOs === 'browser'
      ? 'https://download.mql5.com/cdn/web/land.prime.ltd/mt5/landprime5setup.exe'
      : deviceOs === 'android'
      ? 'https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5'
      : 'https://apps.apple.com/us/app/metatrader-5-forex-stocks/id413251709';
  return (
    <DropdownWrapper onClick={onClose}>
      <StyledLink href={webMt4Link}>{t('CT_head11_mt4')}</StyledLink>
      <StyledLink href={webMt5Link}>{t('CT_head11_mt5')}</StyledLink>
    </DropdownWrapper>
  );
}

const StyledLink = styled.a`
  font-weight: 400;
  font-size: 15px;
  line-height: 1.88;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(165, 175, 196, 0.2);
  width: max-content;
  cursor: pointer;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: -30px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 15px;
  background-color: ${({ theme }) => theme.button.secondary};
  color: ${({ theme }) => theme.color.white};
  z-index: 1000;

  animation: growDown 100ms ease-in-out forwards;
  transform-origin: top center;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;
