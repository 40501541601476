import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import styled, { css } from 'styled-components';

import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';

function NeedVerificationModal() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslationNamespace('common');
  const HeaderBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.HEADER_BREAK_POINT})` });

  const handleButtonClick = () => {
    if (PATH.MY.SETTING === location.pathname) return navigate(0);
    navigate(`${PATH.MY.SETTING}#mobile-number`);
  };

  return (
    <Wrapper isHeaderBreak={HeaderBreakPoint}>
      {t('need_verification_message')}
      <ModalButton onClick={handleButtonClick}>{t('Common_Confirm')}</ModalButton>
    </Wrapper>
  );
}

export default NeedVerificationModal;

const Wrapper = styled.div<{ isHeaderBreak: boolean }>`
  padding: 10px 20px 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: ${({ theme }) => theme.font.primary};
  line-height: 24px;

  ${({ isHeaderBreak }) =>
    isHeaderBreak &&
    css`
      padding: 0;
      width: 300px;
    `}
`;

const ModalButton = styled.button`
  padding: 15px 0;
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;
