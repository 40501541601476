import styled from 'styled-components';

import { ReactComponent as MaintenanceIcon } from 'assets/img/maintenance.svg';
import { FROM, TO } from 'constant/maintenance';
import GlobalStyle from 'styles/global';

export default function Maintenance() {
  return (
    <Wrapper>
      <GlobalStyle />
      <InnerWrapper>
        <h1 className="title">Server Maintenance</h1>
        <p className="title-desc">
          {FROM} to {TO}, based on server time (GMT).
        </p>
        <ImageWrapper>
          <MaintenanceIcon width={460} height={360} />
        </ImageWrapper>
        <p className="desc">
          If you have any questions or need assistance,
          <br />
          please contact our support team at <a href="mailto:account@landprime.com">account@landprime.com</a>
        </p>
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #bdd7ef;
`;

const InnerWrapper = styled.div`
  margin: 12px;

  .title {
    color: #2452c2;
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }

  .title-desc {
    margin-top: 16px;
    color: #2651ac;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .desc {
    color: #445c8f;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    a {
      text-decoration: underline;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  svg {
    max-width: 100%;
  }
`;
