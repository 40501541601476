const PATH = {
  MAIN: '/',
  REGISTER: '/register',
  LOGIN: '/login',
  RESET_PASSWORD: '/login/reset_password',
  USER_RESET_PASSWORD: '/login/user_reset_password',
  LEADER_BOARD: `/leader-board`,
  LEADER_DETAIL: `/leader-board/detail`,
  FAQ: '/faq',
  PROMOTION: '/promotion',
  LOGOUT: '/logout',
  MY: {
    ACCOUNT: '/my/account',
    TRADING: '/my/trading',
    SETTING: '/my/setting',
    FOLLOWERS: '/my/followers',
    INVITE: '/my/invite',
    FUND: '/my/fund',
  },
};

export default PATH;
