import { MouseEvent, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import Button from 'components/Common/Button';
import { showModal } from 'components/Common/GlobalModal';
import PATH from 'constant/paths';
import { useExchangedLeaderStats } from 'services/react-query/copy';
import { useFollowersLeaders } from 'services/react-query/followers';
import { Mt4AccountNosSelectListStore } from 'store/selectAccountsStore';
import { useUserInfoStore } from 'store/userInfoStore';
import defaultTheme from 'styles/theme';
import { formatNumber } from 'utils/format';

import useTranslationNamespace from './useTranslationNamespace';

interface UseCopyParams {
  autoCopy?: boolean;
}

export const useCopy = ({ autoCopy = true }: UseCopyParams) => {
  const { role, balance, isSimple, currencyAbbr, currencySymbol, mt4AccountNo } = useUserInfoStore();
  const { selectedMt4AccountNo } = Mt4AccountNosSelectListStore();
  const { t, Trans } = useTranslationNamespace('leader-board');
  const location = useLocation();

  const { mutateAsync: prepare } = useExchangedLeaderStats(currencyAbbr);
  const { mutate: copy } = useFollowersLeaders(selectedMt4AccountNo?.followerCd ?? NaN);
  const navigate = useNavigate();

  const copyModal = useCallback(
    (leaderCd: number, leaderName: string, amount?: number) => ({
      body: (
        <ModalBody>
          <div>
            <Trans
              ns="leader-board"
              i18nKey="Copy_Modal_Confirm1"
              values={{ amount: formatNumber(amount, { symbol: currencySymbol }) }}
              components={{ deco: <span className="highlight" /> }}
            />
          </div>
          <div>
            <Trans
              ns="leader-board"
              i18nKey="Copy_Modal_Confirm2"
              values={{ name: leaderName }}
              components={{ deco: <span className="highlight" /> }}
            />
          </div>
        </ModalBody>
      ),
      isDefaultBtn: true,
      cancelBtn: true,
      cancelText: t('Common_No', { ns: 'common' }),
      confirmText: t('Common_Yes', { ns: 'common' }),
      onConfirm: () => copy(leaderCd),
    }),
    [copy, currencySymbol, t, Trans]
  );

  const onlyFollowerModal = useMemo(
    () => ({
      body: (
        <ModalBody>
          <Trans ns="leader-board" i18nKey="Copy_Modal_Not_Follower" />
        </ModalBody>
      ),
      isDefaultBtn: true,
      cancelBtn: false,
      hasCloseIcon: true,
    }),
    [t, Trans]
  );

  const redirectModal = useCallback(
    (leaderCd: number) => ({
      body: (
        <ModalBody>
          <Trans ns="leader-board" i18nKey="Copy_Modal_Not_Login" />
        </ModalBody>
      ),
      buttons: (
        <ButtonWrapper>
          <Button
            variant="contained"
            onClick={() => {
              navigate(PATH.REGISTER + (autoCopy ? `?leaderCode=${leaderCd}` : ''));
            }}
            style={{
              width: '150px',
              height: '44px',
              color: 'white',
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: defaultTheme.color.secondary,
            }}
            className="confirm-btn"
          >
            {t('CT_head2', { ns: 'common' })}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate(
                PATH.LOGIN,
                autoCopy ? { state: { redirectUrl: location.pathname, autoCopy: { leaderCd } } } : undefined
              );
            }}
            style={{
              width: '150px',
              height: '44px',
              color: 'white',
              fontWeight: 600,
              fontSize: 15,
              backgroundColor: defaultTheme.button.primary,
            }}
            className="confirm-btn"
          >
            {t('Common_Login', { ns: 'common' })}
          </Button>
        </ButtonWrapper>
      ),
      closeOnBackdropClick: false,
      hasCloseIcon: true,
    }),
    [navigate, t, Trans, location, autoCopy]
  );

  const notEnoughModal = useCallback(
    (leaderName?: string, amount?: number) => ({
      body: (
        <ModalBody>
          <div>
            <Trans
              ns="leader-board"
              i18nKey="Copy_Modal_Not_Enough_Balance"
              values={{ amount: formatNumber(amount, { symbol: currencySymbol }), name: leaderName }}
              components={{ deco: <span className="highlight" /> }}
            />
          </div>
        </ModalBody>
      ),
      isDefaultBtn: true,
      cancelBtn: true,
      cancelText: t('Common_Confirm', { ns: 'common' }),
      confirmText: t('Common_Deposit', { ns: 'common' }),
      onConfirm: () => {
        navigate(PATH.MY.FUND);
      },
    }),
    [navigate, currencySymbol, t, Trans]
  );

  const notCertifiedModal = useMemo(
    () => ({
      body: (
        <ModalBody>
          <Trans ns="leader-board" i18nKey="Copy_Modal_Not_Certified" />
        </ModalBody>
      ),
      isDefaultBtn: true,
      cancelBtn: true,
      cancelText: t('Common_Confirm', { ns: 'common' }),
      confirmText: t('Copy_Modal_Button_To_KYC'),
      onConfirm: () => {
        navigate(`${PATH.MY.SETTING}#mobile-number`);
      },
    }),
    [navigate, t, Trans]
  );

  const errorModal = useMemo(
    () => ({
      body: (
        <ModalBody>
          <Trans ns="common" i18nKey="Error_Common_Msg" />
        </ModalBody>
      ),
      isDefaultBtn: true,
      cancelBtn: false,
      confirmText: t('Common_Confirm', { ns: 'common' }),
      modalWrapStyle: { width: '360px' },
    }),
    [t, Trans]
  );

  return {
    createOnCopy: (leaderCd: number) => (e?: MouseEvent<HTMLButtonElement>) => {
      e?.stopPropagation();
      if (!role || balance === undefined) showModal(redirectModal(leaderCd));
      else if (role === 'Leader') showModal(onlyFollowerModal);
      else if (role === 'Follower') {
        if (isSimple) showModal(notCertifiedModal);
        else {
          prepare(leaderCd).then(({ exchangedMinFundingAmount, nickname }) => {
            if (!exchangedMinFundingAmount) {
              throw showModal(errorModal);
            } else if (balance < exchangedMinFundingAmount)
              showModal(notEnoughModal(nickname, exchangedMinFundingAmount));
            else showModal(copyModal(leaderCd, nickname, exchangedMinFundingAmount));
          });
        }
      }
    },
  };
};

const ModalBody = styled.div`
  color: ${({ theme }) => theme.font.primary};
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .highlight {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
