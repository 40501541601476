import { MouseEvent, useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import dummyImg from 'assets/img/profile_default.png';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { GetLeadersResponseDto } from 'services/react-query/leaders';
import defaultTheme from 'styles/theme';
import { formatNumber, formatPeriod } from 'utils/format';
import { handleDomainByTld } from 'utils/handleDomainByTld';

import Button from './Button';

type LeaderData = GetLeadersResponseDto['items'][0];
const defaultOrder = ['roi', 'mdd', 'aum', 'trades', 'followers'];

interface LeaderCardProps {
  mainDisplay?: string;
  data: LeaderData;
  responsive?: boolean;
  fixedWidth?: boolean;
  rank?: number;
  onCopy?: (e?: MouseEvent<HTMLButtonElement>) => void;
  customOrder?: (string | undefined)[];
}

interface DataDisplay {
  key: string;
  titleMain: string;
  titleSub: string;
  value?: string;
  negative?: boolean;
}

export default function LeaderCard({
  mainDisplay = 'roi',
  data,
  responsive = false,
  fixedWidth = false,
  rank,
  onCopy,
  customOrder,
}: LeaderCardProps) {
  const navigate = useNavigate();
  const { t } = useTranslationNamespace('leader-board');

  const formattedData = useMemo<{ [key: string]: DataDisplay }>(
    () => ({
      roi: {
        key: 'roi',
        titleMain: t('Leader_Board_Head_Filter1'),
        titleSub: t('Leader_Board_Head_Filter1'),
        value: formatNumber(data?.roi, { signDisplay: true, maxFractionalDigits: 2, maxSignificantDigits: 4 }) + '%',
        negative: data?.roi < 0,
      },
      mdd: {
        key: 'mdd',
        titleMain: t('Leader_Board_Info2'),
        titleSub: t('Leader_Board_Info2'),
        value: formatNumber(data?.mdd) + '%',
      },
      aum: {
        key: 'aum',
        titleMain: t('Leader_Board_Info3'),
        titleSub: t('Leader_Board_Info3'),
        value: formatNumber(data?.aum, { symbol: '$', compact: true }),
        negative: data?.aum < 0,
      },
      followers: {
        key: 'followers',
        titleMain: t('Leader_Board_Info5'),
        titleSub: t('Leader_Board_Info5'),
        value: formatNumber(data?.followers),
      },
      trades: {
        key: 'trades',
        titleMain: t('Leader_Board_Info6'),
        titleSub: t('Leader_Board_Info6'),
        value: formatNumber(data?.trades),
      },
    }),
    [t, data]
  );

  const order = useMemo(() => {
    if (!!customOrder) return customOrder;
    const index = defaultOrder.findIndex((el) => el === mainDisplay);
    if (index === -1) {
      return defaultOrder;
    } else {
      const newOrder = [...defaultOrder];
      let tmp = newOrder[index];
      newOrder[index] = newOrder[0];
      newOrder[0] = tmp;
      return newOrder;
    }
  }, [mainDisplay, customOrder]) as Array<keyof typeof formattedData>;

  const handleClick = () => {
    navigate(`/leader-board/detail/${data.leaderCd}`, { state: { rank: rank } });
  };

  return (
    <CardWrapper className={fixedWidth ? 'fixed-width' : ''} onClick={handleClick}>
      {responsive ? (
        <CardHeader>
          <div className="mobile-wrapper">
            <LazyLoad height={70}>
              <img
                src={
                  data.avatar
                    ? `${handleDomainByTld(process.env.REACT_APP_ASSET_DOMAIN as string)}/${data.avatar}`
                    : dummyImg
                }
                alt="leader profile"
                className="profile mobile-profile"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyImg;
                }}
                width={70}
                height={70}
              />
            </LazyLoad>
            <div className="block">
              <div className="row mobile-row">
                <div className="name">
                  <span className="rank">#{rank}</span> {data.nickname}
                </div>
              </div>
              <div className="row mobile-row">
                <div className="title">{formattedData[order[0]].titleMain}</div>
                <div className="mainValue">{formattedData[order[0]].value ?? 0}</div>
              </div>
              <div className="row mobile-row">
                <div className="title">{t('Leader_Board_Info1')}</div>
                <div className="value">{formatPeriod(data.regDatetime)}</div>
              </div>
            </div>
          </div>
        </CardHeader>
      ) : (
        <CardHeader>
          <div className="row">
            <div className="name">
              <span className="rank">#{rank}</span> {data.nickname}
            </div>
          </div>
          <div className="row">
            <LazyLoad height={70}>
              <img
                src={
                  data.avatar
                    ? `${handleDomainByTld(process.env.REACT_APP_ASSET_DOMAIN as string)}/${data.avatar}`
                    : dummyImg
                }
                alt="leader profile"
                className="profile"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = dummyImg;
                }}
                width={70}
                height={70}
              />
            </LazyLoad>
            <div className={`mainData${formattedData[order[0]].negative ? ' negative' : ''}`}>
              <div className="mainTitle">{formattedData[order[0]].titleMain}</div>
              <div className="mainValue">{formattedData[order[0]].value}</div>
            </div>
          </div>
          <div className="row">
            <div className="title">{t('Leader_Board_Info1')}</div>
            <div className="value">{formatPeriod(data.regDatetime)}</div>
          </div>
        </CardHeader>
      )}
      <CardData>
        <div className="row">
          <div className="block">
            {!!formattedData[order[1]] && (
              <>
                <div className="title left">{formattedData[order[1]].titleSub}</div>
                <div className="value left">{formattedData[order[1]].value}</div>
              </>
            )}
          </div>
          <div className="block">
            {!!formattedData[order[2]] && (
              <>
                <div className="title right">{formattedData[order[2]].titleSub}</div>
                <div className="value right">{formattedData[order[2]].value}</div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="block">
            {!!formattedData[order[3]] && (
              <>
                <div className="title left">{formattedData[order[3]].titleSub}</div>
                <div className="value left">{formattedData[order[3]].value}</div>
              </>
            )}
          </div>
          <div className="block">
            {!!formattedData[order[4]] && (
              <>
                <div className="title right">{formattedData[order[4]].titleSub}</div>
                <div className="value right">{formattedData[order[4]].value}</div>
              </>
            )}
          </div>
        </div>
      </CardData>
      <CardButtons>
        <Button
          variant="contained"
          onClick={handleClick}
          style={{
            width: '100%',
            height: '35px',
            backgroundColor: defaultTheme.color.secondary,
            color: defaultTheme.color.white,
            fontSize: '13px',
          }}
          children={t('Leader_Board_Info_button1')}
        />
        <Button
          variant="contained"
          onClick={onCopy}
          style={{
            width: '100%',
            height: '35px',
            backgroundColor: defaultTheme.button.primary,
            color: defaultTheme.color.white,
            fontSize: '13px',
          }}
          children={t('Leader_Board_Info_button2')}
        />
      </CardButtons>
    </CardWrapper>
  );
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
  max-width: 480px;
  height: 100%;

  background: ${({ theme }) => theme.color.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  align-self: stretch;
  cursor: pointer;

  &.fixed-width {
    width: 190px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  border-radius: 12px 12px 0;
  background: #f8f9fb;
  align-self: stretch;

  .profile {
    min-width: 70px;
    min-height: 70px;
    width: 70px;
    height: 70px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;

    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  .name {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: ${({ theme }) => theme.font.primary};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .rank {
      color: ${({ theme }) => theme.color.sub2};
    }
  }

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    button,
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .mainData {
    color: ${({ theme }) => theme.font.copy};
    &.negative {
      color: ${({ theme }) => theme.status.warning};
    }
    .mainTitle {
      font-weight: 600;
      text-align: end;
      font-size: 15px;
      line-height: 20px;
    }
    .mainValue {
      font-weight: 600;
      text-align: end;
      font-size: 19px;
      line-height: 24px;
    }
  }

  .title {
    color: ${({ theme }) => theme.font.quaternary};
  }
  .value {
    color: ${({ theme }) => theme.font.primary};
  }

  .block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    width: 100%;
  }

  .mobile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    .block {
      width: calc(100% - 110px);
    }
  }
  .mobile-profile {
    min-width: 90px;
    min-height: 90px;
    width: 90px;
    height: 90px;
  }
  .mobile-row {
    align-items: center;
    .mainValue {
      font-size: 20px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      color: ${({ theme }) => theme.font.copy};
    }
  }
`;

const CardData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  align-self: stretch;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    align-self: stretch;

    .title {
      width: 100%;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: ${({ theme }) => theme.font.quaternary};
    }
    .value {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: ${({ theme }) => theme.font.primary};
    }
    .right {
      text-align: right;
    }
    .left {
      text-align: left;
    }
  }
`;

const CardButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 15px 15px;
  gap: 8px;

  align-self: stretch;
`;
