import styled from 'styled-components';

import { ReactComponent as MyFooterIcon } from 'assets/logo/logo_prime_color.svg';
import DEVICE_SIZE from 'constant/deviceSize';
import { handleDomain } from 'utils/handleDomain';

export default function MyPageFooter() {
  return (
    <FooterOuter>
      <FooterWrapper>
        <div className="logo">
          <MyFooterIcon />
        </div>
        <div className="desc">
          <p>Registered in SVG.</p>
          Read risk disclosure before trading Forex/CFDs. Forex/CFD trading involves substantial risk of loss and is not
          suitable for all investors. {handleDomain()} domain is owned and operated by Land Prime Ltd., Suite 305,
          Griffith Corporate Centre, Beachmont, 1510 Kingstown, St. Vincent and the Grenadines © 2013 Land Prime Ltd.
          All rights reserved. help@land-fx.com +63 7767086
        </div>
      </FooterWrapper>
    </FooterOuter>
  );
}

const FooterOuter = styled.footer`
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.color.white};
  padding: 29px 0;
  border-top: 1px solid ${({ theme }) => theme.line.box};

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    height: auto;
    padding: 20px;
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  gap: 50px;
  align-items: center;

  .logo {
    min-width: 128px;
  }

  .desc {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #a6a6a6;
    p {
      font-weight: 700;
    }
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;
