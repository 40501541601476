import styled from 'styled-components';

import CountryFlag from 'components/Common/CountryFlag';
import SearchableSelectBox from 'components/Common/SearchableSelectBox';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { GetCountriesResponseDto } from 'services/react-query/countries';
import { CountriesListStore } from 'store/countriesListStore';

interface CountrySelectBoxProps {
  value?: string;
  onSelect?: (value?: GetCountriesResponseDto) => void;
}

export default function CountrySelectBox({ value, onSelect }: CountrySelectBoxProps) {
  const { supportedCountries } = CountriesListStore();
  const { t } = useTranslationNamespace();
  return (
    <StyledSelectBox
      options={supportedCountries}
      searchKey="countryName"
      onSelect={onSelect}
      leftSlot={(el) => <>{el && <CountryFlag countryAbbr={el.countryAbbr ?? ''} />}</>}
      listContent={(el, i) => (
        <div className="element-wrapper">
          <CountryFlag countryAbbr={el.countryAbbr} />
          <div className="country-name">{el.countryName}</div>
        </div>
      )}
      placeholder={t('Placeholder_Country_Of_Residence', { ns: 'common' }) as string}
      value={value}
    />
  );
}

const StyledSelectBox = styled(SearchableSelectBox<GetCountriesResponseDto>)`
  width: 100%;
  height: 100%;
  align-items: center;
  border: none;
  padding: auto;
  padding-right: 0;
  padding-left: 0;
  box-sizing: border-box;
  gap: 10px;
  .country-flag {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.line.my};
  }
  .element-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .country-name {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }
`;
