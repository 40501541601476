import { create } from 'zustand';

interface LoadingStoreProps {
  isLoading: boolean;
  setLoading: (show: boolean) => void;
  stack: number;
}

export const LoadingStore = create<LoadingStoreProps>((set, get) => ({
  isLoading: false,
  stack: 0,
  setLoading: (show: boolean) => {
    if (show) {
      set({ isLoading: true, stack: get().stack + 1 });
    } else {
      const stackAfter = get().stack - 1;
      set({ isLoading: stackAfter > 0, stack: stackAfter });
    }
  },
}));

export const { setLoading } = LoadingStore.getState();
