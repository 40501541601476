import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import styled from 'styled-components';

import Button from 'components/Common/Button';
import StyledRadio from 'components/Common/StyledRadio';
import DEVICE_SIZE from 'constant/deviceSize';
import useToggleState from 'hooks/useToggleState';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import * as S from 'styles/register.and.login.styled';
import savePromotionCode from 'utils/savePromotionCode';

import { LoginImageBox } from '..';

import PlatformDownloadButtons from './PlatformDownloadButtons';

interface StepProps {
  handleNextStep: () => void;
  accountType: string;
  handleAccountType: (value: any) => void;
}

const commonLabelStyle = {
  fontSize: '17px',
  lineHeight: '24px',
};

const Step1 = (props: StepProps) => {
  const isBreakPoint = useMediaQuery({ query: `(max-width : ${DEVICE_SIZE.BREAK_POINT})` });
  const [searchParams] = useSearchParams();
  const { t, Trans } = useTranslationNamespace();
  const { toggleState, handleToggleState: handleSelected } = useToggleState(props.accountType === 'Follower');

  useEffect(() => {
    if (searchParams.get('promotionCode') === '30_dubai_bonus') savePromotionCode('30_dubai_bonus', 20);
  }, [searchParams]);

  return (
    <StepContainer>
      {!isBreakPoint && (
        <LoginImageBox>
          <Adjust>
            <S.TextBox>
              <h1>{t('Register_Title')}</h1>
              <p>{t('Register_Title_Desc')}</p>
            </S.TextBox>
            <PlatformDownloadButtons />
          </Adjust>
        </LoginImageBox>
      )}
      <StepArea>
        <StepWrapper>
          <div className="description">
            <Trans i18nKey="Register_Type" ns="register" />
          </div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={props.accountType}
              onChange={props.handleAccountType}
            >
              <FormControlLabel
                checked={toggleState}
                onChange={() => handleSelected()}
                value="Follower"
                control={<StyledRadio />}
                label={t('Register_Type_Follower')}
                sx={{
                  '.MuiFormControlLabel-label': {
                    ...commonLabelStyle,
                    color: `${toggleState ? '#1352EF' : '#333333'}`,
                    fontWeight: `${toggleState ? 600 : 400}`,
                  },
                  height: '24px',
                }}
              />
              <FormControlLabel
                checked={!toggleState}
                onChange={() => handleSelected()}
                value="Leader"
                control={<StyledRadio />}
                label={t('Register_Type_Leader')}
                sx={{
                  '.MuiFormControlLabel-label': {
                    ...commonLabelStyle,
                    color: `${!toggleState ? '#1352EF' : '#333333'}`,
                    fontWeight: `${!toggleState ? 600 : 400}`,
                  },
                  height: '24px',
                }}
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            style={{
              height: '54px',
              backgroundColor: '#1352EF',
              color: 'white',
              fontWeight: 600,
              fontSize: '17px',
              lineHeight: '20px',
              borderRadius: '10px',
            }}
            onClick={() => props.handleNextStep()}
          >
            {t('Register_Type_Next')}
          </Button>
        </StepWrapper>
      </StepArea>
    </StepContainer>
  );
};

export default Step1;

const Adjust = styled.div`
  width: 100%;
  max-width: 720px;
  padding-top: 50px;
  padding-left: 80px;
`;

const StepContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const StepArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 30px 16px 50px;
    justify-content: center;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px;
  color: ${({ theme }) => theme.font.primary};
  gap: 50px;

  > * {
    width: 100%;
    max-width: 424px;
  }

  .description {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
  }

  .MuiFormGroup-root {
    gap: 50px;
  }

  .Mui-checked {
    color: ${({ theme }) => theme.button.primary} !important;
  }

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    gap: 30px;

    .MuiFormGroup-root {
      gap: 20px;
    }
  }
`;
