import { CSSProperties } from 'react';

import styled from 'styled-components';

interface ButtonProps {
  variant: 'outlined' | 'contained';
  style?: CSSProperties;
  onClick?: (e?: any) => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
}

const Button = ({ variant, style, onClick, children, disabled, className, type }: ButtonProps) => {
  return (
    <ButtonWrapper
      type={type}
      className={className}
      onClick={onClick}
      variant={variant}
      style={style ?? {}}
      disabled={disabled ?? false}
    >
      {children}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button.attrs((props: ButtonProps) => ({
  type: props.type ?? 'button',
  children: props.children,
}))<{ variant: string; style: CSSProperties; disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ style }) => style.width};
  height: ${({ style }) => style.height};
  cursor: ${({ disabled }) => disabled && 'auto'};
  border-radius: 8px;

  ${({ variant, style }) =>
    variant === 'outlined' &&
    `
        border: 1px solid ${style.color};
        color: ${style.color};
        background-color: none;
        ${{ ...style }}
    `}

  ${({ variant, style, theme }) =>
    variant === 'contained' &&
    `
        background-color: ${style.backgroundColor};
        color: ${theme.color.white};
        ${{ ...style }}
     `}
`;

export default Button;
