import { useEffect } from 'react';
import { Trans, useTranslation, UseTranslationOptions } from 'react-i18next';
import { useLocation } from 'react-router-dom';

/**
 *
 * @param namespace
 * @param options
 * @returns useTranslation의 return values인 t function, i18n instance
 *
 */

const useTranslationNamespace = (namespace?: string | string[], options?: UseTranslationOptions) => {
  const { pathname: _pathname } = useLocation();
  const pathname = _pathname.includes('/my/') ? _pathname.split('/my/')[1] : _pathname.split('/')[1];

  const { t, i18n } = useTranslation(namespace ?? pathname, options);

  useEffect(() => {
    i18n.loadNamespaces(namespace ?? pathname);
  }, [pathname]);

  return { t, i18n, Trans };
};

export default useTranslationNamespace;
