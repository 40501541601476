import { useLocation, useNavigate } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icon/icon_close.svg';
import { ReactComponent as CTLogo } from 'assets/logo/ct_logo.svg';
import { ReactComponent as Logo } from 'assets/logo/logo_prime.svg';
import { LinkText } from 'components/Common/LinkText';
import AddAccountModal from 'components/MainLayout/AddAccountModal';
import MobileDownloadButton from 'components/MainLayout/MobileDownloadButton';
import DEVICE_SIZE from 'constant/deviceSize';
import PATH from 'constant/paths';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { useLogout } from 'services/react-query/auth';
import { useUserInfoStore, useUserLoginStore } from 'store/userInfoStore';
import SideDrawerProps from 'types/common';

import Button from './Button';
import { showModal } from './GlobalModal';

export default function SideDrawer({ open, onClose }: SideDrawerProps) {
  const { t } = useTranslationNamespace('common');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { role } = useUserInfoStore();
  const { isLoggedIn } = useUserLoginStore();
  const { logout } = useLogout();

  return (
    <StyledDrawer open={open} onClose={onClose}>
      <SideDrawerWrapper>
        <div>
          <button className="close-btn" onClick={onClose}>
            <CloseIcon width={24} height={24} />
          </button>
          <LogoWrapper href="/">
            <Logo width="126.4" height="24" />
            <div className="divider" />
            <CTLogo width="59" height="24" />
          </LogoWrapper>
          <LinkWrapper
            onClick={(e: any) => {
              if (e.target.tagName === 'DIV') return;
              onClose();
            }}
          >
            {!isLoggedIn && (
              <LinkText to={PATH.REGISTER} $isActive={pathname === PATH.REGISTER}>
                {t('CT_head2')}
              </LinkText>
            )}
            <LinkText to={PATH.LEADER_BOARD} $isActive={pathname === PATH.LEADER_BOARD}>
              {t('CT_head3')}
            </LinkText>
            <LinkText to={PATH.FAQ} $isActive={pathname === PATH.FAQ}>
              {t('CT_head4')}
            </LinkText>
            <MobileDownloadButton />
            {isLoggedIn && (
              <>
                <LinkText to={PATH.MY.ACCOUNT} $isActive={pathname === PATH.MY.ACCOUNT}>
                  {t('CT_head5')}
                </LinkText>
                <LinkText to={PATH.MY.TRADING} $isActive={pathname === PATH.MY.TRADING}>
                  {t('CT_head6')}
                </LinkText>
                <LinkText to={PATH.MY.SETTING} $isActive={pathname === PATH.MY.SETTING}>
                  {t('CT_head7')}
                </LinkText>
                {role === 'Leader' && (
                  <>
                    <LinkText to={PATH.MY.FOLLOWERS} $isActive={pathname === PATH.MY.FOLLOWERS}>
                      {t('CT_head8')}
                    </LinkText>
                    <LinkText to={PATH.MY.INVITE} $isActive={pathname === PATH.MY.INVITE}>
                      {t('CT_head9')}
                    </LinkText>
                  </>
                )}
                <LinkText to={PATH.MY.FUND} $isActive={pathname === PATH.MY.FUND}>
                  {t('CT_head10')}
                </LinkText>
              </>
            )}
          </LinkWrapper>
        </div>
        <BottomWrapper>
          <div className="buttons-wrapper">
            <Button
              variant="contained"
              style={{
                color: '#FFF',
                fontSize: '19px',
                fontWeight: 600,
                height: '50px',
                border: '2px solid white',
                width: '100%',
              }}
              onClick={() => {
                if (pathname === PATH.REGISTER) {
                  window.location.replace(PATH.REGISTER);
                  onClose();
                  return;
                }

                isLoggedIn
                  ? (() => {
                      onClose();
                      showModal({
                        body: <AddAccountModal />,
                        closeOnBackdropClick: false,
                        modalWrapStyle: {
                          padding: '20px',
                          width: 'fit-content',
                        },
                      });
                    })()
                  : navigate(PATH.REGISTER);
              }}
            >
              {isLoggedIn ? t('add_account') : t('CT_head2')}
            </Button>
            <Button
              variant="contained"
              style={{
                color: '#FFF',
                fontSize: '19px',
                fontWeight: 600,
                height: '50px',
                border: '2px solid white',
                width: '100%',
              }}
              onClick={() => {
                if (pathname === PATH.LOGIN) {
                  onClose();
                  return;
                }
                isLoggedIn ? logout() : navigate(PATH.LOGIN);
              }}
            >
              {!isLoggedIn ? t('Common_Login') : t('Common_Logout')}
            </Button>
          </div>
          <div>Regulated by the Finance Services Authority (FSA) : 23627 IBC 2016</div>
        </BottomWrapper>
      </SideDrawerWrapper>
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.button.secondary};
  }
`;

const SideDrawerWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100vw;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
  height: 100vh;
  min-height: ${DEVICE_SIZE.MIN_HEIGHT};
  padding: 74px 28px 30px 28px;
  color: ${({ theme }) => theme.color.white};
  align-items: stretch;
  justify-content: space-between;

  .close-btn {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 20px;
    right: 0px;
    transform: translateX(-100%);
  }
`;

const LogoWrapper = styled.a`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;

  .divider {
    width: 1px;
    height: 24px;
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 35px;
  font-size: 17px;
  line-height: 24px;

  .divider {
    width: 100%;
    min-width: 319px;
    height: 1px;
    background-color: ${({ theme }) => theme.color.secondary};
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  margin-top: 40px;
  gap: 30px;
  min-width: 319px;
  width: 100%;
  div {
    color: ${({ theme }) => theme.font.guide};
    font-size: 13px;
    text-align: center;
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
  }
`;
