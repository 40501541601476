import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styled, { css } from 'styled-components';
import { create, SetState } from 'zustand';

import { ReactComponent as CloseIcon } from 'assets/icon/icon_close_modal.svg';
import Button from 'components/Common/Button';
import DEVICE_SIZE from 'constant/deviceSize';
import useTranslationNamespace from 'hooks/useTranslationNamespace';

import { Modal } from './Modal';

type Fn = () => void;

interface ModalState {
  show: boolean;
  msg: string;
  closeBtn: boolean;
  cancelBtn: boolean;
  isDefaultBtn: boolean;
  buttons?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: Fn;
  onCancel?: Fn;
  body?: React.ReactNode;
  closeOnBackdropClick?: boolean;
  setShow: (show: boolean) => void;
  setClose: () => void;
  set: SetState<this>;
  modalWrapStyle?: any;
  scModalWrapStyle?: ReturnType<typeof css>;
  hasCloseIcon?: boolean;
  disableEscapeKeyDown?: boolean;
}

const initialState = {
  show: false,
  msg: '',
  isDefaultBtn: false,
  closeBtn: false,
  cancelBtn: true,
  buttons: undefined,
  cancelText: undefined,
  confirmText: undefined,
  onConfirm: undefined,
  onCancel: undefined,
  closeOnBackdropClick: true,
  body: null,
  hasCloseIcon: true,
  modalWrapStyle: undefined,
  scModalWrapStyle: undefined,
  disableEscapeKeyDown: false,
};

const createApi = (set: SetState<ModalState>) => ({
  setShow: (show: boolean) => set({ show }),
  setClose: () => set({ show: false }),
  set,
});

export const useModalStore = create<ModalState>((set) => {
  return { ...initialState, ...createApi(set) };
});

export const showModal = (args: Partial<ModalState>) => {
  const { show, ...rest } = args;

  useModalStore.getState().set({
    ...initialState,
    show: true,
    ...(rest as any),
  });
};

export const closeModal = () => {
  useModalStore.getState().set({
    show: false,
  });
};

const commonButtonStyle = {
  width: '140px',
  height: '44px',
  color: 'white',
  fontWeight: 600,
  fontSize: 15,
};

function GlobalModal() {
  const isPromotionPage = window.location.href.includes('promotion');
  const { t } = useTranslationNamespace('common');
  const modalStore = useModalStore();

  const { pathname } = useLocation();

  useEffect(() => {
    modalStore.setClose();
  }, [pathname]);

  const handleClose = () => {
    modalStore.setClose();
    modalStore.onCancel?.();
  };

  const handleConfirm = () => {
    modalStore.setClose();
    modalStore.onConfirm?.();
  };

  return (
    <Modal
      isOpen={modalStore.show}
      onClose={(_, reason) => {
        if (!modalStore.closeOnBackdropClick) {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        } else {
          handleClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      isPromotionPage={isPromotionPage}
      disableEscapeKeyDown={modalStore.disableEscapeKeyDown}
    >
      <ModalContentWrapper modalWrapStyle={modalStore.modalWrapStyle} scModalWrapStyle={modalStore.scModalWrapStyle}>
        <ModalBodyWrapper className="modal-body-wrapper">
          {modalStore.hasCloseIcon && <CloseIcon className="close-icon-btn" onClick={handleClose} fill="#333333" />}
          {modalStore?.body}
        </ModalBodyWrapper>
        {modalStore?.buttons}
        {modalStore.isDefaultBtn && (
          <ButtonsWrapper>
            {modalStore.cancelBtn && (
              <Button
                variant="contained"
                onClick={handleClose}
                style={{
                  ...commonButtonStyle,
                  backgroundColor: '#A5AFC4',
                }}
                className="cancel-btn"
              >
                {modalStore.cancelText ?? t('Leader_Setting_Fund_Activation_Popup_No')}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleConfirm}
              style={{
                ...commonButtonStyle,
                backgroundColor: '#1352EF',
              }}
              className="confirm-btn"
            >
              {modalStore.confirmText ?? t('Common_Confirm')}
            </Button>
          </ButtonsWrapper>
        )}
      </ModalContentWrapper>
    </Modal>
  );
}

export default GlobalModal;

const ModalContentWrapper = styled.div<{ modalWrapStyle?: any; scModalWrapStyle?: ReturnType<typeof css> }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px 20px;
  width: 360px;
  text-align: center;
  background: ${(props) => props.theme.color.white};
  color: ${({ theme }) => theme.font.primary};
  outline: 0;
  gap: 28px;
  ${(props) => {
    return { ...props.modalWrapStyle };
  }}
  ${({ scModalWrapStyle }) => scModalWrapStyle}
  border-radius: 20px;

  .close-icon-btn {
    position: relative;
    margin-left: auto;
    cursor: pointer;
    z-index: 1;
  }

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    width: 330px;
    ${(props) => {
      return { ...props.modalWrapStyle };
    }}
  }
`;

const ModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;

  width: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
