import { useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

import { showModal } from 'components/Common/GlobalModal';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { apiPrivate, ResponsePromise } from 'services/apiService';

/**
 * PLE, POA 등 legal documents
 * Swagger : mypage-profile
 **/

interface LegalDocumentDto {
  memberCd: number;
  file: string;
  stat: string;
  regDate: string;
  fileUrl: string;
}

interface PleDto extends LegalDocumentDto {
  pleCd: number;
}

interface PoaDto extends LegalDocumentDto {
  poaCd: number;
  addressCountryCd: number;
  addressProvince: string;
  addressCity: string;
  addressZip: string;
  addressRest: string;
}

export interface FindLegalResponseDto {
  ple: PleDto;
  poa: PoaDto;
}

interface PostPleResponseDto {
  response: string;
  state: number;
}

interface DataObject {
  [key: string]: number | string | File | undefined;
}

const getLegalDocuments = (): ResponsePromise<FindLegalResponseDto> => {
  return apiPrivate.get(`/profile/legal`);
};

const postPleFile = (data: File): ResponsePromise<PostPleResponseDto> => {
  const formData = new FormData();
  formData.append('ple', data);
  return apiPrivate.post(`/profile/ple`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const postPoaFile = (data: DataObject): ResponsePromise<PostPleResponseDto> => {
  const formData = new FormData();

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key] as any);
    }
  }

  return apiPrivate.post(`/profile/poa`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// fileUrl의 유효 시간이 3 * 1000 * 60 ms이므로, 해당 파일의 view를 위해서는 재호출해야함
export const useGetLegalDocuments = (trigger?: boolean, isClickViewBtn?: boolean) => {
  const [isExpired, setExpired] = useState(false);

  return useQuery({
    queryKey: ['getLegalDocument'],
    queryFn: () => getLegalDocuments(),
    onSuccess: () => {
      setTimeout(() => {
        setExpired(true);
      }, 3 * 1000 * 60);
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    refetchInterval: isClickViewBtn && isExpired ? 3 * 1000 * 60 : false,
  });
};

export const useUploadPle = () => {
  const { Trans } = useTranslationNamespace('setting');
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: File) => postPleFile(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['getLegalDocument']);
      showModal({
        body: (
          <ModalBody>
            <Trans i18nKey="Setting_Legal_Document_Upload_Success" ns="setting" />
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
    onError: (err) => {
      console.log(err);
      showModal({
        body: (
          <ModalBody>
            <Trans i18nKey="Setting_Legal_Document_Upload_Fail" ns="setting" />
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useUploadPoa = () => {
  const queryClient = useQueryClient();
  const { Trans } = useTranslationNamespace('setting');

  return useMutation({
    mutationFn: (data: DataObject) => postPoaFile(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['getLegalDocument']);
      showModal({
        body: (
          <ModalBody>
            <Trans i18nKey="Setting_Legal_Document_Upload_Success" ns="setting" />
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
    onError: (err) => {
      console.log(err);
      showModal({
        body: (
          <ModalBody>
            <Trans i18nKey="Setting_Legal_Document_Upload_Fail" ns="setting" />
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

const ModalBody = styled.div`
  min-width: 260px !important;
  margin-top: 10px;
  background-color: white;
`;
