import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 * @param initialState
 * @returns toggleState, handleToggleState 함수를 담은 객체
 * Modal, SideDrawer, SwitchButton 등 on/off가 필요한 모든 컴포넌트에 적용
 * when routing, initialize state (or not, modal is still open when routing)
 */

const useToggleState = (initialState?: boolean) => {
  const { pathname } = useLocation();

  const [toggleState, setToggleState] = useState(initialState ?? false);
  const handleToggleState = () => setToggleState((p) => !p);

  useEffect(() => {
    setToggleState(initialState ?? false);
  }, [pathname]);

  return { toggleState, handleToggleState };
};

export default useToggleState;
