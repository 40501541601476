import styled, { css } from 'styled-components';

import DEVICE_SIZE from 'constant/deviceSize';

/**
 * /my/account, my/setting 등 /my 로 시작하는 path의 공통 page wrapper styling
 * import * as myPage from 'styles/mypage.wrapper.styled'; 처럼 import 해와서 사용
 */

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.bg.primary};
  width: 100%;
  height: 100%;

  @media screen and (max-width: ${DEVICE_SIZE.CONTENTS_MAX_WIDTH}) {
    padding: 0;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 0;
  }
`;

export const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;

  &:lang(jp) {
    font-weight: 500;
    font-family: 'M PLUS Rounded 1c', sans-serif;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    font-size: 17px;
  }
`;

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const ContentBlock = styled.section`
  ${FlexColumn};
  width: 100%;
  height: fit-content;
  padding: 30px;
  background-color: ${({ theme }) => theme.color.white};
  margin-top: 20px;
  color: ${({ theme }) => theme.font.primary};
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 20px;
  }
`;
