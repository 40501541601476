import { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import AuthGuard from 'components/AuthGuard';
import Logout from 'components/Logout';
import MainLayout from 'components/MainLayout';
import Landing from 'pages/Landing';

const MyAccount = lazy(() => import('pages/MyAccount'));
const Register = lazy(() => import('pages/Register'));
const Trading = lazy(() => import('pages/Trading'));
const Login = lazy(() => import('pages/LogIn'));
const Fund = lazy(() => import('pages/Fund'));
const Setting = lazy(() => import('pages/Setting'));
const LeaderBoard = lazy(() => import('pages/LeaderBoard'));
const LeaderDetail = lazy(() => import('pages/LeaderDetail'));
const FAQ = lazy(() => import('pages/FAQ'));
const Invite = lazy(() => import('pages/Invite'));
const Followers = lazy(() => import('pages/Followers'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ResetUserPassword = lazy(() => import('pages/ResetUserPassword'));
const NotFound = lazy(() => import('pages/NotFound'));
const Promotion = lazy(() => import('pages/Promotion/first'));
const PromotionSecond = lazy(() => import('pages/Promotion/second'));
const PromotionThird = lazy(() => import('pages/Promotion/third'));
const PromotionRoi = lazy(() => import('pages/Promotion/roi'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { index: true, element: <Landing /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      {
        path: 'login/reset_password',
        element: <ResetPassword />,
      },
      {
        path: 'login/user_reset_password',
        element: <ResetUserPassword />,
      },
      {
        path: 'leader-board',
        element: <LeaderBoard />,
      },
      {
        path: 'leader-board/detail/:leaderCd',
        element: <LeaderDetail />,
      },
      {
        path: 'faq',
        element: <FAQ />,
      },
      {
        path: 'promotion/aum_competition',
        element: <Promotion />,
      },
      {
        path: 'promotion/invite_followers_competition',
        element: <PromotionSecond />,
      },
      {
        path: 'promotion/invite_followers_competition_october',
        element: <PromotionThird />,
      },
      {
        path: 'promotion/roi_competition',
        element: <PromotionRoi />,
      },
      {
        path: 'notfound',
        element: <NotFound />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
  {
    path: '/my',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'account',
        element: <MyAccount />,
      },
      {
        path: 'trading',
        element: <Trading />,
      },
      {
        path: 'setting',
        element: <Setting />,
      },
      {
        path: 'fund',
        element: <Fund />,
      },
      // 이 밑은 leader만 접근할 수 있는 페이지
      {
        path: 'followers',
        element: <Followers />,
      },
      {
        path: 'invite',
        element: <Invite />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/notfound" />,
  },
];

export default routes;
