/**
 *
 * @param obj 중첩객체
 * @returns 중첩객체의 values를 한데 묶은 string
 * @usage 에러핸들링시 사용
 */

export default function printObjValues(obj: any) {
  let stringArr = [] as any[];
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      stringArr = stringArr.concat(printObjValues(obj[key]));
    } else {
      stringArr.push(obj[key]);
    }
  }
  return stringArr.join('. ');
}
