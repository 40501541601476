import styled, { css } from 'styled-components';

import DEVICE_SIZE from 'constant/deviceSize';

/**
 * Register page, Login page의 공통 styling
 */

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  min-height: 640px;
  background-color: ${({ theme }) => theme.color.white};

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    min-height: auto;
  }
`;

export const ImageBoxWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 50%;
  object-fit: cover;
  color: ${({ theme }) => theme.font.primary};
  background-color: #d1dfed;

  img {
    position: absolute;
    width: 90%;
    max-width: 550px;
    min-width: 416px;
    height: 100%;
    max-height: 720px;
    bottom: 0;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.line.my};
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  label {
    color: ${({ theme }) => theme.font.tertiary};
    font-size: 15px;
    line-height: 24px;
    margin-right: 30px;
    min-width: 120px;
  }

  input {
    width: calc(100% - 120px);
    font-size: 17px;
    line-height: 24px;
    color: ${({ theme }) => theme.font.primary};
  }

  input::placeholder {
    color: ${({ theme }) => theme.font.guide};
  }
`;

export const TextBox = styled.div<{ isCenter?: boolean }>`
  width: fit-content;
  height: fit-content;
  color: ${({ theme }) => theme.font.primary};
  z-index: 1;

  ${({ isCenter }) =>
    isCenter &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  h1 {
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;

    &:lang(jp) {
      font-weight: 500;
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }
  }

  p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    position: static;
    width: auto;
    margin: 20px 16px;

    h1 {
      line-height: 50px;
      text-align: center;
    }
  }
`;
