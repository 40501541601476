const checkMobileOs = (): 'android' | 'ios' | 'browser' => {
  let agent = navigator.userAgent.toLowerCase();

  if (agent.indexOf('android') > -1) {
    // 안드로이드인 경우
    return 'android';
  }
  if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1 || agent.indexOf('ipod') > -1) {
    // IOS인 경우
    return 'ios';
  }
  return 'browser';
};

export default checkMobileOs;
