import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import { api, ResponsePromise } from 'services/apiService';

export interface ExchangeCurrencyResponseDto {
  amount: number;
}

export interface ExchangeCurrencyQueryString {
  amount: number;
  src: string;
  dest: string;
}

export const getExchangeCurrency = (
  query: ExchangeCurrencyQueryString
): ResponsePromise<ExchangeCurrencyResponseDto> => {
  return api.get(`/exchange/currency?${queryString.stringify(query)}`);
};

export const useExchangeCurrency = (query: ExchangeCurrencyQueryString, trigger?: boolean) => {
  return useQuery({
    queryKey: ['exchange'],
    queryFn: () => getExchangeCurrency(query),
    onSuccess: () => {},
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    cacheTime: 0,
  });
};
