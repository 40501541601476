import styled from 'styled-components';

import { ReactComponent as LeftActiveIcon } from 'assets/icon/left_active.svg';
import { ReactComponent as LeftDefaultIcon } from 'assets/icon/left_default.svg';
import { ReactComponent as RightActiveIcon } from 'assets/icon/right_active.svg';
import { ReactComponent as RightDefaultIcon } from 'assets/icon/right_default.svg';

interface NavButtonProps {
  type: 'prev' | 'next';
  isActive: boolean;
  onClick?: () => void;
}

export default function NavButton({ type, isActive, onClick }: NavButtonProps) {
  return (
    <StyledButton onClick={onClick}>
      {type === 'prev' &&
        (isActive ? <LeftActiveIcon width="30" height="30" /> : <LeftDefaultIcon width="30" height="30" />)}
      {type === 'next' &&
        (isActive ? <RightActiveIcon width="30" height="30" /> : <RightDefaultIcon width="30" height="30" />)}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.1));
`;
