import { create } from 'zustand';

import { GetCountriesResponseDto } from 'services/react-query/countries';

interface SupportedCountriesListDto {
  supportedCountries: Array<GetCountriesResponseDto>;
  supportedCountriesMap: Map<string, GetCountriesResponseDto>;
  setSupportedCountries: (data: Array<GetCountriesResponseDto>) => void;
}

export const CountriesListStore = create<SupportedCountriesListDto>((set) => ({
  supportedCountries: [],
  supportedCountriesMap: new Map(),
  setSupportedCountries: (supportedCountries) => {
    set({
      supportedCountries,
      supportedCountriesMap: new Map(supportedCountries.map((el) => [el.countryName, el])),
    });
  },
}));

export const { supportedCountries, setSupportedCountries } = CountriesListStore.getState();
