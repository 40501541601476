import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

/**
 * url 변경 추적 컴포넌트
 * url가 변경될 때마다 pageview 이벤트 전송 (pageview method deprecated, .send("pageview")로 대체)
 */
const RouteChangeTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  // production만 적용
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.set({ page: location.pathname });
      ReactGA.send('pageview');
    }
  }, [location, initialized]);
};

export default RouteChangeTracker;
