// TODO: 쓰이는 곳 없는지 다시 체크하고 삭제하기

import { useQuery } from '@tanstack/react-query';

import { api, ResponsePromise } from 'services/apiService';

interface GetAllCurrenciesResponseDto {
  currencyCd: number;
  currencyAbbr: string;
  currencySymbol: string;
  accountCurrency: boolean;
  stCurrency: boolean;
}

const getCurrenciesList = (): ResponsePromise<GetAllCurrenciesResponseDto[]> => {
  return api.get('/currencies', { useLoading: false });
};

export const useGetCurrencies = () => {
  return useQuery({
    queryKey: ['getCurrencies'],
    queryFn: () => getCurrenciesList(),
  });
};

interface CurrenciesCTResponseDto {
  currencyCd: number;
  currencyAbbr: string;
  currencySymbol: string;
  accountCurrency: number;
}

const getCurrenciesCT = (): ResponsePromise<CurrenciesCTResponseDto[]> => {
  return api.get('/currencies/social-trading', { useLoading: false });
};

export const useGetCurrenciesCT = () => {
  return useQuery({
    queryKey: ['getCurrenciesCT'],
    queryFn: () => getCurrenciesCT(),
  });
};
