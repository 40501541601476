import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import DEVICE_SIZE from 'constant/deviceSize';
import { isHeaderTransparent } from 'utils/pathCheckers';

import Footer from './Footer';
import FooterToopip from './Footer_Toopip';
import Header from './Header';
import HeaderNewPromotion from './Header_New_Promotion';
import HeaderToopip from './Header_Toopip';
import MyPageFooter from './MyPageFooter';

const fullPages = [
  /^\/$/,
  /^\/faq$/,
  /^\/leader-board$/,
  /^\/leader-board\/detail\/[0-9]+$/,
  /^\/promotion\/[A-Za-z_]+$/,
  /^\/register$/,
  /^\/login$/,
];

export default function MainLayout() {
  const { pathname } = useLocation();
  const [isLanding, setIsLanding] = useState(false);
  const [isMyPage, setMyPage] = useState(false);

  const isToopip = window.location.href.includes('toopip');
  const isNewPromotion = window.location.href.includes('roi_competition');

  useEffect(() => {
    if (fullPages.some((el) => pathname.match(el))) {
      setIsLanding(true);
    } else setIsLanding(false);

    if (pathname.includes('/my/')) {
      setMyPage(true);
    } else setMyPage(false);
  }, [pathname]);

  return (
    <LayoutWrapper isLanding={isHeaderTransparent(pathname)}>
      {isToopip ? <HeaderToopip key={pathname} /> : isNewPromotion ? <HeaderNewPromotion /> : <Header key={pathname} />}
      <ContentWrapper isLanding={isLanding}>
        <React.StrictMode>
          <Outlet />
        </React.StrictMode>
      </ContentWrapper>
      {isToopip ? <FooterToopip /> : isMyPage ? <MyPageFooter /> : <Footer />}
    </LayoutWrapper>
  );
}

const LayoutWrapper = styled.div<{ isLanding: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
  min-height: ${DEVICE_SIZE.MIN_HEIGHT};
  background-color: ${({ theme }) => theme.bg.primary};
  padding-top: ${({ isLanding }) => (isLanding ? 0 : '70px')};
`;

const ContentWrapper = styled.main<{ isLanding: boolean }>`
  width: 100%;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};
  max-width: ${({ isLanding }) => (isLanding ? `100%` : `${DEVICE_SIZE.CONTENTS_MAX_WIDTH}`)};
  min-height: ${DEVICE_SIZE.MIN_HEIGHT};
  margin: ${({ isLanding }) => (isLanding ? `0` : `0 auto`)};
  padding: ${({ isLanding }) => (isLanding ? `0` : `50px 0`)};

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    height: fit-content;
    min-height: 100%;
    padding: 0;
  }
`;
