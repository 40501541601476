import styled from 'styled-components';

// import DEVICE_SIZE from 'constant/deviceSize';
import { ReactComponent as ToopipLogo } from 'assets/logo/toopip_logo_main.svg';

import { FooterOuter } from './Footer';

export default function FooterToopip() {
  return (
    <FooterOuter>
      <FooterWrapper>
        <ToopipLogo fill="#A6A6A6" />
        <div className="copyright-text">© 2023 toopip.com. All rights reserved</div>
      </FooterWrapper>
    </FooterOuter>
  );
}

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 126px;
  gap: 10px 0;

  .copyright-text {
    color: #a6a6a6;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
