import { ChangeEventHandler } from 'react';
import { useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as ClearIcon } from 'assets/img/clear.svg';
import { ReactComponent as HideIcon } from 'assets/img/hide.svg';
import { ReactComponent as ViewIcon } from 'assets/img/view.svg';
import * as S from 'styles/register.input.styled';

export interface PasswordInputProps {
  id: string;
  labelName: string;
  placeholder?: string;
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClickClearButton?: () => void;
}

export default function PasswordInput(props: PasswordInputProps) {
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const handleEyeButtonClick = () => {
    setInputType((prev) => (prev === 'text' ? 'password' : 'text'));
  };

  return (
    <PasswordInputWrapper>
      <label htmlFor={props.id}>{props.labelName}</label>
      <input
        type={inputType}
        id={props.id}
        value={props?.value || ''}
        onChange={props.onChange}
        placeholder={props?.placeholder || ''}
      />
      {inputType === 'password' ? (
        <ViewIcon className="eye-icon" onClick={handleEyeButtonClick} />
      ) : (
        <HideIcon className="eye-icon" onClick={handleEyeButtonClick} />
      )}
      {props.value && props.onClickClearButton ? (
        <ClearIcon className="clear-icon" onClick={props?.onClickClearButton} />
      ) : null}
    </PasswordInputWrapper>
  );
}

const PasswordInputWrapper = styled(S.InputWrapperNew)`
  .eye-icon {
    position: absolute;
    top: 18px;
    right: 10px;
    cursor: pointer;
  }

  .clear-icon {
    position: absolute;
    top: 18px;
    right: 42px;
    cursor: pointer;
  }
`;
