import LazyLoad from 'react-lazyload';
import { useMediaQuery } from 'react-responsive';

import styled from 'styled-components';

import Awards from 'assets/img/awards.webp';
import Awards_M from 'assets/img/awards_m.webp';
import { ReactComponent as Bankwire } from 'assets/img/bankwire.svg';
import { ReactComponent as Fasapay } from 'assets/img/fasapay.svg';
import { ReactComponent as Mastercard } from 'assets/img/mastercard.svg';
import { ReactComponent as Neteller } from 'assets/img/neteller.svg';
import { ReactComponent as Skrill } from 'assets/img/skrill.svg';
import Sponsorship from 'assets/img/sponsorship.png';
import { ReactComponent as Sticpay } from 'assets/img/sticpay.svg';
import { ReactComponent as Unionpay } from 'assets/img/unionpay.svg';
import { ReactComponent as Visa } from 'assets/img/visa.svg';
import DEVICE_SIZE from 'constant/deviceSize';
import { handleDomain } from 'utils/handleDomain';

const SPONSOR_LIST = [
  <Neteller />,
  <Skrill />,
  <Visa />,
  <Mastercard />,
  <Bankwire />,
  <Sticpay />,
  <Unionpay />,
  <Fasapay />,
];

export default function Footer() {
  const FooterBreakPoint = useMediaQuery({ query: `(max-width :${DEVICE_SIZE.FOOTER_BREAK_POINT})` });
  const isBreakPoint = useMediaQuery({ query: `(max-width :${DEVICE_SIZE.FOOTER_BREAK_POINT_2})` });
  const isMobile = useMediaQuery({ query: `(max-width :${DEVICE_SIZE.MOBILE})` });

  return (
    <FooterOuter>
      <FooterWrapper isMobile={isMobile}>
        <SponsorWrapper>
          {SPONSOR_LIST.map((sponsor, i) => (
            <div key={i}>{sponsor}</div>
          ))}
        </SponsorWrapper>
        {!isBreakPoint && (
          <AwardsWrapper isMobile={isMobile}>
            {FooterBreakPoint ? (
              <div className="awards-container-m">
                <LazyLoad>
                  <img src={Awards_M} alt="awards" />
                </LazyLoad>
              </div>
            ) : (
              <div className="awards-container">
                <LazyLoad>
                  <img src={Awards} alt="awards" />
                </LazyLoad>
              </div>
            )}
            <img src={Sponsorship} alt="awards" width={170} height={60} />
          </AwardsWrapper>
        )}
        <RegulationWrapper isMobile={isMobile}>
          <div>
            <div className="highlight">Registered in SVG.</div> Read risk disclosure before trading Forex/CFDs.
            Forex/CFD trading involves substantial risk of loss and is not suitable for all investors.
            {handleDomain()} domain is owned and operated by Land Prime Ltd., Suite 305, Griffith Corporate Centre,
            Beachmont, 1510 Kingstown, St. Vincent and the Grenadines © 2013 Land Prime Ltd. All rights reserved.
            help@land-fx.com +63 7767086
          </div>
          <br />
          <div>
            <span className="highlight">High Risk Warning</span> : Foreign exchange trading carries a high level of risk
            that may not be suitable for all investors. Leverage creates additional risk and loss exposure. Before you
            decide to trade foreign exchange, carefully consider your investment objectives, experience level, and risk
            tolerance. You could lose some or all of your initial investment; do not invest money that you cannot afford
            to lose. Educate yourself on the risks associated with foreign exchange trading, and seek advice from an
            independent financial or tax advisor if you have any questions.
          </div>
          <br />
          <div>
            <span className="highlight">Advisory Warning</span> : Landprime provides references and links to selected
            blogs and other sources of economic and market information as an educational service to its clients and
            prospects and does not endorse the opinions or recommendations of the blogs or other sources of information.
            Clients and prospects are advised to carefully consider the opinions and analysis offered in the blogs or
            other information sources in the context of the client or prospect's individual analysis and decision
            making. None of the blogs or other sources of information is to be considered as constituting a track
            record. Past performance is no guarantee of future results and Landprime specifically advises clients and
            prospects to carefully review all claims and representations made by advisors, bloggers, money managers and
            system vendors before investing any funds or opening an account with any Forex dealer. Any news, opinions,
            research, data, or other information contained within this website is provided as general market commentary
            and does not constitute investment or trading advice. Landprime expressly disclaims any liability for any
            lost principal or profits without limitation which may arise directly or indirectly from the use of or
            reliance on such information. As with all such advisory services, past results are never a guarantee of
            future results.
          </div>
          <br />
          <div>
            Landprime does not offer its services to residents of certain jurisdictions such as USA, Cuba, Sudan, Syria
            and North Korea and listed / relevant parties of Consolidated United Nations Security Council Sanctions
            Lists.
          </div>
        </RegulationWrapper>
      </FooterWrapper>
    </FooterOuter>
  );
}

export const FooterOuter = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  border-top: 1px solid ${({ theme }) => theme.line.box};
`;

const FooterWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 80px 67px 80px;

  ${({ isMobile }) =>
    isMobile &&
    `
  padding: 40px 28px; 
  `};
`;

const SponsorWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 2%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 39px;

  div {
    text-align: center;
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    justify-content: start;
  }
`;

const AwardsWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 39px;
  gap: 20px;

  .awards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px 4px;

    img {
      max-width: 966px;
      height: 56px;
    }
  }

  .awards-container-m {
    img {
      max-width: 530px;
      height: 132px;
    }
  }

  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    .awards-container {
      justify-content: start;
    }
  }
`;

const RegulationWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1280px;
  color: ${({ theme }) => theme.font.quaternary};
  font-size: 12px;
  line-height: 14.06px;
  font-weight: 400;

  ${({ isMobile }) =>
    isMobile &&
    `
    margin-top: 26px;
  `}

  .highlight {
    font-weight: 700;
  }
`;
