import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import DEVICE_SIZE from 'constant/deviceSize';
import { competitionOrder } from 'constant/leaderCardOrders';
import { useCopy } from 'hooks/useCopy';
import useTranslationNamespace from 'hooks/useTranslationNamespace';
import { useEventList, useEventsLeaders } from 'services/react-query/events';

import Carousel from './Carousel';
import ShowAll from './ShowAll';

export default function Ranking() {
  const isMobile = useMediaQuery({ query: `(max-width :${DEVICE_SIZE.BREAK_POINT})` });
  const navigate = useNavigate();
  const { t, i18n } = useTranslationNamespace('common');

  const { data: events } = useEventList(i18n.language);
  const eventDataArr = useEventsLeaders(events ?? [], { page: 1, limit: 15 }, !!events);
  const { state } = useLocation();
  const { createOnCopy } = useCopy({});
  useEffect(() => {
    if (!!(state as any)?.autoCopy) {
      createOnCopy((state as any).autoCopy.leaderCd as number)();
      window.history.replaceState({ ...(state as any), redirectUrl: undefined, autoCopy: undefined }, '');
    }
  }, []);

  return (
    <RankingWrapper>
      <ContentWrapper>
        <RowWrapper>
          <div className="header">
            <h3 className="heading">{t('main_page_sort1')}</h3>
            <ShowAll
              onClick={() => {
                navigate('/leader-board', { state: { tab: 'roi' } });
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <Carousel mainDisplay="roi" orderByField="roi" orderByOrder="DESC" createOnCopy={createOnCopy} />
        </RowWrapper>
        <RowWrapper>
          <div className="header">
            <h3 className="heading">{t('main_page_sort2')}</h3>
            <ShowAll
              onClick={() => {
                navigate('/leader-board', { state: { tab: 'aum' } });
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <Carousel mainDisplay="aum" orderByField="aum" orderByOrder="DESC" createOnCopy={createOnCopy} />
        </RowWrapper>
        <RowWrapper>
          <div className="header">
            <h3 className="heading">{isMobile ? t('main_page_sort3') : t('main_page_sort3')}</h3>
            <ShowAll
              onClick={() => {
                navigate('/leader-board', { state: { tab: 'mdd' } });
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <Carousel mainDisplay="mdd" orderByField="mdd" orderByOrder="ASC" createOnCopy={createOnCopy} />
        </RowWrapper>
        <RowWrapper>
          <div className="header">
            <h3 className="heading">{t('main_page_sort4')}</h3>
            <ShowAll
              onClick={() => {
                navigate('/leader-board', { state: { tab: 'followers' } });
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <Carousel mainDisplay="followers" orderByField="followers" orderByOrder="DESC" createOnCopy={createOnCopy} />
        </RowWrapper>
        {events
          ?.filter((event) => event.displayOnLanding)
          .map((event, i) => (
            <RowWrapper key={event.eventCd}>
              <div className="header">
                <h3 className="heading">{event.name}</h3>
                {event.displayOnLeaderboard ? (
                  <ShowAll
                    onClick={() => {
                      navigate('/leader-board', { state: { tab: `event${event.eventCd}` } });
                      window.scrollTo(0, 0);
                    }}
                  />
                ) : (
                  <div className="showall-placeholder" />
                )}
              </div>
              <Carousel
                insertedData={eventDataArr[i].data}
                mainDisplay={event.defaultOrderBy}
                createOnCopy={createOnCopy}
                customOrder={competitionOrder}
              />
            </RowWrapper>
          ))}
      </ContentWrapper>
    </RankingWrapper>
  );
}

const RankingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${DEVICE_SIZE.MIN_WIDTH};

  width: 100%;

  background: ${({ theme }) => theme.bg.primary};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 40px;
  gap: 50px;
  max-width: ${DEVICE_SIZE.BODY_MAX_WIDTH};

  width: 100%;

  background: ${({ theme }) => theme.bg.primary};
  @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
    padding: 50px 20px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 100%;

  .heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;

    &:lang(jp) {
      font-weight: 500;
      font-family: 'M PLUS Rounded 1c', sans-serif;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 40px;
    gap: 20px;

    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: ${({ theme }) => theme.font.primary};
    @media screen and (max-width: ${DEVICE_SIZE.BREAK_POINT}) {
      font-size: 20px;
      padding: 0;
    }
  }
`;
