import { useNavigate } from 'react-router-dom';

import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import queryString from 'query-string';

import { showModal } from 'components/Common/GlobalModal';
import ERROR_CODES from 'constant/errorCode';
import { api, apiPrivate, ResponsePromise } from 'services/apiService';
import { CreateStMemberRequestDto, CreateStMemberResponseDto, Mt4AccountNosDto } from 'services/common';
import { setMt4AccountNos } from 'store/mt4AccountNosStore';
import { setUserCountryState } from 'store/userInfoStore';
import { ModalBody } from 'styles/modal.styled';
import printObjValues from 'utils/printObjValues';

export interface GetLeadersAccountResponseDto {
  mt4AccountNos: Mt4AccountNosDto[];
}

export interface GetLeadersCdResponseDto {
  leaderCd: number;
  isActive: number | boolean;
  settlementPeriod: string;
  maxFollowers: number;
  sharingRatio: number;
  nickname: string;
  avatar: string;
  strategy: string;
  memberCd: string;
  mt4AccountNo: number;
  settlementMemberCd: number;
  settlementMt4AccountNo: number;
  minFundingAmount: number;
  regDatetime: string;
  updateDatetime: string;
  email: string;
  mobileCountry: string;
  mobileRest: string;
  isPrivate: boolean | number;
}

export interface GetLeadersFollowersResponseDto {
  totalFollowers: number;
  totalBalance: number;
  totalEquity: number;
  currencyAbbr: string;
  currencySymbol: string;
  followers: {
    items: Array<{
      mt4AccountNo: number;
      name: string;
      balance: number;
      equity: number;
      startingDate: string;
      pl: number;
      isActive: boolean;
      countryName: string;
      countryCd: number;
      currencyAbbr: string;
      currencySymbol: string;
    }>;
    meta: { totalItems: number; itemCount: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
}

export interface GetLeadersFollowersQueryString {
  search?: string;
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}

interface DataObject {
  [key: string]: number | string | File | undefined;
}

export type PutLeadersCdRequestDto = Omit<GetLeadersCdResponseDto, 'regDatetime' | 'updateDatetime'>;

export interface GetLeadersAccountInfoResponseDto {
  cd: number;
  mt4AccountNo: number;
  role: string;
  name: string;
  balance: number;
  status: string;
  isSimple: number;
  isSMSVerified: number;
  isPOAVerified: number;
  isPLEVerified: number;
  currencyAbbr: string;
  currencySymbol: string;
  nickname: string;
}
export interface GetLeadersTradeInfoResponseDto {
  totalDepositAmount: number;
  totalProfitLoss: number;
  totalProfitRate: number;
  balance: number;
  equity: number;
  floating: number;
  margin: number;
  currencyAbbr: string;
  currencySymbol: string;
}

export interface GetLeadersOpenPositionsQueryString {
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}
export interface GetLeadersOpenPositionsResponseDto {
  items: {
    currencyAbbr: string;
    currencySymbol: string;
    positions: Array<{
      symbol: string;
      type: string;
      volume: number;
      price: number;
      sl: number;
      tp: number;
      profit: number;
      ticket: number;
    }>;
  };
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}
export interface GetLeadersTradingHistoryQueryString {
  startDate?: string;
  endDate?: string;
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}
export interface GetLeadersTradingHistoryResponseDto {
  items: Array<{
    symbol: string;
    volume: number;
    openPrice: number;
    closePrice: number;
    openTime: string;
    closeTime: string;
    profit: number;
    type: string;
    currencyAbbr: string;
    currencySymbol: string;
    swaps: number;
  }>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface GetLeadersResponseDto {
  items: Array<{
    leaderCd: number;
    nickname: string;
    regDatetime: string;
    roi: number;
    aum: number;
    mdd: number;
    winRate: number;
    copied: number;
    followers: number;
    avatar: string;
    minFundingAmount: number;
    currencyAbbr: string;
    currencySymbol: string;
    trades: number;
  }>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}

export interface GetLeadersQueryString {
  search?: string;
  orderByField?: string;
  orderByOrder?: 'ASC' | 'DESC';
  page?: number;
  limit?: number;
}

// Returns a list of mt4 account nos that exist as leaders for the authenticated leader.
const getLeadersAccount = (): ResponsePromise<GetLeadersAccountResponseDto> => {
  return apiPrivate.get('/st-leaders/leaders');
};

// Returns a full representation of one leader
const getLeadersCd = (leaderCd: number): ResponsePromise<GetLeadersCdResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}`);
};

// Update setting of the leader
const putLeadersCd = (leaderCd: number, data: DataObject): ResponsePromise<AxiosResponse<any>> => {
  const formData = new FormData();

  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key] as any);
    }
  }
  return apiPrivate.put(`/st-leaders/${leaderCd}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const postInvite = (data: DataObject): ResponsePromise<AxiosResponse<any>> => {
  return apiPrivate.post(`/st-leaders/invite`, data);
};

const postLeadersAccount = (params: CreateStMemberRequestDto): ResponsePromise<CreateStMemberResponseDto> => {
  return api.post('/st-leaders', params);
};

const getLeadersFollowers = ({
  leaderCd,
  page,
  limit,
  query,
}: {
  leaderCd: number;
  page: number;
  limit: number;
  query?: GetLeadersFollowersQueryString;
}): ResponsePromise<GetLeadersFollowersResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/followers?${queryString.stringify({ page, limit, ...query })}`, {
    useLoading: true,
  });
};

const getLeadersFollowersStats = (leaderCd: number): ResponsePromise<GetLeadersFollowersResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/followers`);
};

const getLeadersTradeInfo = (leaderCd: number): ResponsePromise<GetLeadersTradeInfoResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/trade-info`);
};

const getLeadersOpenPositions = ({
  leaderCd,
  page,
  limit,
  query,
}: {
  leaderCd: number;
  page: number;
  limit: number;
  query?: GetLeadersOpenPositionsQueryString;
}): ResponsePromise<GetLeadersOpenPositionsResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/open-positions?${queryString.stringify({ page, limit, ...query })}`, {
    useLoading: false,
  });
};

const getLeadersTradingHistory = ({
  leaderCd,
  page,
  limit,
  query,
}: {
  leaderCd: number;
  page: number;
  limit: number;
  query?: GetLeadersTradingHistoryQueryString;
}): ResponsePromise<GetLeadersTradingHistoryResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/trading-history?${queryString.stringify({ page, limit, ...query })}`);
};

const deleteLeadersClosePosition = (leaderCd: number, ticket: number) => {
  return apiPrivate.delete(`/st-leaders/${leaderCd}/close-position/${ticket}`);
};

const getLeaders = (query: GetLeadersQueryString, useLoading?: boolean): ResponsePromise<GetLeadersResponseDto> => {
  return api.get(`/st-leaders?${queryString.stringify({ ...query })}`, { useLoading: useLoading ?? true });
};

export const useLeadersAccount = (trigger: boolean) => {
  return useQuery({
    queryKey: ['getLeadersAccount'],
    queryFn: getLeadersAccount,
    onSuccess: (data) => {
      setMt4AccountNos(data.mt4AccountNos);
    },
    onError: (err) => {
      console.error(err);
    },
    enabled: trigger,
  });
};

export const useLeadersCd = (leaderCd: number, trigger: boolean) => {
  return useQuery({
    queryKey: ['getLeadersCd', leaderCd],
    queryFn: () => getLeadersCd(leaderCd),
    onSuccess: ({ mobileCountry, mobileRest }) => {
      setUserCountryState({
        mobileCountry,
        mobileRest,
      });
    },
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
  });
};

export const useUpdateLeaderCd = (leaderCd: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: DataObject) => putLeadersCd(leaderCd, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['getLeadersCd']);
      showModal({
        body: <ModalBody>{t('Update_Success', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
    onError: (err: any) => {
      const errorDesc = err.response.data.error.description;
      const errorCode = err.response.data.error.code;

      if (errorCode === ERROR_CODES.client[400]) {
        showModal({
          body: <ModalBody>{printObjValues(errorDesc[0].constraints)}</ModalBody>,
          isDefaultBtn: true,
          cancelBtn: false,
        });
        return;
      }

      showModal({
        body: <ModalBody>{printObjValues(errorDesc)}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useInvite = () => {
  return useMutation({
    mutationFn: (data: DataObject) => postInvite(data),
    onSuccess: () => {
      showModal({
        body: <ModalBody>{t('Invite_Success', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
    onError: () => {
      showModal({
        body: <ModalBody>{t('Validation_Email_Invalid', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};

export const useInfiniteLeadersFollowers = (
  {
    leaderCd,
    limit,
    query,
  }: {
    leaderCd: number;
    limit: number;
    query?: GetLeadersFollowersQueryString;
  },
  trigger: boolean
) => {
  return useInfiniteQuery({
    queryKey: ['getLeadersFollowers', leaderCd, queryString.stringify({ ...(query ?? {}), limit })],
    queryFn: ({ pageParam = 1 }) => getLeadersFollowers({ leaderCd, page: pageParam, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    getNextPageParam: (_, allPages) => allPages.length + 1,
  });
};

export const useLeadersFollowers = (
  {
    leaderCd,
    limit,
    page,
    query,
  }: {
    leaderCd: number;
    limit: number;
    page: number;
    query?: GetLeadersFollowersQueryString;
  },
  trigger: boolean
) => {
  return useQuery({
    queryKey: ['getLeadersFollowers', leaderCd, queryString.stringify({ ...query, page, limit })],
    queryFn: () => getLeadersFollowers({ leaderCd, page, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export const useLeadersFollowersStats = (leaderCd: number, trigger: boolean) => {
  return useQuery({
    queryKey: ['getLeadersFollowers', leaderCd],
    queryFn: () => getLeadersFollowersStats(leaderCd),
    select: (data) => ({
      totalFollowers: data.totalFollowers,
      totalBalance: data.totalBalance,
      totalEquity: data.totalEquity,
      currencyAbbr: data.currencyAbbr,
      currencySymbol: data.currencySymbol,
    }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    refetchOnMount: 'always',
  });
};

export const useLeadersTradeInfo = (leaderCd: number, trigger: boolean) => {
  return useQuery({
    queryKey: ['getLeadersTradeInfo', leaderCd],
    queryFn: () => getLeadersTradeInfo(leaderCd),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
  });
};

export const useInfiniteLeadersOpenPositions = (
  {
    leaderCd,
    limit,
    query,
  }: {
    leaderCd: number;
    limit: number;
    query?: GetLeadersOpenPositionsQueryString;
  },
  trigger: boolean
) => {
  return useInfiniteQuery({
    queryKey: ['getLeadersOpenPositions', leaderCd, queryString.stringify({ ...query, limit } ?? {})],
    queryFn: ({ pageParam = 1 }) => getLeadersOpenPositions({ leaderCd, page: pageParam, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });
};

export const useLeadersOpenPositions = (
  {
    leaderCd,
    limit,
    page,
    query,
  }: {
    leaderCd: number;
    limit: number;
    page: number;
    query?: GetLeadersOpenPositionsQueryString;
  },
  trigger: boolean
) => {
  return useQuery({
    queryKey: ['getLeadersOpenPositions', leaderCd, queryString.stringify({ ...query, page, limit })],
    queryFn: () => getLeadersOpenPositions({ leaderCd, page, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export const useInfiniteLeadersTradingHistory = (
  {
    leaderCd,
    limit,
    query,
  }: {
    leaderCd: number;
    limit: number;
    query?: GetLeadersTradingHistoryQueryString;
  },
  trigger: boolean
) => {
  return useInfiniteQuery({
    queryKey: ['getLeadersTradingHistory', leaderCd, queryString.stringify({ ...query, limit } ?? {})],
    queryFn: ({ pageParam = 1 }) => getLeadersTradingHistory({ leaderCd, page: pageParam, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });
};

export const useLeadersTradingHistory = (
  {
    leaderCd,
    limit,
    page,
    query,
  }: {
    leaderCd: number;
    limit: number;
    page: number;
    query?: GetLeadersTradingHistoryQueryString;
  },
  trigger: boolean
) => {
  return useQuery({
    queryKey: ['getLeadersTradingHistory', leaderCd, queryString.stringify({ ...query, page, limit })],
    queryFn: () => getLeadersTradingHistory({ leaderCd, page, limit, query }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export const useLeadersClosePosition = (leaderCd: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ ticket }: { ticket: number }) => deleteLeadersClosePosition(leaderCd, ticket),
    onSuccess: () => {
      showModal({
        body: <ModalBody>{t('Close_Position_Success', { ns: 'trading' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
      queryClient.invalidateQueries(['getLeadersTradeInfo']);
      queryClient.invalidateQueries(['getLeadersOpenPositions']);
      queryClient.invalidateQueries(['getLeadersTradingHistory']);
    },
    onError: () => {
      showModal({
        body: <ModalBody>{t('Error_Common_Msg', { ns: 'common' })}</ModalBody>,
        isDefaultBtn: true,
        cancelBtn: false,
      });
    },
  });
};
export const useCreateLeaderAccount = () => {
  return useMutation({
    mutationFn: (data: CreateStMemberRequestDto) => postLeadersAccount(data),
    onError: (err: any) => {
      const Error = err.data.error;
      console.log(Error);
    },
  });
};

export const useGetLeaders = (
  query: GetLeadersQueryString,
  trigger?: boolean,
  options?: { useLoading?: boolean; keepPreviousData?: boolean; cacheTime?: number }
) => {
  return useQuery({
    queryKey: ['leaders', queryString.stringify(query)],
    queryFn: () => getLeaders({ ...query }, options?.useLoading),
    enabled: trigger,
    keepPreviousData: options?.keepPreviousData ?? true,
    cacheTime: options?.cacheTime,
    onError: (err) => {
      console.error(err);
    },
  });
};

export const useInfiniteGetLeaders = (
  query: GetLeadersQueryString,
  trigger?: boolean,
  options?: { useLoading?: boolean }
) => {
  return useInfiniteQuery({
    queryKey: ['leaders', queryString.stringify(query)],
    queryFn: ({ pageParam = 1 }) => getLeaders({ ...query, page: pageParam }, options?.useLoading),
    getNextPageParam: ({ meta: { totalPages, currentPage } }) => {
      if (totalPages !== currentPage) {
        return currentPage + 1;
      }
    },
    enabled: !!trigger,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    keepPreviousData: true,
    onError: (err) => {
      console.error(err);
    },
  });
};

export type GetLeadersDailyProfitChartResponseDto = Array<{
  data: string;
  value: number;
  formattedDate: string;
  accValue: number;
}>;
export interface GetLeadersDailyProfitChartQueryString {
  monthCount: number;
}
const getLeadersDailyProfitChart = (
  leaderCd: number,
  query: GetLeadersDailyProfitChartQueryString
): ResponsePromise<GetLeadersDailyProfitChartResponseDto> => {
  return api.get(`/st-leaders/${leaderCd}/charts/daily/profit?${queryString.stringify(query)}`, { useLoading: false });
};
export const useLeadersDailyProfitChart = (
  leaderCd: number,
  query: GetLeadersDailyProfitChartQueryString,
  trigger?: boolean
) => {
  return useQuery({
    queryKey: ['leadersDailyProfitChart', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersDailyProfitChart(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export type GetLeadersDailyPipChartResponseDto = Array<{
  data: string;
  value: number;
  formattedDate: string;
}>;
export interface GetLeadersDailyPipChartQueryString {
  monthCount: number;
}
const getLeadersDailyPipChart = (
  leaderCd: number,
  query: GetLeadersDailyPipChartQueryString
): ResponsePromise<GetLeadersDailyPipChartResponseDto> => {
  return api.get(`/st-leaders/${leaderCd}/charts/daily/pip?${queryString.stringify(query)}`, { useLoading: false });
};
export const useLeadersDailyPipChart = (
  leaderCd: number,
  query: GetLeadersDailyPipChartQueryString,
  trigger?: boolean
) => {
  return useQuery({
    queryKey: ['leadersDailyPipChart', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersDailyPipChart(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export type GetLeadersDailySymbolChartResponseDto = Array<{
  symbol: string;
  lot: number;
  percentage: number;
}>;
export interface GetLeadersDailySymbolChartQueryString {
  monthCount: number;
}
const getLeadersDailySymbolChart = (
  leaderCd: number,
  query: GetLeadersDailySymbolChartQueryString
): ResponsePromise<GetLeadersDailySymbolChartResponseDto> => {
  return api.get(`/st-leaders/${leaderCd}/charts/daily/symbol?${queryString.stringify(query)}`, { useLoading: false });
};
export const useLeadersDailySymbolChart = (
  leaderCd: number,
  query: GetLeadersDailySymbolChartQueryString,
  trigger?: boolean
) => {
  return useQuery({
    queryKey: ['leadersDailySymbolChart', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersDailySymbolChart(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};
export type GetLeadersProfitChartResponseDto = Array<{
  date: string;
  value: number;
  formattedDate: string;
}>;
export interface GetLeadersProfitChartQueryString {
  itemCount?: number;
  period?: string;
}
const getLeadersProfitChart = (
  leaderCd: number,
  query: GetLeadersProfitChartQueryString
): ResponsePromise<GetLeadersProfitChartResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/charts/profit?${queryString.stringify(query)}`, { useLoading: false });
};
export const useLeadersProfitChart = (leaderCd: number, query: GetLeadersProfitChartQueryString, trigger?: boolean) => {
  return useQuery({
    queryKey: ['leadersProfitChart', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersProfitChart(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export type GetLeadersPipChartResponseDto = Array<{
  date: string;
  value: number;
  formattedDate: string;
}>;

export interface GetLeadersPipChartQueryString {
  itemCount?: number;
  period?: string;
}
const getLeadersPipChart = (
  leaderCd: number,
  query: GetLeadersPipChartQueryString
): ResponsePromise<GetLeadersPipChartResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/charts/pip?${queryString.stringify(query)}`, { useLoading: false });
};
export const useLeadersPipChart = (leaderCd: number, query: GetLeadersPipChartQueryString, trigger?: boolean) => {
  return useQuery({
    queryKey: ['leadersPipChart', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersPipChart(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export interface GetLeadersProfitRebateResponseDto {
  currency: string;
  currencySymbol: string;
  periodBy: string;
  periodData: Array<{
    key: string;
    value: number;
  }>;
  todayProfitRebate: number;
  totalProfitRebate: number;
}
export interface GetLeadersProfitRebateQueryString {
  periodBy?: string;
}
const getLeadersProfitRebate = (
  leaderCd: number,
  query: GetLeadersProfitRebateQueryString
): ResponsePromise<GetLeadersProfitRebateResponseDto> => {
  return apiPrivate.get(`/st-leaders/${leaderCd}/profit-rebate?${queryString.stringify(query)}`, { useLoading: false });
};
export const useLeadersProfitRebate = (
  leaderCd: number,
  query: GetLeadersProfitRebateQueryString,
  trigger?: boolean
) => {
  return useQuery({
    queryKey: ['leadersProfitRebate', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersProfitRebate(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export interface GetLeadersPerformanceResponseDto {
  items: Array<{
    year: number;
    data: Array<{ month: number; rollingRoi: number }>;
    total: number;
  }>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
}
export interface GetLeadersPerformanceQueryString {
  page: number;
  limit: number;
}

const getLeadersPerformance = (
  leaderCd: number,
  query: GetLeadersPerformanceQueryString
): ResponsePromise<GetLeadersPerformanceResponseDto> => {
  return api.get(`/st-leaders/${leaderCd}/performance?${queryString.stringify(query)}`, { useLoading: false });
};

export const useLeadersPerformance = (leaderCd: number, query: GetLeadersPerformanceQueryString, trigger?: boolean) => {
  return useQuery({
    queryKey: ['leadersPerformance', leaderCd, queryString.stringify(query)],
    queryFn: () => getLeadersPerformance(leaderCd, query),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    keepPreviousData: true,
  });
};

export const useInfiniteLeadersPerformance = (
  leaderCd: number,
  query: Omit<GetLeadersPerformanceQueryString, 'page'>,
  trigger?: boolean
) => {
  return useInfiniteQuery({
    queryKey: ['leadersPerformance', leaderCd, queryString.stringify(query)],
    queryFn: ({ pageParam = 1 }) => getLeadersPerformance(leaderCd, { ...query, page: pageParam }),
    onError: (err) => {
      console.log(err);
    },
    enabled: trigger,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });
};

interface CreateAdditionalAccountDto {
  memberTypeCd: number;
  currencyCd: number;
  leverage: number;
  mtVersion: number;
  password: string;
  confirmPassword: string;
  signUpIpAddress: string;
  twoFactorAuthentication: {
    type: string;
    secureVerificationCd: number;
  };
}

const postAdditionalAccount = (params: CreateAdditionalAccountDto) => {
  return apiPrivate.post('/st-leaders/additional-account', params);
};

export const useCreateLeaderAdditionalAccount = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: CreateAdditionalAccountDto) => postAdditionalAccount(data),
    onSuccess: () => navigate(0),
    onError: (error: any) => {
      const { code: errorCode, description: descriptions } = error.response.data.error;

      showModal({
        body: (
          <ModalBody>
            <p style={{ marginBottom: '8px' }}>{t('Error_Common_Msg', { ns: 'common' })}</p>
            {errorCode === ERROR_CODES.client[400] ? (
              <p>{printObjValues(descriptions[0].constraints)}</p>
            ) : errorCode === ERROR_CODES.user[404] ? (
              <p>{t('add_account_error_0007', { ns: 'common' })}</p>
            ) : (
              descriptions.map((description: string, index: number) => <p key={index}>{description}</p>)
            )}
          </ModalBody>
        ),
        isDefaultBtn: true,
        cancelBtn: false,
        hasCloseIcon: false,
        closeOnBackdropClick: false,
      });
    },
  });
};
