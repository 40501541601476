import styled from 'styled-components';

export const InputWrapperNew = styled.div`
  display: flex;
  height: 64px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  padding: 10px 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.line.my};
  position: relative;

  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${({ theme }) => theme.font.quaternary};
    top: 10px;
    left: 16px;
  }

  input {
    width: 100%;
    font-size: 17px;
    line-height: 24px;
  }

  input::placeholder {
    color: ${({ theme }) => theme.font.placeholder};
  }

  &:focus-within {
    border-color: ${({ theme }) => theme.color.primary};

    label {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }

  .code-button-timer {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    align-items: center;
  }

  .validation-area {
    width: 100%;
    display: flex;
    gap: 6px;
  }
`;
